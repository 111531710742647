<template>
  <div class="auth-body">
    <div class="auth">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6" style="padding: 0 50px">
            <div class="logo-box text-center">
              <span class="title cherry-bomb-one-regular">Kinkyer</span>
            </div>
            <br />
            <div class="text-center subtitle">
              アカウント情報の入力をお願いします
            </div>
            <div class="form-group has-search" style="padding-bottom: 5px">
              <span
                class="fa fa-envelope-o form-control-feedback"
                style="color: #fff"
              ></span>
              <input
                type="email"
                class="form-control"
                style="background-color: #ffffff69; border: 0; color: #fff"
                placeholder="メールアドレス"
                v-model="email"
              />
            </div>
            <div class="form-group has-search" style="padding-bottom: 5px">
              <span
                class="fa fa-lock form-control-feedback"
                style="color: #fff"
              ></span>
              <input
                type="password"
                class="form-control"
                style="background-color: #ffffff69; border: 0; color: #fff"
                placeholder="パスワード"
                v-model="password"
              />
            </div>
            <br />
            <div class="text-center">
              <div style="padding-bottom: 10px">
                <button
                  type="button"
                  class="btn btn-kinkyer-outline m-plus-medium"
                  @click="loginPassword"
                >
                  メールアドレスでログイン
                </button>
              </div>
              <p>
                <a
                  style="font-size: 12px"
                  @click="myBottomSheet = !myBottomSheet"
                  >パスワードを忘れた方はこちら</a
                >
              </p>
              <br />
              <div>
                <div style="float: left; width: 45%">
                  <hr />
                </div>
                <div
                  class="text-center"
                  style="float: left; width: 10%; color: #fff"
                >
                  or
                </div>
                <div style="float: left; width: 45%">
                  <hr />
                </div>
              </div>
              <br />
              <div style="padding: 30px 0">
                <button
                  type="button"
                  class="btn btn-kinkyer-outline m-plus-medium"
                  @click="loginTwitter"
                >
                  X(Twitter)でログイン
                </button>
              </div>
              <div>
                <button
                  @click="linkUrl('entry')"
                  type="button"
                  style="width: 200px !important; font-size: 12px !important"
                  class="btn btn-kinkyer-by-color m-plus-medium"
                >
                  アカウントを作成する
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="isLoad"
        style="
          position: fixed;
          top: 0px;
          width: 100%;
          height: 100%;
          background-color: rgb(0 0 0 / 50%);
          margin: 0 auto;
          padding-top: 200px;
          text-align: center;
          z-index: 999;
        "
      >
        <div style="position: absolute; z-index: 9999; width: 100%">
          <VueSpinnerOval size="50" color="#fff" />
        </div>
      </div>
      <v-bottom-sheet v-model="myBottomSheet">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <v-sheet
                class="text-center"
                height="250px"
                style="border-radius: 15px 15px 0 0"
              >
                <div style="padding: 0px" class="text-center">
                  <span
                    class="material-symbols-rounded"
                    style="color: #ccc; font-size: 40px"
                    @click="myBottomSheet = !myBottomSheet"
                  >
                    remove
                  </span>
                </div>
                <div style="height: 200px; padding: 0 15px">
                  <div>
                    <div style="padding-bottom: 5px">パスワード再発行</div>
                    <div
                      class="text-start"
                      style="font-size: 12px; padding-bottom: 10px"
                    >
                      登録したメールアドレスを入力してください。<br />パスワード再発行URLをお送りします。
                    </div>
                    <div>
                      <input
                        type="email"
                        class="form-control form-control-reset"
                        aria-describedby="emailHelp"
                        placeholder="メールアドレス"
                        v-model="resetEmail"
                      />
                    </div>
                    <div style="padding-top: 10px">
                      <button
                        type="button"
                        style="width: 100%; border-radius: 4px"
                        @click="resetPassword"
                        class="btn btn-kinkyer-reset m-plus-medium"
                      >
                        パスワード再発行
                      </button>
                    </div>
                  </div>
                </div>
              </v-sheet>
            </div>
          </div>
        </div>
      </v-bottom-sheet>
    </div>
  </div>
</template>

<script>
import { db, auth, storage } from "../../plugins/firebase";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  TwitterAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import {
  store,
  setUser,
  setUserIconUrl,
  setShareUserId,
  setUserDetail,
} from "../../plugins/store";

export default {
  data() {
    return {
      isLoad: false,
      email: "",
      password: "",
      myBottomSheet: false,
      resetEmail: "",
    };
  },
  mounted() {
    onAuthStateChanged(auth, async (user) => {
      if (user != null) {
        if (user.emailVerified) {
          const docRef = doc(db, "authLink", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const docUserRef = doc(db, "users", docSnap.data().uid);
            const docUserSnap = await getDoc(docUserRef);

            if (docUserSnap.exists()) {
              setUser(docUserSnap.data(), docSnap.data().uid);

              if (store.shareUserId != "") {
                const docShareUserRef = doc(db, "users", store.shareUserId);
                const docShareUserSnap = await getDoc(docShareUserRef);

                setUserDetail(docShareUserSnap.data());
                setShareUserId("");
                this.$router.push({
                  name: "user-detail",
                });
              } else {
                this.$router.push("/home");
              }
            }
          }
        }
      }
    });
  },
  methods: {
    linkUrl(url) {
      this.$router.push({
        name: url,
      });
    },
    loginPassword() {
      this.isLoad = true;
      if (this.email == "" || this.email == "") {
        this.$toast.show("メールアドレス、パスワードを入力してください", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        this.isLoad = false;

        return;
      }

      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(async (userCredential) => {
          // 成功時処理
          const user = userCredential.user;

          const docRef = doc(db, "authLink", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const docUserRef = doc(db, "users", docSnap.data().uid);
            const docUserSnap = await getDoc(docUserRef);

            setUser(docUserSnap.data(), docSnap.data().uid);

            const imageRef = storageRef(
              storage,
              `users/icon/${docSnap.data().uid}/user_icon1.png`,
            );

            try {
              const url = await getDownloadURL(imageRef);
              setUserIconUrl(url);
            } catch (error) {
              console.log("---------- auth/topview ---------");
              console.error("Error fetching image URL:", error);
            }

            if (store.shareUserId != "") {
              const docShareUserRef = doc(db, "users", store.shareUserId);
              const docShareUserSnap = await getDoc(docShareUserRef);

              setUserDetail(docShareUserSnap.data());
              setShareUserId("");
              this.$router.push({
                name: "user-detail",
              });
            } else {
              if (docUserSnap.data().birthday == undefined) {
                this.$router.push("/user/edit/name/entry");
              } else {
                this.$router.push("/home");
              }
            }

            this.isLoad = false;
          } else {
            this.$router.push("/auth/conf");
          }
        })
        .catch((e) => {
          console.log(e);
          this.isLoad = false;
          // 失敗時処理
          this.$toast.show("ログイン情報を確認してください", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
        });
    },
    loginTwitter() {
      this.isLoad = true;
      const provider = new TwitterAuthProvider();

      signInWithPopup(auth, provider)
        .then(async (result) => {
          // The signed-in user info.
          const user = result.user;
          if (user) {
            const docRef = doc(db, "authLink", user.uid);
            const docSnap = await getDoc(docRef);

            const docUserRef = doc(db, "users", docSnap.data().uid);
            const docUserSnap = await getDoc(docUserRef);

            setUser(docUserSnap.data(), docSnap.data().uid);

            const imageRef = storageRef(
              storage,
              `users/icon/${docSnap.data().uid}/user_icon1.png`,
            );

            try {
              const url = await getDownloadURL(imageRef);
              setUserIconUrl(url);
            } catch (error) {
              console.log("---------- auth/topview ---------");
              console.error("Error fetching image URL:", error);
            }

            if (store.shareUserId != "") {
              const docShareUserRef = doc(db, "users", store.shareUserId);
              const docShareUserSnap = await getDoc(docShareUserRef);

              setUserDetail(docShareUserSnap.data());
              setShareUserId("");
              this.$router.push({
                name: "user-detail",
              });
            } else {
              if (docUserSnap.data().birthday == undefined) {
                this.$router.push("/user/edit/name/entry");
              } else {
                this.$router.push("/home");
              }
            }

            this.isLoad = false;
          } else {
            this.isLoad = false;
            // 失敗時処理
            this.$toast.show("有効なアカウントではありません", {
              type: "error",
              position: "bottom",
              duration: 2000,
            });
          }
        })
        .catch((e) => {
          console.log("err" + e);
          this.isLoad = false;
          // 失敗時処理
          this.$toast.show("有効なアカウントではありません", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
        });
    },
    async resetPassword() {
      try {
        await sendPasswordResetEmail(auth, this.resetEmail);
        this.myBottomSheet = false;
        this.$toast.show("パスワード再発行メールを送信しました", {
          type: "success",
          position: "bottom",
          duration: 2000,
        });
      } catch (error) {
        var errorMessage = "";
        switch (error.code) {
          case "auth/invalid-email":
            errorMessage = "無効なメールアドレスです。";
            break;
          case "auth/user-not-found":
            errorMessage = "メールアドレスは見つかりませんでした。";
            break;
          default:
            errorMessage = "パスワード再発行中にエラーが発生しました。";
        }

        this.$toast.show(errorMessage, {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style scoped>
hr {
  height: 1px;
  background-color: #fff;
  border: none;
  color: #fff;
}

p {
  margin-bottom: 5px;
}

a {
  color: #fff !important;
  text-decoration: underline !important;
}

a:focus {
  color: #fff;
  text-decoration: underline;
}

a:hover {
  color: #fff;
  text-decoration: underline;
}

.auth {
  height: 100%;
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
}

.title {
  color: #fff;
  font-size: 45px;
}

.subtitle {
  color: #fff;
  font-size: 14px;
  padding-bottom: 10px;
}

.subnote {
  color: #fff;
  font-size: 12px;
}

.logo-box {
  padding-top: 80px;
}

.btn-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 50px;
}

.has-search .form-control {
  padding-left: 2.2rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #fff;
}

.form-control::placeholder {
  color: #ffffff7c;
}

.form-control-reset::placeholder {
  color: #ddd !important;
}

.btn-kinkyer-reset {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  width: 280px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}
.btn-kinkyer-reset:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.btn-kinkyer-reset:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.btn-kinkyer-reset:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer-reset:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
