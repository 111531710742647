<template>
  <div
    :class="
      imageUrl.length == 1 && pageMode != 2
        ? 'container-box-one'
        : 'container-box'
    "
  >
    <div
      v-for="(image, i) in imageUrl"
      :key="i"
      :class="imageUrl.length == 1 && pageMode != 2 ? 'item-one' : 'item'"
      :style="
        pageMode == 2 || pageMode == 4
          ? 'height: 80px; min-width: 80px'
          : 'height: 200px; width: 200px'
      "
    >
      <div @click="setListModalView(i, imageUrl)">
        <img
          :src="image"
          :class="
            imageUrl.length == 1 && pageMode != 2
              ? 'thumbnail-one'
              : 'thumbnail'
          "
          :style="
            pageMode == 2 || pageMode == 4
              ? 'height: 80px; width: 80px'
              : 'height: 200px; width: 200px'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { storage } from "../../plugins/firebase";

export default {
  data() {
    return {
      imageUrl: [],
      userIds: [],
      pageMode: 0,
    };
  },
  props: ["uids", "mode"],
  created() {
    this.userIds = this.uids;
    this.pageMode = this.mode;
    this.getImageList();
  },
  methods: {
    setListModalView(i, images) {
      this.$emit("handle-list-modal-view", i, images);
    },
    setModal(index) {
      this.modalImageUrl = this.imageUrl[index];
      this.modalImageIndex = index;
    },
    async getImageList() {
      for (var i in this.userIds) {
        const imageRef = storageRef(storage, this.userIds[i]);
        try {
          const url = await getDownloadURL(imageRef);
          this.imageUrl.push(url);
        } catch (error) {
          console.log("---------- components/headerView1 ---------");
          console.error("Error fetching image URL:", error);
        }
      }
      /* const imageRef = storageRef(
        storage,
        `users/icon/${this.userId}/user_icon1.png`
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;
      } catch (error) {
        console.error("Error fetching image URL:", error);
      } */
    },
  },
};
</script>

<style scoped>
.thumbnail {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  border: solid 1px #eee;
}
.container-box {
  display: flex; /* 横に並べる */
  overflow-x: auto; /* 横スクロールを許可 */
  white-space: nowrap; /* 折り返しを防止 */
  margin-left: 60px;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.item {
  min-width: 200px;
  height: 200px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thumbnail-one {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  border: solid 1px #eee;
}
.container-box-one {
  display: inline-block;
  white-space: nowrap; /* 折り返しを防止 */
  margin-left: 60px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  padding-right: 20px;
}

.item-one {
  width: 100%;
  height: auto;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
