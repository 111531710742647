<template>
  <div class="page">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div
            class="text-start"
            style="padding-top: 50px; margin: 0 auto; width: 300px"
          >
            <span class="m-plus-medium" style="font-size: 18px; color: #fff"
              >STEP3.<br />界隈を盛り上げよう！</span
            >
          </div>
          <div class="text-center" style="margin: 0 auto; padding-top: 30px">
            <div class="message-window text-start">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators" style="bottom: -40px">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-box carousel-item active">
                    <div style="color: #827490; padding: 40px 10px 0 10px">
                      KinkyerにはSNSやマッチング機能の他にイベントを主催したりチェックする機能があります
                    </div>
                    <div class="text-center tutorial-image">
                      <img
                        :src="require('@/assets/images/matching_info7.png')"
                        style="width: 200px; height: auto"
                      />
                    </div>
                  </div>
                  <div class="carousel-box carousel-item">
                    <div style="color: #827490; padding: 40px 10px 0 10px">
                      他にも界隈を盛り上げるために様々な機能があります<br />皆様に素敵な出会いがありますように
                    </div>
                    <div class="text-center" style="padding-top: 50px">
                      <div class="text-center">
                        <span class="title cherry-bomb-one-regular"
                          >Kinkyer</span
                        >
                        <br />
                        <span class="subtitle cherry-bomb-one-regular">
                          〜すべてのkinkyへ〜
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div class="btn-window">
              <router-link to="/home">
                <button
                  type="button"
                  class="btn btn-kinkyer-by-color m-plus-medium"
                >
                  Kinkyerホームへ
                </button></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel } from "bootstrap";

export default {
  data() {
    return {};
  },
  mounted() {
    const carouselEl = document.querySelector("#carouselExampleIndicators");
    if (carouselEl) {
      new Carousel(carouselEl, {
        interval: 3000, // 3秒ごとに自動スライド
        ride: "carousel", // 自動起動
      });
    }
  },
  methods: {},
};
</script>

<style scoped>
a {
  font-size: 12px;
  color: #fff !important;
  text-decoration: none !important;
}
a:hover {
  font-size: 12px;
  color: #fff !important;
  text-decoration: none !important;
}
a:focus {
  font-size: 12px;
  color: #fff !important;
  text-decoration: none !important;
}
.page {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: -moz-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: -webkit-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background-color: #784ba0;
}
.message-window {
  margin: 0 auto;
  width: 300px;
  border-radius: 12px;
  background-color: #fff;
  font-size: 15px;
}
.btn-window {
  margin: 0 auto;
  width: 300px;
}
.carousel-box {
  width: 300px;
  height: 350px;
  padding: 10px 12px;
}
.tutorial-image {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
}
.title {
  color: #784ba0;
  font-size: 50px;
  background: -moz-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: -webkit-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background-color: #784ba0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  background: -moz-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: -webkit-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background-color: #784ba0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
}
</style>
