<template>
  <AppHeader
    :is_icon="false"
    :is_back="true"
    :title="''"
    :type="isEntry ? 'skip_icon' : 'none'"
    @header-action-click="headerActionClick"
  />
  <div class="user-edit-detail" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div style="padding: 0 8px">
              <div style="padding-bottom: 15px" v-show="isEntry">
                <div
                  class="progress"
                  role="progressbar"
                  aria-label="Basic example"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    class="progress-bar"
                    :style="
                      'background-color: #784ba0 !important; width: ' +
                      count +
                      '%'
                    "
                  ></div>
                </div>
              </div>
              <div>
                <div
                  style="
                    padding-top: 0px;
                    padding-bottom: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #827490;
                  "
                >
                  性癖・好きなプレイ
                </div>
                <div
                  style="
                    padding: 10px 20px;
                    font-size: 12px;
                    font-weight: 400;
                    background-color: rgb(244, 245, 247);
                    border-radius: 6px;
                  "
                >
                  あなたの好きな性癖・プレイを教えてください<br />
                  1:全く当てはまらない<br />
                  2:当てはまらない<br />
                  3:あまり当てはまらない<br />
                  4:やや当てはまる<br />
                  5:当てはまる<br />
                  6:とても当てはまる
                </div>
              </div>
              <div class="item-box">
                <hr />
                <div v-for="(item, i) in this.questionList" :key="i">
                  <div class="item-title">
                    {{ item.label }}&nbsp;
                    <svg
                      ref="tooltipIcons"
                      xmlns="http://www.w3.org/2000/svg"
                      height="16px"
                      viewBox="0 -960 960 960"
                      width="16px"
                      fill="currentColor"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :title="item.comment"
                    >
                      <path
                        d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
                      />
                    </svg>
                  </div>
                  <div style="padding: 10px 0">
                    <v-slider
                      v-model="item.value"
                      color="#784ba0"
                      :max="6"
                      :min="1"
                      show-ticks="always"
                      :ticks="tickLabels"
                      :step="1"
                    ></v-slider>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 75px;
      padding: 5px 20px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="((isEdit = true), update())"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-none d-md-block"
    style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
  >
    <div class="container" style="height: 100%">
      <div class="row" style="height: 100%">
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
        ></div>
        <div class="col-8"></div>
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
        ></div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import AppHeader from "@/components/HeaderView.vue";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";
import { Tooltip } from "bootstrap";

export default {
  data() {
    return {
      isEdit: false,
      isEntry: true,
      uid: "",
      user: {},
      sadistic: 1,
      count: 0,
      tickLabels: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
      },
      questionList: {
        question36: {
          label: "SEX",
          column: "question36",
          value: 1,
          comment: "挿入を含む性的な交わり全般を楽しむ",
        },
        question37: {
          label: "キス・ハグ",
          column: "question37",
          value: 1,
          comment: "キスや抱きしめることで愛情や快感を得る",
        },
        question38: {
          label: "フェラ・クンニ",
          column: "question38",
          value: 1,
          comment: "口で性器を愛撫するオーラルセックスの嗜好",
        },
        question39: {
          label: "イラマチオ",
          column: "question39",
          value: 1,
          comment: "喉奥まで挿入する強制的なオーラルプレイ",
        },
        question40: {
          label: "アナル",
          column: "question40",
          value: 1,
          comment: "肛門を刺激・挿入するプレイに興奮を覚える",
        },
        question41: {
          label: "コスプレ",
          column: "question41",
          value: 1,
          comment: "衣装や設定で非日常感を楽しむ性的プレイ",
        },
        question42: {
          label: "撮影",
          column: "question42",
          value: 1,
          comment: "性的行為を記録・鑑賞して興奮する嗜好",
        },
        question43: {
          label: "首絞め",
          column: "question43",
          value: 1,
          comment: "首を締める刺激で快感を得るスリル系プレイ",
        },
        question44: {
          label: "噛み跡",
          column: "question44",
          value: 1,
          comment: "体に噛み跡を残すことで興奮や愛情を感じる",
        },
        question45: {
          label: "露出願望",
          column: "question45",
          value: 1,
          comment: "他者に見られることで快感を得る嗜好",
        },
        question46: {
          label: "ボイジャー",
          column: "question46",
          value: 1,
          comment: "他人の性行為や裸を見ることに興奮する嗜好",
        },
        question47: {
          label: "スイッチ",
          column: "question47",
          value: 1,
          comment: "支配と服従どちらの役割も楽しめる性質",
        },
        question48: {
          label: "実験家",
          column: "question48",
          value: 1,
          comment: "新しいプレイや刺激を試すことに興奮する嗜好",
        },
        question49: {
          label: "エイジプレイヤー",
          column: "question49",
          value: 1,
          comment: "年齢を演じて関係性を楽しむプレイスタイル",
        },
        question50: {
          label: "バニラ",
          column: "question50",
          value: 1,
          comment: "ノーマルな性行為を好むプレイスタイル",
        },
        question51: {
          label: "ノンモノガミスト",
          column: "question51",
          value: 1,
          comment: "複数の愛・性的関係を同時に楽しむ志向",
        },
        question52: {
          label: "モノガミスト",
          column: "question52",
          value: 1,
          comment: "一対一の関係性を大切にする恋愛・性志向",
        },
        question53: {
          label: "サピオシャス",
          column: "question53",
          value: 1,
          comment: "知性に魅力や性的興奮を感じる嗜好",
        },
        question54: {
          label: "鞭・打具を使うプレイ",
          column: "question54",
          value: 1,
          comment: "鞭や道具で叩いて刺激や支配を楽しむ嗜好",
        },
        question55: {
          label: "拘束具を使うプレイ",
          column: "question55",
          value: 1,
          comment: "手錠や拘束具で身動きを制限するプレイ",
        },
        question56: {
          label: "玩具を使うプレイ",
          column: "question56",
          value: 1,
          comment: "バイブなどの道具で刺激を楽しむプレイ",
        },
        question57: {
          label: "蝋燭を使うプレイ",
          column: "question57",
          value: 1,
          comment: "熱い蝋で感覚刺激を楽しむプレイスタイル",
        },
        question58: {
          label: "緊縛プレイ",
          column: "question58",
          value: 1,
          comment: "縄で縛り、拘束される快感を楽しむ嗜好",
        },
        question59: {
          label: "マミフィケーションプレイ",
          column: "question59",
          value: 1,
          comment: "全身を包帯などで包み拘束されるプレイ",
        },
        question60: {
          label: "全身タイツプレイ",
          column: "question60",
          value: 1,
          comment: "全身タイツを着て独特の感覚を楽しむプレイ",
        },
        question61: {
          label: "ぐりぐら",
          column: "question61",
          value: 1,
          comment: "くすぐりによって刺激や快感を得るプレイ",
        },
        question69: {
          label: "ソフレ",
          column: "question69",
          value: 1,
          comment: "性行為なしで添い寝などの親密さを楽しむ",
        },
        question70: {
          label: "舐め犬",
          column: "question70",
          value: 1,
          comment: "犬のように相手を舐め尽くす奉仕的プレイ",
        },
        question62: {
          label: "スローセックス・ポリネシアンセックス",
          column: "question62",
          value: 1,
          comment: "ゆっくり丁寧に愛し合う感覚重視のセックス",
        },
        question63: {
          label: "スパンキング",
          column: "question63",
          value: 1,
          comment: "お尻などを叩いて刺激を楽しむプレイ",
        },
      },
    };
  },
  watch: {
    // データが変更されたときにスクロールをリセット
    content: {
      async handler() {
        await nextTick();
        if (this.$refs.scrollContainer) {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.count = 3.846 * 25;
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";

    this.user = store.user;
  },
  mounted() {
    this.$refs.tooltipIcons?.forEach((el) => {
      new Tooltip(el);
    });
    this.questionList.question36.value =
      this.user.question36 == undefined ? 0 : this.user.question36;
    this.questionList.question37.value =
      this.user.question37 == undefined ? 0 : this.user.question37;
    this.questionList.question38.value =
      this.user.question38 == undefined ? 0 : this.user.question38;
    this.questionList.question39.value =
      this.user.question39 == undefined ? 0 : this.user.question39;
    this.questionList.question40.value =
      this.user.question40 == undefined ? 0 : this.user.question40;
    this.questionList.question41.value =
      this.user.question41 == undefined ? 0 : this.user.question41;
    this.questionList.question42.value =
      this.user.question42 == undefined ? 0 : this.user.question42;
    this.questionList.question43.value =
      this.user.question43 == undefined ? 0 : this.user.question43;
    this.questionList.question44.value =
      this.user.question44 == undefined ? 0 : this.user.question44;
    this.questionList.question45.value =
      this.user.question45 == undefined ? 0 : this.user.question45;
    this.questionList.question46.value =
      this.user.question46 == undefined ? 0 : this.user.question46;
    this.questionList.question47.value =
      this.user.question47 == undefined ? 0 : this.user.question47;
    this.questionList.question48.value =
      this.user.question48 == undefined ? 0 : this.user.question48;
    this.questionList.question49.value =
      this.user.question49 == undefined ? 0 : this.user.question49;
    this.questionList.question50.value =
      this.user.question50 == undefined ? 0 : this.user.question50;
    this.questionList.question51.value =
      this.user.question51 == undefined ? 0 : this.user.question51;
    this.questionList.question52.value =
      this.user.question52 == undefined ? 0 : this.user.question52;
    this.questionList.question53.value =
      this.user.question53 == undefined ? 0 : this.user.question53;
    this.questionList.question54.value =
      this.user.question54 == undefined ? 0 : this.user.question54;
    this.questionList.question55.value =
      this.user.question55 == undefined ? 0 : this.user.question55;
    this.questionList.question56.value =
      this.user.question56 == undefined ? 0 : this.user.question56;
    this.questionList.question57.value =
      this.user.question57 == undefined ? 0 : this.user.question57;
    this.questionList.question58.value =
      this.user.question58 == undefined ? 0 : this.user.question58;
    this.questionList.question59.value =
      this.user.question59 == undefined ? 0 : this.user.question59;
    this.questionList.question60.value =
      this.user.question60 == undefined ? 0 : this.user.question60;
    this.questionList.question61.value =
      this.user.question61 == undefined ? 0 : this.user.question61;
    this.questionList.question62.value =
      this.user.question62 == undefined ? 0 : this.user.question62;
    this.questionList.question63.value =
      this.user.question63 == undefined ? 0 : this.user.question63;
    this.questionList.question69.value =
      this.user.question69 == undefined ? 0 : this.user.question69;
    this.questionList.question70.value =
      this.user.question70 == undefined ? 0 : this.user.question70;
  },
  components: {
    AppHeader,
  },
  methods: {
    async update() {
      try {
        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        await updateDoc(docUpdateUsersRef, {
          question36: this.questionList.question36.value,
          question37: this.questionList.question37.value,
          question38: this.questionList.question38.value,
          question39: this.questionList.question39.value,
          question40: this.questionList.question40.value,
          question41: this.questionList.question41.value,
          question42: this.questionList.question42.value,
          question43: this.questionList.question43.value,
          question44: this.questionList.question44.value,
          question45: this.questionList.question45.value,
          question46: this.questionList.question46.value,
          question47: this.questionList.question47.value,
          question48: this.questionList.question48.value,
          question49: this.questionList.question49.value,
          question50: this.questionList.question50.value,
          question51: this.questionList.question51.value,
          question52: this.questionList.question52.value,
          question53: this.questionList.question53.value,
          question54: this.questionList.question54.value,
          question55: this.questionList.question55.value,
          question56: this.questionList.question56.value,
          question57: this.questionList.question57.value,
          question58: this.questionList.question58.value,
          question59: this.questionList.question59.value,
          question60: this.questionList.question60.value,
          question61: this.questionList.question61.value,
          question62: this.questionList.question62.value,
          question63: this.questionList.question63.value,
          question69: this.questionList.question69.value,
          question70: this.questionList.question70.value,
        });
        const docUpdateShareUsersRef = doc(db, "shareUsers", this.user.uid);

        await updateDoc(docUpdateShareUsersRef, {
          question36: this.questionList.question36.value,
          question37: this.questionList.question37.value,
          question38: this.questionList.question38.value,
          question39: this.questionList.question39.value,
          question40: this.questionList.question40.value,
          question41: this.questionList.question41.value,
          question42: this.questionList.question42.value,
          question43: this.questionList.question43.value,
          question44: this.questionList.question44.value,
          question45: this.questionList.question45.value,
          question46: this.questionList.question46.value,
          question47: this.questionList.question47.value,
          question48: this.questionList.question48.value,
          question49: this.questionList.question49.value,
          question50: this.questionList.question50.value,
          question51: this.questionList.question51.value,
          question52: this.questionList.question52.value,
          question53: this.questionList.question53.value,
          question54: this.questionList.question54.value,
          question55: this.questionList.question55.value,
          question56: this.questionList.question56.value,
          question57: this.questionList.question57.value,
          question58: this.questionList.question58.value,
          question59: this.questionList.question59.value,
          question60: this.questionList.question60.value,
          question61: this.questionList.question61.value,
          question62: this.questionList.question62.value,
          question63: this.questionList.question63.value,
          question69: this.questionList.question69.value,
          question70: this.questionList.question70.value,
        });

        this.user.question36 = this.questionList.question36.value;
        this.user.question37 = this.questionList.question37.value;
        this.user.question38 = this.questionList.question38.value;
        this.user.question39 = this.questionList.question39.value;
        this.user.question40 = this.questionList.question40.value;
        this.user.question41 = this.questionList.question41.value;
        this.user.question42 = this.questionList.question42.value;
        this.user.question43 = this.questionList.question43.value;
        this.user.question44 = this.questionList.question44.value;
        this.user.question45 = this.questionList.question45.value;
        this.user.question46 = this.questionList.question46.value;
        this.user.question47 = this.questionList.question47.value;
        this.user.question48 = this.questionList.question48.value;
        this.user.question49 = this.questionList.question49.value;
        this.user.question50 = this.questionList.question50.value;
        this.user.question51 = this.questionList.question51.value;
        this.user.question52 = this.questionList.question52.value;
        this.user.question53 = this.questionList.question53.value;
        this.user.question54 = this.questionList.question54.value;
        this.user.question55 = this.questionList.question55.value;
        this.user.question56 = this.questionList.question56.value;
        this.user.question57 = this.questionList.question57.value;
        this.user.question58 = this.questionList.question58.value;
        this.user.question59 = this.questionList.question59.value;
        this.user.question60 = this.questionList.question60.value;
        this.user.question61 = this.questionList.question61.value;
        this.user.question62 = this.questionList.question62.value;
        this.user.question63 = this.questionList.question63.value;
        this.user.question69 = this.questionList.question69.value;
        this.user.question70 = this.questionList.question70.value;

        setUser(this.user, this.user.uid);

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push(
            "/mypage/setting/identification/" + this.$route.params.type,
          );
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
    headerActionClick(data) {
      if (data == "skip_icon") {
        this.update();
      }
    },
  },
};
</script>

<style scoped>
.user-edit-detail {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
  align-items: center;
  display: flex;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
</style>
