<template>
  <div style="padding: 10px 15px; display: flex" @click="linkUser">
    <div v-if="imageUrl">
      <img :src="imageUrl" />
    </div>
    <div v-else>
      <img :src="require('@/assets/icon_user.webp')" />
    </div>
    <div style="padding-left: 10px; padding-top: 7px">
      {{ user.name }}
    </div>
  </div>
</template>

<script>
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { storage } from "../../plugins/firebase";
import { store } from "../../plugins/store";

export default {
  data() {
    return {
      loginUser: {},
      user: {},
      imageUrl: null,
    };
  },
  props: ["item"],
  async mounted() {
    this.user = this.item;
    this.loginUser = store.user;

    this.getUserIcon();
  },
  methods: {
    async getUserIcon() {
      const imageRef = storageRef(
        storage,
        `users/icon/${this.user.uid}/user_icon1.png`,
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;
      } catch (error) {
        console.log("---------- components/timeline ---------");
        console.error("Error fetching image URL:", error);
      }
    },
    async linkUser() {
      this.$router.push("/u/" + this.user.accountId);
    },
  },
};
</script>

<style scoped>
img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.list-sub-tag {
  font-size: 10px;
  white-space: nowrap;
}
</style>
