<template>
  <div class="timeline-edit">
    <div class="header-back">
      <div class="header-item header-item-size" style="width: 30%">
        <a @click="$router.go(-1)">
          <span class="material-symbols-rounded icon"> arrow_back </span>
        </a>
      </div>
      <div
        class="text-center header-item"
        style="padding-top: 5px; width: 40%"
      ></div>
      <div class="header-item header-item-size" style="width: 30%">
        <div class="text-end" style="margin-right: 10px">
          <button
            type="button"
            style="width: 90px"
            class="btn btn-kinkyer m-plus-medium"
            @click="sendTweet"
          >
            投稿する
          </button>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div>
              <table class="table table-borderless" style="margin: 0">
                <tbody>
                  <tr style="padding: 0">
                    <td
                      style="vertical-align: top; text-align: end; padding: 0"
                      width="50"
                    >
                      <listIcon :uid="user.uid" :is_icon="user.isIconUrl" />
                    </td>
                    <td style="word-break: break-word; padding: 0 10px 0 0">
                      <div>
                        <textarea
                          class="form-control"
                          rows="5"
                          v-model="replayText"
                          placeholder="最近どう？"
                        ></textarea>
                        <div class="text-end" style="font-size: 10px">
                          {{ replayText.length }}/300
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 画像 -->
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              style="display: none"
              @change="fileUpload"
            />
            <div
              v-if="img_urls.length > 0"
              style="
                height: 210px;
                display: flex;
                overflow-x: scroll;
                margin-bottom: 10px;
              "
            >
              <div
                v-for="(img_url, i) in img_urls"
                :key="i"
                style="float: left; flex-shrink: 0; width: auto; height: 210px"
              >
                <div
                  style="
                    position: relative;
                    float: right;
                    right: 30px;
                    top: 5px;
                    text-shadow:
                      1px 1px 2px #ffffff,
                      -1px 1px 2px #ffffff,
                      1px -1px 2px #ffffff,
                      -1px -1px 2px #ffffff,
                      1px 0px 2px #ffffff,
                      0px 1px 2px #ffffff,
                      -1px 0px 2px #ffffff,
                      0px -1px 2px #ffffff;
                  "
                >
                  <span
                    class="material-symbols-rounded"
                    style="color: red"
                    @click="removeImgs(i)"
                  >
                    close
                  </span>
                </div>
                <img
                  style="
                    width: auto;
                    height: 200px;
                    margin: 5px 5px 5px 0;
                    border-radius: 8px;
                    border: solid 1px #eee;
                  "
                  :src="img_url"
                />
              </div>
            </div>
            <div v-if="img_urls.length != 0">
              <div class="form-check" style="padding-bottom: 6px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  style="margin-top: 7px"
                  v-bind:checked="isSensitive"
                  v-on:change="isSensitive = !isSensitive"
                />
                <label
                  class="form-check-label"
                  style="color: #888; font-size: 12px"
                >
                  アップロードする画像をセンシティブ設定にする,
                </label>
              </div>
            </div>
            <!-- 動画 -->
            <input
              id="video-upload"
              type="file"
              accept="video/*"
              style="display: none"
              @change="fileVideoUpload"
            />
            <div v-if="uploadVideoURL" style="padding: 5px 0">
              <video controls autoplay :src="uploadVideoURL" width="100%" />
              <div class="text-center" style="padding: 5px; font-size: 13px">
                <a @click="resetVideo">動画を削除する</a>
              </div>
            </div>
            <!-- タグ -->
            <div v-if="selectTags.length != 0">
              <div
                v-for="(tag, i) in selectTags"
                :key="i"
                class=""
                style="
                  display: inline-flex;
                  color: #784ba0;
                  font-size: 14px;
                  font-weight: 600;
                  position: relative;
                  padding: 0 5px;
                "
              >
                #{{ tag }}
              </div>
            </div>
            <!-- メンション -->
            <div v-if="choiceUsers.length != 0">
              <div
                v-for="(user, i) in choiceUsers"
                :key="i"
                class=""
                style="
                  display: inline-flex;
                  color: #784ba0;
                  font-size: 14px;
                  font-weight: 600;
                  position: relative;
                  padding: 0 5px;
                "
              >
                @{{ user.name }}
              </div>
            </div>
            <!-- グループチャット -->
            <div
              v-if="roomSelected != 0"
              style="color: #784ba0; padding-top: 3px"
            >
              <div style="font-size: 11px">グループチャット連携：</div>
              <div style="font-size: 16px; font-weight: 600">
                {{ groupChat }}
              </div>
            </div>
            <!-- 期限 -->
            <div>
              <div class="note-timer" v-if="noteTimer == 12">
                翌日の12時に投稿を削除
              </div>
              <div class="note-timer" v-else-if="noteTimer == 24">
                翌日の24時に投稿を削除
              </div>
            </div>
            <hr />
            <div style="height: 30px; width: 100%; display: inline-flex">
              <div style="min-width: 35px">
                <label for="file-upload">
                  <div style="width: 30px; height: 30px; padding: 2.5px">
                    <span
                      class="material-symbols-rounded"
                      style="font-size: 25px; color: #784ba0"
                    >
                      add_photo_alternate
                    </span>
                  </div>
                </label>
              </div>
              <div style="min-width: 35px">
                <label for="video-upload">
                  <div style="width: 30px; height: 30px; padding: 2.5px">
                    <span
                      class="material-symbols-rounded"
                      style="font-size: 25px; color: #784ba0"
                    >
                      video_call
                    </span>
                  </div>
                </label>
              </div>
              <div style="min-width: 35px">
                <div
                  style="width: 30px; height: 30px; padding: 2.5px"
                  @click="
                    ((myBottomSheet = !myBottomSheet),
                    (sheetTag = true),
                    (sheetMention = false),
                    (sheetGroupChat = false),
                    (sheetTime = false))
                  "
                >
                  <span
                    class="material-symbols-rounded"
                    style="font-size: 25px; color: #784ba0"
                  >
                    tag
                  </span>
                </div>
              </div>
              <!-- <div style="min-width: 35px">
                <div
                  style="width: 30px; height: 30px; padding: 2.5px"
                  @click="
                    ((myBottomSheet = !myBottomSheet),
                    (sheetTag = false),
                    (sheetMention = true),
                    (sheetGroupChat = false),
                    (sheetTime = false))
                  "
                >
                  <span
                    class="material-symbols-rounded"
                    style="font-size: 25px; color: #784ba0"
                  >
                    alternate_email
                  </span>
                </div>
              </div> -->
              <!-- <div style="min-width: 35px">
                <div
                  style="width: 30px; height: 30px; padding: 2.5px"
                  @click="
                    (myBottomSheet = !myBottomSheet),
                      (sheetTag = false),
                      (sheetMention = false),
                      (sheetGroupChat = true),
                      (sheetTime = false)
                  "
                >
                  <span
                    class="material-symbols-rounded"
                    style="font-size: 25px; color: #784ba0"
                  >
                    forum
                  </span>
                </div>
              </div> -->
              <div style="min-width: 35px">
                <div
                  style="width: 30px; height: 30px; padding: 2.5px"
                  @click="
                    ((myBottomSheet = !myBottomSheet),
                    (sheetTag = false),
                    (sheetMention = false),
                    (sheetGroupChat = false),
                    (sheetTime = true))
                  "
                >
                  <span
                    class="material-symbols-rounded"
                    style="font-size: 25px; color: #784ba0"
                  >
                    alarm
                  </span>
                </div>
              </div>
              <div style="padding: 0 0 15px 10px; width: 100%">
                <select
                  style="
                    border: 0;
                    width: 100%;
                    font-size: 11px;
                    padding: 5px 15px;
                    border-radius: 20px;
                    color: #fff;
                    -webkit-appearance: none;
                    appearance: none;
                    background-image: url(./../../images/circle-arrow-down-solid.svg);
                    background-repeat: no-repeat;
                    background-size: 14px auto;
                    background-color: #784ba0;
                    background-position: right 12px center;
                  "
                  v-model="selected"
                >
                  <option value="all">{{ selectedRangeName }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
  <v-bottom-sheet v-model="myBottomSheet">
    <div class="container" style="min-height: 100%">
      <div class="row justify-content-center" style="min-height: 100%">
        <div class="col-md-8" style="padding: 0; min-height: 100%">
          <v-sheet height="500px" style="border-radius: 15px 15px 0 0">
            <div style="padding: 0px" class="text-center">
              <span
                class="material-symbols-rounded"
                style="color: #ccc; font-size: 40px"
                @click="myBottomSheet = !myBottomSheet"
              >
                remove
              </span>
            </div>
            <!-- ---------- タグ編集 ---------- -->
            <div style="height: 100%; padding: 0 15px" v-if="sheetTag">
              <div style="padding-bottom: 5px">タグ編集</div>
              <div style="padding-top: 5px">
                <div style="margin-bottom: 10px; width: 100%; height: 30px">
                  <div style="float: left; width: 90%; height: 40px">
                    <vue-simple-suggest
                      style="height: 30px; font-size: 14px"
                      v-model="valueCurrent"
                      :list="autocompleteItems"
                      :filter-by-query="true"
                      @update:model-select="(item) => (selectedCurrent = item)"
                    />
                  </div>
                  <div style="float: left; width: 10%; height: 30px">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      style="
                        height: 30px;
                        border-radius: 0 8px 8px 0;
                        border: solid 1px #aaa;
                        padding: 0px 10px;
                      "
                      @click="setTags"
                    >
                      <span
                        class="material-symbols-rounded"
                        style="font-size: 16px"
                      >
                        edit
                      </span>
                    </button>
                  </div>
                </div>
                <div style="margin-bottom: 10px; width: 100%; min-height: 30px">
                  <div v-if="selectTags.length != 0">
                    <div
                      v-for="(tag, i) in selectTags"
                      :key="i"
                      class="alert alert-dismissible fade show"
                      role="alert"
                      style="
                        display: inline-flex;
                        color: #fff;
                        background-color: #633988;
                        font-size: 14px;
                        position: relative;
                      "
                    >
                      #{{ tag }}
                      <button
                        type="button"
                        style="color: #fff; margin-top: -3px"
                        class="close"
                        @click="removeTag(i)"
                      >
                        <span aria-hidden="true" style="font-size: 18px"
                          >&times;</span
                        >
                      </button>
                    </div>
                  </div>
                  <div v-else>
                    <div style="font-size: 12px; color: #ccc">
                      タグは入力されていません
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ---------- メンション選択 ---------- -->
            <div style="height: 100%; padding: 0 15px" v-else-if="sheetMention">
              <div style="padding-bottom: 5px">メンション選択</div>
              <div>
                <input
                  type="text"
                  class="form-control"
                  v-model="searchWord"
                  @keydown.enter="searchMention"
                />
              </div>
              <div
                v-if="resultUsers.length != 0"
                style="padding: 10px; overflow-y: scroll"
              >
                <div
                  v-for="(user, i) in resultUsers"
                  :key="i"
                  :class="
                    choiceUsers.includes(user)
                      ? 'item-mention-selected'
                      : 'item-mention'
                  "
                  @click="setChoiceUsers(user)"
                >
                  <listIcon :uid="user.uid" :is_icon="user.isIconUrl" />
                  <div style="padding-top: 8px; padding-left: 5px">
                    @{{ user.name }}
                  </div>
                </div>
              </div>
            </div>
            <!-- ---------- グループチャット ---------- -->
            <div
              style="height: 100%; padding: 0 15px"
              v-else-if="sheetGroupChat"
            >
              <div style="padding-bottom: 10px">グループチャット連携</div>
              <div>
                <select
                  style="
                    border: solid 1px #ccc;
                    width: 100%;
                    font-size: 13px;
                    padding: 5px;
                    border-radius: 6px;
                    -webkit-appearance: auto;
                    appearance: auto;
                    background-position: right 12px center;
                  "
                  v-model="roomSelected"
                  @change="groupChatChange"
                >
                  <option value="0">-</option>
                  <option
                    v-for="(room, i) in rooms"
                    :key="i"
                    :value="room.roomId"
                  >
                    {{ room.message }}
                  </option>
                </select>
                <div class="text-center" style="padding-top: 20px">
                  <a
                    @click="((roomSelected = 0), closeSheet)"
                    style="
                      font-size: 14px;
                      color: #784ba0;
                      text-decoration: underline;
                    "
                    >グループチャット連携を解除</a
                  >
                </div>
              </div>
            </div>
            <!-- ---------- インスタント設定 ---------- -->
            <div style="height: 100%; padding: 0 15px" v-else-if="sheetTime">
              <div style="padding-bottom: 5px">インスタント設定</div>
              <div
                style="
                  padding-bottom: 15px;
                  font-size: 12px;
                  padding: 8px;
                  background-color: #efefef;
                "
              >
                投稿後に翌日の12時もしくは24時に投稿を削除します。
              </div>
              <div class="form-check" style="padding-top: 20px">
                <input
                  class="form-check-input"
                  type="radio"
                  value="12"
                  v-model="noteTimer"
                  @click="closeSheet"
                />
                <label class="form-check-label"> 翌日の12時に投稿を削除 </label>
              </div>
              <div class="form-check" style="padding-top: 10px">
                <input
                  class="form-check-input"
                  type="radio"
                  value="24"
                  v-model="noteTimer"
                  @click="closeSheet"
                />
                <label class="form-check-label"> 翌日の24時に投稿を削除 </label>
              </div>
              <div class="text-center" style="padding-top: 20px">
                <a
                  @click="((noteTimer = 0), closeSheet)"
                  style="
                    font-size: 14px;
                    color: #784ba0;
                    text-decoration: underline;
                  "
                  >インスタンス設定を解除</a
                >
              </div>
            </div>
          </v-sheet>
        </div>
      </div>
    </div>
  </v-bottom-sheet>
</template>

<script>
import listIcon from "@/components/timeline/listIconView.vue";
import { db, storage } from "../../plugins/firebase";
import {
  doc,
  collection,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  arrayUnion,
  setDoc,
} from "firebase/firestore";
import {
  ref as storageRef,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { store } from "../../plugins/store";

import VueSimpleSuggest from "@vojtechlanka/vue-simple-suggest";
import "@vojtechlanka/vue-simple-suggest/style.css";
import imageCompression from "browser-image-compression";

export default {
  data() {
    return {
      isEdit: false,
      uid: "",
      user: {},
      replayText: "",
      field: null,
      selectTags: [],
      valueCurrent: "",
      selectedCurrent: null,
      autocompleteItems: [],
      selected: "all",
      roomSelected: "0",
      files: [],
      img_urls: [],
      isUploadError: false,
      rooms: [],
      isSensitive: false,
      myBottomSheet: false,
      sheetTag: false,
      sheetMention: false,
      sheetGroupChat: false,
      sheetTime: false,
      noteTimer: 0,
      groupChat: "",
      searchWord: "",
      searchUsers: [], //検索対象（初期化の時にfirestoreから呼び出し
      resultUsers: [], //モーダル内で表示する検索結果
      choiceUsers: [], //メンションとして選択されたユーザたち
      fileVideo: null,
      uploadVideoURL: "",
      selectedRange: "all",
      selectedRangeName: "全員に公開",
    };
  },
  async created() {
    this.selectedRange = this.$route.query.range;

    if (this.selectedRange == "all") {
      this.selectedRangeName = "全員に公開";
    } else if (this.selectedRange == "follower") {
      this.selectedRangeName = "フォロワーに公開";
    } else if (this.selectedRange == "group") {
      this.selectedRangeName = "グループ公開";
    } else if (this.selectedRange == "like") {
      this.selectedRangeName = "いいね公開";
    } else if (this.selectedRange == "matching") {
      this.selectedRangeName = "マッチング公開";
    } else {
      this.selectedRangeName = "#" + this.selectedRange + "界隈";
    }

    this.user = store.user;
    this.uid = store.uid;

    const docTagRef = doc(db, "tags", "1");
    const docTagSnap = await getDoc(docTagRef);
    this.autocompleteItems = docTagSnap.data().tagList;

    const docUserListRef = doc(db, "user_list", "1");
    const docUserListSnap = await getDoc(docUserListRef);
    this.searchUsers = docUserListSnap.data().list;

    const docRoomRef = doc(db, "users", this.uid);
    const subcollectionRef = collection(docRoomRef, "groupChats");
    const subcollectionSnapshot = await getDocs(subcollectionRef);

    this.rooms = subcollectionSnapshot.docs.map((doc) => doc.data());
  },
  components: {
    listIcon,
    VueSimpleSuggest,
  },
  methods: {
    /**
     * 投稿登録
     * @classdesc 新しい投稿を追加する
     * @classdesc 投稿文が空の場合はアラート
     * @classdesc 下記コレクションに追加
     * @classdesc users/$uid/notes
     * @classdesc notes/$noteId
     * @classdesc 2秒待機後にhomeに戻る
     */
    async sendTweet() {
      if (this.replayText == "") {
        this.$toast.show("投稿内容を入力してください", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      } else if (this.replayText.length > 300) {
        this.$toast.show("300文字以内にしてください", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
      this.isEdit = true;

      var noteId = "";

      const docRef = await addDoc(
        collection(db, `users/${this.user.uid}/notes`),
        {
          uid: this.user.uid,
        },
      );
      noteId = docRef.id;

      const now = new Date();
      const yyyy = now.getFullYear();
      const mm = ("00" + (now.getMonth() + 1)).slice(-2);
      const dd = ("00" + now.getDate()).slice(-2);

      var tagView = "";
      for (const e of this.selectTags) {
        tagView += `#${e} `;
      }

      var lineList = [noteId];

      await setDoc(doc(db, "users", this.user.uid, "notes", noteId), {
        noteId: noteId,
        uid: this.user.uid,
        userName: this.user.name,
        isIconUrl: this.user.isIconUrl,
        detail: this.replayText,
        likeUID: [],
        tags: this.selectTags,
        tagView: tagView,
        roomId: this.roomSelected,
        createdAt: new Date(),
        createdAtView: `${yyyy}年${mm}月${dd}日`,
        isReply: false,
        replyCount: 0,
        lineHistory: lineList,
        mentions: this.choiceUsers,
        limit: this.noteTimer,
        range: this.selectedRange,
        isSensitiveImageByTweet: this.isSensitive,
        noteIndex: 1,
        isRemove: false,
        isProfileShare: false,
      });

      await setDoc(doc(db, "notes", noteId), {
        noteId: noteId,
        uid: this.user.uid,
        isIconUrl: this.user.isIconUrl,
        userName: this.user.name,
        detail: this.replayText,
        likeUID: [],
        tags: this.selectTags,
        tagView: tagView,
        roomId: this.roomSelected,
        createdAt: new Date(),
        createdAtView: `${yyyy}年${mm}月${dd}日`,
        isReply: false,
        replyCount: 0,
        lineHistory: lineList,
        mentions: this.choiceUsers,
        limit: this.noteTimer,
        range: this.selectedRange,
        isSensitiveImageByTweet: this.isSensitive,
        noteIndex: 1,
        isRemove: false,
        isProfileShare: false,
      });

      try {
        //image
        const docUpdateImageRef = doc(db, "notes", noteId);
        const docUpdateUserImageRef = doc(
          db,
          "users",
          this.user.uid,
          "notes",
          noteId,
        );

        for (var i in this.files) {
          var imageId = "";
          const docImageRef = await addDoc(
            collection(db, `notes/${noteId}/images`),
            {
              uid: this.user.uid,
            },
          );
          imageId = docImageRef.id;

          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(this.files[i], options);
          const fileRef = storageRef(
            storage,
            `notes/${imageId}/${compressedFile.name}`,
          );
          await uploadBytes(fileRef, compressedFile);

          await updateDoc(docUpdateImageRef, {
            url: arrayUnion(`notes/${imageId}/${compressedFile.name}`),
          });
          await updateDoc(docUpdateUserImageRef, {
            url: arrayUnion(`notes/${imageId}/${compressedFile.name}`),
          });
        }

        //video
        if (this.fileVideo != null) {
          var videoId = "";
          const docImageRef = await addDoc(
            collection(db, `notes/${noteId}/videos`),
            {
              uid: this.user.uid,
            },
          );
          videoId = docImageRef.id;

          const fileName = `${Date.now()}_${this.fileVideo.name}`;

          await updateDoc(docUpdateImageRef, {
            videoUrl: `notes/videos/${videoId}/${fileName}`,
          });
          await updateDoc(docUpdateUserImageRef, {
            videoUrl: `notes/videos/${videoId}/${fileName}`,
          });

          const fileRef = storageRef(
            storage,
            `notes/videos/${videoId}/${fileName}`,
          );
          const uploadTask = uploadBytesResumable(fileRef, this.fileVideo);

          uploadTask.on(
            "state_changed",
            null,
            (uploadError) => {
              console.error = `アップロードエラー: ${uploadError.message}`;
            },
            async () => {
              const uploadURL = await getDownloadURL(uploadTask.snapshot.ref);

              console.log("アップロード成功: ", uploadURL);
            },
          );
        }
      } catch (error) {
        console.error("Error uploading file:", error);

        this.isEdit = false;
        this.$toast.show("投稿時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }

      // todo::
      /**
       * 通知
       * メンションがある場合はメンション先に通知する
       *
       * タグ
       * 新しく入力してる場合は追加
       */

      this.$toast.show("投稿完了しました", {
        position: "bottom",
        duration: 2000,
      });
      await this.delay(2000);
      this.isEdit = false;
      this.$router.push("/home");
    },
    removeTag(i) {
      this.selectTags.splice(i, 1);
    },
    setTags() {
      if (this.valueCurrent == "") {
        return;
      }

      if (!this.selectTags.includes(this.valueCurrent)) {
        this.selectTags.push(this.valueCurrent);
        this.valueCurrent = "";
        this.selectedCurrent = null;
        this.field = null;
      }
    },
    fileUpload(event) {
      if (this.img_urls.length >= 4) {
        this.isUploadError = true;
      } else {
        const file = event.target.files[0];

        this.img_urls.push(URL.createObjectURL(file));
        this.files.push(file);
      }
    },
    removeImgs(i) {
      this.isUploadError = false;
      this.img_urls.splice(i, 1);
      this.files.splice(i, 1);
      if (this.img_urls.length == 0) {
        this.isSensitive = false;
      }
    },
    async groupChatChange(event) {
      this.groupChat = event.target.options[event.target.selectedIndex].text;
      await this.delay(500);
      this.myBottomSheet = false;
    },
    searchMention() {
      if (this.searchWord == "") {
        return;
      }
      const searchRegex = new RegExp(this.searchWord, "i"); // あいまい検索用の正規表現
      this.resultUsers = this.searchUsers.filter((item) =>
        searchRegex.test(item.name),
      );
    },
    setChoiceUsers(user) {
      if (this.choiceUsers.includes(user)) {
        const index = this.choiceUsers.indexOf(user);
        if (index !== -1) {
          this.choiceUsers.splice(index, 1); // 配列から削除
        }
      } else {
        this.choiceUsers.push(user);
      }
    },
    fileVideoUpload(event) {
      const selectedFile = event.target.files[0];

      if (!selectedFile) {
        return;
      }

      // 動画ファイルか確認
      if (selectedFile.type.startsWith("video/")) {
        this.fileVideo = selectedFile;
        this.uploadVideoURL = URL.createObjectURL(selectedFile);

        // 一時的なURL生成
        const tempURL = URL.createObjectURL(selectedFile);
        // 一時的なvideo要素を作成してメタデータを読み込む
        const videoElement = document.createElement("video");
        videoElement.preload = "metadata";
        videoElement.src = tempURL;

        videoElement.onloadedmetadata = () => {
          // 動画の長さ（秒）をチェック：3分（180秒）以内か
          if (videoElement.duration <= 180) {
            this.fileVideo = selectedFile;
            this.uploadVideoURL = tempURL; // プレビュー用に保持（コンポーネントの破棄時に revoke するなどの管理を検討）
          } else {
            this.fileVideo = null;
            this.uploadVideoURL = "";
            // 不要なオブジェクトURLは解放
            URL.revokeObjectURL(tempURL);

            this.$toast.show("動画の長さは3分以内である必要があります", {
              type: "error",
              position: "bottom",
              duration: 2000,
            });
          }
        };

        videoElement.onerror = () => {
          this.fileVideo = null;
          this.uploadVideoURL = "";
          URL.revokeObjectURL(tempURL);
        };
      }
    },
    resetVideo() {
      this.fileVideo = null;
      this.uploadVideoURL = "";
    },
    async closeSheet() {
      await this.delay(500);
      this.myBottomSheet = false;
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
  beforeUnmount() {
    if (this.videoURL) {
      URL.revokeObjectURL(this.uploadVideoURL); // メモリリーク防止
    }
  },
};
</script>

<style scoped>
textarea {
  border: 0;
  border-radius: 0;
  resize: none;
}
.timeline-edit {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  min-height: 100%;
  background-color: #fff;
}

.page {
  padding-top: 5px;
}

.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  width: 100%;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.icon {
  color: #784ba0;
  font-size: 30px;
}
.header-item {
  float: left;
}
.header-item-size {
  padding-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
}
.title {
  color: #784ba0;
  font-size: 30px;
}

.header-back {
  width: 100%;
  height: 60px;
  z-index: 99;
}
.note-timer {
  color: #784ba0;
  font-size: 12px;
  font-weight: 600;
  padding-top: 5px;
}
.item-mention {
  display: inline-flex;
  color: #784ba0;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  padding: 5px;
  width: 100%;
  margin: 3px 0;
}
.item-mention-selected {
  display: inline-flex;
  color: #784ba0;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  padding: 5px;
  width: 100%;
  margin: 3px 0;
  background-color: #784ba03b;
  border-radius: 5px;
}
</style>
