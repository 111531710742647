<template>
  <AppHeader
    :is_icon="false"
    :is_back="true"
    :title="'退会ページ'"
    type="none"
  />
  <div class="withdrawal-page">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div style="padding: 0 8px">
              <div
                class="text-center"
                style="color: #784ba0; font-size: 16px; font-weight: bold"
              >
                退会する前に<br />こちらをご確認ください
              </div>
              <br />
              <div>
                <div
                  style="
                    padding: 15px 20px;
                    font-size: 14px;
                    background-color: rgb(244, 245, 247);
                    border-radius: 6px;
                  "
                >
                  <ul>
                    <li>退会するとアカウントへのログインができません</li>
                    <li>いいねやマッチングなどの履歴が削除されます</li>
                    <li>メッセージのデータが削除されます</li>
                  </ul>
                  <div class="text-center">
                    <b>ご確認の上、退会するようお願いいたします</b>
                  </div>
                </div>
              </div>
              <br />
              <div
                style="padding: 10px 10px; font-size: 14px"
                v-if="user.isSubscription && !isCancel && user.sex == '男性'"
              >
                <div
                  class="text-center"
                  style="
                    color: #784ba0;
                    font-size: 14px;
                    font-weight: bold;
                    padding-bottom: 10px;
                  "
                >
                  Kinkyer+に加入中です
                </div>
                退会するにはKinkyer+の解約をお願いいたします<br />
                解約は<a
                  style="
                    margin: 0 5px;
                    color: #784ba0;
                    font-weight: 700;
                    text-decoration: underline;
                  "
                  @click="linkPage('/mypage/plan/cancel')"
                  >こちら</a
                >から<br />
                <div style="padding-top: 5px; font-size: 12px">
                  {{
                    setDateView(user.lastSubscriptionAt)
                  }}までKinkyer＋として利用可能です
                </div>
                <br />
              </div>
              <div class="item-box" style="padding: 0 10px">
                <div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 7px"
                      v-bind:checked="isUserRemove"
                      v-on:change="isUserRemove = !isUserRemove"
                    />
                    <label class="form-check-label" style="color: #784ba0">
                      データが削除されることを許可します
                    </label>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-8">
            <button
              v-if="isUserRemove && isCancel"
              type="button"
              style="width: 100%; height: 40px"
              class="btn btn-kinkyer m-plus-medium"
              @click="remove()"
            >
              退会する
            </button>
            <button
              v-else
              type="button"
              style="
                width: 100%;
                height: 40px;
                background-color: #bbb;
                border-color: #bbb;
              "
              class="btn btn-kinkyer m-plus-medium"
            >
              退会する
            </button>
          </div>
        </div>
        <div
          v-if="isUserRemove && !isCancel"
          class="text-center"
          style="color: red; font-size: 12px; font-weight: bold"
        >
          退会するにはKinkyer+の解約をお願いいたします
        </div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/HeaderView.vue";
import { store, removeAll } from "../../plugins/store";
import { db, auth } from "../../plugins/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged, deleteUser } from "firebase/auth";

export default {
  data() {
    return {
      user: {},
      login: null,
      isEdit: false,
      isUserRemove: false,
      isCancel: false, //解約フラグ（trueであれば解約処理を済ませてる
    };
  },
  created() {
    this.user = store.user;
    this.isCancel =
      this.user.isCancel == undefined ? false : this.user.isCancel;

    if (!this.user.isSubscription) {
      // そもそも課金済みでなければisCancelはtrueにする
      this.isCancel = true;
    }
    if (this.user.sex == "女性") {
      // 女性の場合isCancelはtrueにする
      this.isCancel = true;
    }
  },
  mounted() {
    // Firebase Authentication の状態を監視
    onAuthStateChanged(auth, (currentUser) => {
      this.login = currentUser;
    });
  },
  components: {
    AppHeader,
  },
  methods: {
    async remove() {
      // 退会フラグ更新
      const itemUserDoc = doc(db, "users", this.user.uid);
      await updateDoc(itemUserDoc, {
        isEntry: false,
        isWithdrawal: true,
      });

      // authは削除
      await deleteUser(this.login);
      removeAll();
      this.$router.replace("/");
    },
    linkPage(url) {
      this.$router.push(url);
    },
    setDateView(date) {
      try {
        let status = "今";
        let lg = new Date(date.toDate());
        const yyyy = lg.getFullYear();
        const mm = ("00" + (lg.getMonth() + 1)).slice(-2);
        const dd = ("00" + lg.getDate()).slice(-2);
        status = `${yyyy}年${mm}月${dd}日`;

        return status;
      } catch {
        return "-";
      }
    },
  },
};
</script>

<style scoped>
.withdrawal-page {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 80px;
}
.item-box {
  padding-bottom: 10px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
  padding-top: 0px;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
  padding: 0 20px;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
