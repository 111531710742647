<template>
  <div v-if="note.isRemove">
    <div style="padding: 5px 30px 5px 30px">
      <div
        style="
          background-color: #f3f3f344;
          padding: 10px 20px;
          border: solid 1px #f3f3f3;
          border-radius: 8px;
          font-size: 14px;
          color: #888;
        "
      >
        投稿は削除されました
      </div>
    </div>
    <div
      v-show="pageMode == 0 || pageMode == 1"
      style="margin: 25px 0; border: 1px; border-top: solid 1px #f3f3f3"
    ></div>
    <div v-if="pageMode == 4" style="height: 30px"></div>
  </div>
  <div v-else>
    <table class="table table-borderless" style="margin: 0">
      <tbody>
        <tr style="padding: 0">
          <td
            style="vertical-align: top; text-align: end; padding: 0"
            width="50"
          >
            <listIcon :uid="note.uid" :is_icon="note.isIconUrl" />
          </td>
          <td style="word-break: break-word; padding: 0 15px">
            <div class="row">
              <div class="col">
                <div
                  v-show="note.isReboot"
                  style="font-size: 11px; color: #777; font-weight: 500"
                >
                  <div>
                    <span
                      class="material-symbols-rounded"
                      style="
                        font-size: 14px;
                        top: 2px;
                        position: relative;
                        font-size: 14px;
                      "
                    >
                      sync </span
                    >リブート投稿
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  v-show="note.range != 'all'"
                  class="text-end"
                  style="
                    font-size: 11px;
                    color: #784ba0;
                    font-weight: 700;
                    font-size: 10px;
                    padding-top: 3px;
                  "
                >
                  {{ rangeName }}
                </div>
              </div>
            </div>
            <div style="height: 25px">
              <div
                class=""
                style="
                  max-width: 180px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >
                {{ note.userName }}
              </div>
              <div
                style="
                  font-size: 10px;
                  padding-top: 2px;
                  display: flex;
                  justify-content: flex-end;
                  position: relative;
                  top: -20px;
                "
                class="text-end"
              >
                {{ setDateView(note.createdAt) }}
              </div>
            </div>
            <div
              v-if="note.hasOwnProperty('repliedNote') && pageMode != 4"
              style="
                padding-bottom: 10px;
                padding-top: 10px;
                font-size: 12px;
                max-width: 250px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              <span
                style="
                  background-color: rgb(215, 201, 227);
                  padding: 4px 16px;
                  border-radius: 20px;
                  min-width: 200px;
                "
                @click="linkTimelineHistory(note)"
              >
                <span style="color: #784ba0">返信先:</span
                >{{ truncateText(note.repliedNote.userName + "さん") }}</span
              >
            </div>
            <div
              class="text-start"
              style="padding-top: 2px; white-space: pre-wrap; font-size: 14px"
            >
              <div
                @click="linkTimelineDatail(note)"
                v-html="autoLink(note.detail)"
              ></div>
              <ul>
                <li v-for="(hobby, i) in note.tags" :key="i">
                  <span @click="setQuery(hobby)">#{{ hobby }}</span>
                </li>
              </ul>
            </div>
            <div v-if="ogpData != null" style="padding: 0 10px 0 0">
              <a :href="ogpData.url" target="_blank">
                <img :src="ogpData.image" style="width: 100%" />
                <div style="font-size: 12px" class="text-end">
                  {{ ogpData.title }}
                </div>
              </a>
            </div>
            <div v-if="note.isProfileShare">
              <div
                style="
                  border: solid 1px #ddd;
                  border-radius: 8px;
                  padding: 10px;
                "
              >
                <table class="table table-borderless" style="margin: 0">
                  <tbody>
                    <tr style="padding: 0">
                      <td style="word-break: break-word; padding: 0 15px">
                        <div>
                          <div>
                            {{ note.shareUser.name }}
                          </div>
                          <div
                            style="font-size: 12px; color: #777"
                            v-if="note.shareUser.isAgeView"
                          >
                            {{
                              note.shareUser.age + "歳/" + note.shareUser.sex
                            }}
                          </div>
                          <div style="font-size: 12px; color: #777" v-else>
                            {{ "秘密/" + note.shareUser.sex }}
                          </div>
                          <div style="padding-top: 4px">
                            <div
                              style="
                                overflow: hidden;
                                display: flex;
                                flex-wrap: wrap;
                              "
                            >
                              <div
                                style="
                                  padding: 0px 13px 5px 13px;
                                  background-color: #784ba0;
                                  color: #fff;
                                  margin-right: 5px;
                                  margin-bottom: 3px;
                                  white-space: nowrap;
                                  border-radius: 20px;
                                "
                                v-for="(tag, i) in note.shareUser.selectTag"
                                :key="i"
                              >
                                <span style="font-size: 12px">{{ tag }}</span>
                              </div>
                            </div>
                          </div>
                          <div style="padding-top: 4px">
                            <div style="font-size: 14px">
                              {{ truncateText(note.shareUser.profile) }}
                            </div>
                          </div>
                          <div style="padding-top: 10px">
                            <div style="width: 100%; height: 30px">
                              <div
                                style="
                                  width: 30px;
                                  font-size: 14px;
                                  float: left;
                                  margin-top: -2px;
                                "
                              >
                                S度
                              </div>
                              <div
                                style="width: calc(100% - 30px); float: left"
                              >
                                <div class="progress">
                                  <div
                                    style="background-color: #543fdd"
                                    class="progress-bar"
                                    role="progressbar"
                                    :style="
                                      'width: ' + note.shareUser.sadistic + '%'
                                    "
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    {{ note.shareUser.sadistic }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style="width: 100%; height: 30px">
                              <div
                                style="
                                  width: 30px;
                                  font-size: 14px;
                                  float: left;
                                  margin-top: -2px;
                                "
                              >
                                M度
                              </div>
                              <div
                                style="width: calc(100% - 30px); float: left"
                              >
                                <div class="progress">
                                  <div
                                    style="background-color: #d85992"
                                    class="progress-bar"
                                    role="progressbar"
                                    :style="
                                      'width: ' + note.shareUser.masochist + '%'
                                    "
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    {{ note.shareUser.masochist }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style="padding-top: 10px">
                            <div class="speech-bubble">
                              {{ note.shareUser.comment }}
                            </div>
                          </div>
                          <div
                            style="font-size: 10px; padding-top: 5px"
                            class="text-end"
                          >
                            プロフィール更新日：{{
                              setDateView(note.shareUser.loginAt)
                            }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <div
                  class="text-center"
                  style="padding-bottom: 8px; font-size: 14px; color: #784ba0"
                >
                  <a @click="myBottomProfileSheet = true">詳細を表示する</a>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      :style="
        note.url != null
          ? 'height: ' + innerImageHeight + 'px; margin-bottom: 30px'
          : ''
      "
    >
      <div style="padding-right: 15px">
        <listImageView
          :uids="note.url"
          :mode="pageMode"
          @handle-list-modal-view="handleListModalView"
        />
      </div>
    </div>
    <div v-if="videoURL" style="padding: 5px 15px 5px 60px">
      <video controls autoplay playsinline :src="videoURL" width="100%" />
    </div>
    <div style="padding-left: 65px; padding-top: 5px; padding-bottom: 15px">
      <div
        style="display: flex; flex-wrap: wrap; width: 100%"
        v-if="user.isEntry"
      >
        <span
          v-for="(item, i) in selectedEmojis"
          :key="i"
          style="
            font-size: 12px;
            background-color: #f0f0f0;
            padding: 2px 8px 8px 8px;
            border-radius: 20px;
            margin-right: 3px;
            margin-bottom: 3px;
          "
          @click="selectEmoji(item)"
        >
          <img :src="item.url" alt="SVG Icon" width="16" height="16" />
          <span style="margin-left: 2px; bottom: -2px; position: relative">{{
            item.users.length
          }}</span></span
        >
      </div>
      <div style="display: flex; flex-wrap: wrap; width: 100%" v-else>
        <span
          v-for="(item, i) in selectedEmojis"
          :key="i"
          style="
            font-size: 12px;
            background-color: #f0f0f0;
            padding: 2px 8px 8px 8px;
            border-radius: 20px;
            margin-right: 3px;
            margin-bottom: 3px;
          "
        >
          <img :src="item.url" alt="SVG Icon" width="16" height="16" />
          <span style="margin-left: 2px; bottom: -2px; position: relative">{{
            item.users.length
          }}</span></span
        >
      </div>
    </div>
    <div v-if="pageMode != 2 && pageMode != 3">
      <div
        style="padding-right: 20px; position: absolute; right: 0px; height: 0"
        v-if="isTooltip"
      >
        <div
          style="
            bottom: 50px;
            position: relative;
            padding: 10px 20px;
            background-color: #efefef;
            border-radius: 6px;
            font-size: 12px;
            box-shadow: rgb(0 0 0 / 40%) 0px 0px 10px 0rem;
          "
          @click="removeNote()"
        >
          投稿を削除
        </div>
      </div>
      <div
        style="height: 20px; padding-left: 65px; padding-top: 0px"
        v-if="user.isEntry"
      >
        <div class="item-box">
          <span
            class="material-symbols-rounded icon-style"
            @click="setReply(note)"
          >
            chat
          </span>
          <span
            class="m-plus-regular"
            style="
              font-size: 12px;
              color: #888;
              bottom: 8px;
              position: relative;
              left: 2px;
            "
            v-if="note.replyCount > 0"
            >{{ formatLargeNumber(note.replyCount) }}</span
          >
        </div>
        <div class="item-box">
          <span
            v-if="note.likeUID.includes(user.uid)"
            class="material-symbols-rounded icon-like-style"
            :style="filledStyle"
            @click="setLike()"
          >
            favorite
          </span>
          <span
            v-else
            class="material-symbols-rounded icon-style"
            :style="noFillStyle"
            @click="setLike()"
            >favorite</span
          >
          <span
            class="m-plus-regular"
            style="
              font-size: 12px;
              color: #888;
              bottom: 8px;
              position: relative;
              left: 2px;
            "
            v-if="note.likeUID.length > 0"
            >{{ formatLargeNumber(note.likeUID.length) }}</span
          >
        </div>
        <div class="item-box">
          <span
            class="material-symbols-rounded icon-style"
            @click="myBottomSheet = true"
          >
            add_reaction
          </span>
        </div>
        <div class="item-box">
          <!-- <span
            class="material-symbols-rounded icon-style"
            @click="setSecretReply()"
          >
            send
          </span> -->
        </div>
        <div class="item-box">
          <!-- <span
            class="material-symbols-rounded icon-style"
            @click="setGroupChat()"
            v-if="note.roomId != 0"
          >
            forum
          </span>
          <span class="material-symbols-rounded un-icon-style" v-else>
            forum
          </span> -->
        </div>
        <div class="item-box text-end" style="padding-right: 20px">
          <span
            class="material-symbols-rounded icon-style"
            v-if="user.uid == note.uid"
            @click="isTooltip = !isTooltip"
          >
            more_vert
          </span>
        </div>
      </div>
      <div style="height: 20px; padding-left: 65px; padding-top: 0px" v-else>
        <div class="item-box">
          <span class="material-symbols-rounded un-icon-style"> chat </span>
        </div>
        <div class="item-box">
          <span
            class="material-symbols-rounded un-icon-style"
            :style="noFillStyle"
            >favorite</span
          >
        </div>
        <div class="item-box">
          <span class="material-symbols-rounded un-icon-style">
            add_reaction
          </span>
        </div>
        <div class="item-box">
          <!-- <span
            class="material-symbols-rounded un-icon-style"
          >
            send
          </span> -->
        </div>
        <div class="item-box">
          <!-- 
          <span class="material-symbols-rounded un-icon-style">
            forum
          </span> -->
        </div>
        <div class="item-box text-end" style="padding-right: 20px">
          <span
            class="material-symbols-rounded un-icon-style"
            v-if="user.uid == note.uid"
          >
            more_vert
          </span>
        </div>
      </div>
    </div>
    <div
      v-show="pageMode == 0 || pageMode == 1"
      style="margin: 25px 0; border: 1px; border-top: solid 1px #f3f3f3"
    ></div>
    <div v-if="pageMode == 4" style="height: 30px"></div>
  </div>
  <v-bottom-sheet v-model="myBottomSheet">
    <v-sheet
      class="text-center"
      height="450px"
      style="border-radius: 15px 15px 0 0"
    >
      <div style="padding: 0px" class="text-center">
        <span
          class="material-symbols-rounded"
          style="color: #ccc; font-size: 40px"
          @click="myBottomSheet = !myBottomSheet"
        >
          remove
        </span>
      </div>
      <div style="height: 400px">
        <EmojiPicker
          :options="{
            imgSrc: '/img/',
            locals: 'en',
            hasSearch: false,
          }"
          @select="selectEmoji"
        />
      </div>
    </v-sheet>
  </v-bottom-sheet>
  <v-bottom-sheet v-model="myBottomProfileSheet">
    <v-sheet
      class="text-center"
      :height="browserHeight + 'px'"
      style="border-radius: 15px 15px 0 0"
    >
      <div
        style="
          padding: 0px;
          position: absolute;
          top: 0;
          width: 100%;
          height: 83px;
          background-color: #fff;
          border-radius: 15px 15px 0 0;
          z-index: 100;
        "
        class="text-center"
      >
        <span
          class="material-symbols-rounded"
          style="color: #ccc; font-size: 40px"
          @click="myBottomProfileSheet = !myBottomProfileSheet"
        >
          remove
        </span>
        <div class="container-fluid" style="position: absolute; top: 35px">
          <div class="row" style="margin-top: 15px">
            <div class="col-3 mx-0 px-0">
              <div style="padding: 0 2px 0 5px">
                <button
                  type="button"
                  style="width: 100%; font-size: 10px"
                  class="btn btn-kinkyer m-plus-medium"
                  @click="profileSheetIndex = 1"
                >
                  プロフィール
                </button>
              </div>
            </div>
            <div class="col-3 mx-0 px-0">
              <div style="padding: 0 2px 0 2px">
                <button
                  type="button"
                  style="width: 100%; font-size: 10px"
                  class="btn btn-kinkyer m-plus-medium"
                  @click="profileSheetIndex = 2"
                >
                  フェチ
                </button>
              </div>
            </div>
            <div class="col-3 mx-0 px-0">
              <div style="padding: 0 2px 0 2px">
                <button
                  type="button"
                  style="width: 100%; font-size: 10px"
                  class="btn btn-kinkyer m-plus-medium"
                  @click="profileSheetIndex = 3"
                >
                  BDSM
                </button>
              </div>
            </div>
            <div class="col-3 mx-0 px-0">
              <div style="padding: 0 5px 0 2px">
                <button
                  type="button"
                  style="width: 100%; font-size: 10px"
                  class="btn btn-kinkyer m-plus-medium"
                  @click="profileSheetIndex = 4"
                >
                  性癖
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-start" style="padding: 60px 0 130px 0">
        <ProfileView
          :data="note.shareUser"
          :is_mod="false"
          v-if="note.isProfileShare && profileSheetIndex == 1"
        />
        <KaiwaiView
          :data="note.shareUser"
          :is_mod="false"
          v-if="note.isProfileShare && profileSheetIndex == 1"
        />
        <FetishismView
          :data="note.shareUser"
          :is_mod="false"
          v-if="note.isProfileShare && profileSheetIndex == 2"
        />
        <BDSMView
          :data="note.shareUser"
          :is_mod="false"
          v-if="note.isProfileShare && profileSheetIndex == 3"
        />
        <SeihekiView
          :data="note.shareUser"
          :is_mod="false"
          v-if="note.isProfileShare && profileSheetIndex == 4"
        />
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import listIcon from "@/components/timeline/listIconView.vue";
import listImageView from "@/components/timeline/listImageView.vue";
import { EmojiPicker } from "vue3-twemoji-picker-final";
import { db, storage, functions } from "../../plugins/firebase";
import { setInformation } from "../../plugins/push";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { setNote, store } from "../../plugins/store";
//import axios from "axios";
import { httpsCallable } from "firebase/functions";
import ProfileView from "@/components/user/ProfileView.vue";
import FetishismView from "@/components/user/FetishismView.vue";
import BDSMView from "@/components/user/BDSMView.vue";
import SeihekiView from "@/components/user/SeihekiView.vue";
import KaiwaiView from "@/components/user/KaiwaiView.vue";

export default {
  emits: ["handleSetQuery", "handleModalView"],
  data() {
    return {
      user: {},
      note: {},
      nowNote: {},
      myBottomSheet: false,
      replayText: "",
      pageMode: 0,
      isclick: false,
      isTooltip: false,
      innerImageHeight: 200,
      showPicker: false,
      selectedEmojis: {},
      ogpUrl: "",
      ogpData: null,
      rangeName: "",
      noFillStyle: {
        fontVariationSettings: "'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24",
      },
      filledStyle: {
        fontVariationSettings: "'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24",
      },
      isRemove: false,
      isProfileShare: false,
      myBottomProfileSheet: false,
      profileSheetIndex: 1,
      browserHeight: 0,
      videoURL: "",
    };
  },
  /**
   * mode
   * 0:タイムライン
   * 1:投稿詳細
   * 2:返信
   * 3:リプライリスト用
   * 4:履歴
   */
  props: ["data", "userData", "mode", "click"],
  async mounted() {
    this.user = this.userData;
    this.note = this.data;
    this.pageMode = this.mode;
    this.isclick = this.click;

    this.selectedEmojis = this.note.selectedEmojis
      ? this.note.selectedEmojis
      : {};

    if (this.pageMode == 2 || this.pageMode == 4) {
      this.innerImageHeight = 80;
    }

    if (this.note.range == "all") {
      this.rangeName = "全員に公開";
    } else if (this.note.range == "follower") {
      this.rangeName = "フォロワー公開";
    } else if (this.note.range == "group") {
      this.rangeName = "グループ公開";
    } else if (this.note.range == "like") {
      this.rangeName = "いいね公開";
    } else if (this.note.range == "matching") {
      this.rangeName = "マッチング公開";
    }

    // 各フラグ取得
    const docRef = doc(db, "notes", this.note.noteId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      this.isRemove = data.isRemove;
      this.isProfileShare = data.isProfileShare;
    }

    this.getBrowserHeight();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.getBrowserHeight);
  },
  created() {
    this.user = this.userData;
    this.note = this.data;
    this.pageMode = this.mode;
    this.isclick = this.click;

    if (this.pageMode == 2 || this.pageMode == 4) {
      this.innerImageHeight = 80;
    }
    const urlPattern =
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gi;

    let urls = [];
    this.note.detail.replace(urlPattern, function (url) {
      urls.push(url);
    });

    if (urls.length != 0) {
      this.ogpUrl = urls[urls.length - 1];
      this.fetchOgpData(urls[urls.length - 1]);
    }

    if (this.note.videoUrl) {
      this.fetchVideo();
    }
  },
  components: {
    listIcon,
    listImageView,
    EmojiPicker,
    ProfileView,
    FetishismView,
    BDSMView,
    SeihekiView,
    KaiwaiView,
  },
  methods: {
    setReply(note) {
      setNote(note);
      this.$router.push({
        name: "home-timeline-reply",
      });
    },
    async setLike() {
      if (this.note.likeUID.includes(this.user.uid)) {
        const index = this.note.likeUID.indexOf(this.user.uid);
        if (index !== -1) {
          this.note.likeUID.splice(index, 1); // 配列から削除
        }
      } else {
        this.note.likeUID.push(this.user.uid);
        //いいね通知
        this.sendInformation("があなたの投稿にいいねがされました", "note_like");
      }

      const subDocRef = doc(db, "notes", this.note.noteId);
      await updateDoc(subDocRef, {
        likeUID: this.note.likeUID,
      });
      setNote(this.note);
    },
    setSecretReply(note) {
      setNote(note);
      this.$router.push({
        name: "home-timeline-reply",
      });
    },
    setGroupChat() {
      console.log("setGroupChat");
    },
    async removeNote() {
      console.log("removeNote");
      const subDocRef = doc(db, "notes", this.note.noteId);
      await updateDoc(subDocRef, {
        isRemove: true,
      });
      this.isTooltip = false;
      this.$router.go(0);
    },
    setQuery(t) {
      this.$emit("handle-set-query", t);
    },
    handleListModalView(i, images) {
      this.$emit("handle-modal-view", i, images);
    },
    setDateView(date) {
      try {
        let status = "今";

        let dt = new Date();
        let lg = new Date(date.toDate());

        var diff = dt.getTime() - lg.getTime();
        //分
        var minutes = Math.floor(diff / (60 * 1000));
        //時
        var hour = Math.floor(diff / (60 * 60 * 1000));
        //日
        var day = Math.floor(diff / (1000 * 60 * 60 * 24));

        if (day == 0 && hour == 0 && minutes > 0) {
          status = minutes + "分前";
        } else if (day == 0 && hour > 0) {
          status = hour + "時間前";
        } else if (day > 0 && day < 31) {
          status = day + "日前";
        } else if (day > 30) {
          const yyyy = lg.getFullYear();
          const mm = ("00" + (lg.getMonth() + 1)).slice(-2);
          const dd = ("00" + lg.getDate()).slice(-2);
          status = `${yyyy}年${mm}月${dd}日`;
        }

        return status;
      } catch {
        return this.note.createdAtView;
      }
    },
    linkTimelineDatail(note) {
      if (this.isclick) {
        setNote(note);
        this.$router.push({
          name: "home-timeline-detail",
          params: { noteInfo: note },
        });
      }
    },
    linkTimelineHistory(note) {
      this.$router.push({
        name: "home-timeline-history",
        params: { noteid: note.noteId },
      });
    },
    autoLink(text) {
      if (text == undefined) {
        return "";
      }

      const urlPattern =
        /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gi;

      return text.replace(urlPattern, function (url) {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    },
    async fetchOgpData(url) {
      const exampleFunction = httpsCallable(functions, "exampleFunction");
      try {
        const response = await exampleFunction({ url: url });

        if (
          response.data.reply["og:url"] != undefined &&
          response.data.reply["og:image"] != undefined &&
          response.data.reply["og:title"] != undefined
        ) {
          this.ogpData = {
            url: response.data.reply["og:url"],
            image: response.data.reply["og:image"],
            title: response.data.reply["og:title"],
          };
        }
      } catch (error) {
        this.error = error.message;
        console.error("Error calling function:", error);
      }
    },
    formatLargeNumber(num) {
      if (num < 10000) {
        return num.toString(); // 10,000未満はそのまま表示
      }

      const units = [
        { value: 1e12, symbol: "兆" },
        { value: 1e8, symbol: "億" },
        { value: 1e4, symbol: "万" },
      ];

      for (let i = 0; i < units.length; i++) {
        if (num >= units[i].value) {
          const formatted = (num / units[i].value)
            .toFixed(1)
            .replace(/\.0$/, ""); // 小数点1桁で、.0は除外
          return `${formatted}${units[i].symbol}`;
        }
      }
    },
    truncateText(text) {
      const maxLength = 10;
      return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    },
    async selectEmoji(emoji) {
      if (Object.prototype.hasOwnProperty.call(this.selectedEmojis, emoji.i)) {
        if (this.selectedEmojis[emoji.i].users.includes(this.user.uid)) {
          const index = this.selectedEmojis[emoji.i].users.indexOf(
            this.user.uid,
          );
          if (index !== -1) {
            this.selectedEmojis[emoji.i].users.splice(index, 1); // 配列から削除
          }

          // 0件になったら削除
          if (this.selectedEmojis[emoji.i].users.length == 0) {
            delete this.selectedEmojis[emoji.i];
          }
        } else {
          this.selectedEmojis[emoji.i].users.push(this.user.uid);
          //絵文字増えるから通知
          this.sendInformation(
            "があなたの投稿にリアクションがありました",
            "note_reaction",
          );
        }
      } else {
        this.selectedEmojis[emoji.i] = {
          i: emoji.i,
          emoji: emoji.i,
          url: emoji.imgSrc,
          users: [this.user.uid],
        };
        //絵文字増えるから通知
        this.sendInformation(
          "あなたの投稿にリアクションがありました",
          "note_reaction",
        );
      }
      const subDocRef = doc(db, "notes", this.note.noteId);
      await updateDoc(subDocRef, {
        selectedEmojis: this.selectedEmojis,
      });
      this.note.selectedEmojis = this.selectedEmojis;
      setNote(this.note);
      this.myBottomSheet = false;
    },
    async sendInformation(message, type) {
      const docShareUserRef = doc(db, "users", this.note.uid);
      const docShareUserSnap = await getDoc(docShareUserRef);
      const user = docShareUserSnap.data();
      const infoUser = {
        uid: this.user.uid,
        icon_url: store.userIconUrl,
        name: this.user.name,
      };

      setInformation(
        this.note.uid,
        message,
        type,
        "/timeline/detail",
        this.note,
        user.token,
        false,
        infoUser,
      );
    },
    getBrowserHeight() {
      this.browserHeight = window.innerHeight - 50;
    },
    async fetchVideo() {
      try {
        const filePath = this.note.videoUrl;
        const fileRef = storageRef(storage, filePath);

        this.videoURL = await getDownloadURL(fileRef);
      } catch (err) {
        console.error = `動画の取得に失敗しました: ${err.message}`;
      }
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
ul {
  display: flex;
  padding-left: 0 !important;
}
li {
  list-style: none;
  margin-right: 10px;
  color: #784ba0;
}
p {
  font-size: 12px;
  background-color: rgb(244, 245, 247);
  padding: 10px 15px;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
}
.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.un-icon-style {
  color: #efefef;
}
.icon-style {
  color: #bbb;
}
.icon-like-style {
  color: #ff3d84;
}
.item-box {
  width: 16.6666%;
  float: left;
  height: 20px;
}
.emoji-picker {
  width: 100% !important;
  padding: 0 !important;
}
.speech-bubble {
  position: relative;
  background: #784ba0;
  border-radius: 10px;
  padding: 15px;
  color: #fff;
  width: 100%;
  font-size: 14px;
}
.speech-bubble::after {
  content: "";
  position: absolute;
  top: -13px;
  left: 20px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #784ba0 transparent;
}
</style>
