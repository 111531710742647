<template>
  <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
  <div class="user-edit-profile" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div style="padding: 0 8px">
              <div style="padding-bottom: 15px" v-show="isEntry">
                <div
                  class="progress"
                  role="progressbar"
                  aria-label="Basic example"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    class="progress-bar"
                    :style="
                      'background-color: #784ba0 !important; width: ' +
                      count +
                      '%'
                    "
                  ></div>
                </div>
              </div>
              <div
                style="
                  padding-top: 10px;
                  padding-bottom: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  color: #827490;
                "
              >
                募集タグ
              </div>
              <div
                style="
                  padding: 10px 0px 20px 0;
                  font-size: 12px;
                  font-weight: 400;
                "
              >
                どのような相手を探しているか選択しましょう
              </div>
              <div class="item-box">
                <div style="padding-top: 5px">
                  <button
                    v-for="(item, i) in this.tags"
                    :key="i"
                    type="button"
                    style="
                      margin-right: 5px;
                      margin-bottom: 5px;
                      width: auto !important;
                      border-radius: 30px !important;
                    "
                    :class="
                      isSelectedTags(item)
                        ? 'btn btn-kinkyer m-plus-medium'
                        : 'btn btn-kinkyer-outline-by-color m-plus-medium'
                    "
                    @click="clickSelectedTags(item)"
                  >
                    {{ item }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 75px;
      padding: 5px 8px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="((isEdit = true), update())"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/HeaderView.vue";
import "vue-select/dist/vue-select.css";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc, setDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      isEdit: false,
      isEntry: false,
      user: {},
      btnName: "",
      count: 0,
      selectedTags: [],
      tags: [
        "恋人募集",
        "日常的なパートナー募集",
        "一時的なプレイパートナー募集",
        "友達募集",
        "趣味友や仲間募集",
        "真剣交際",
        "既婚・恋人がいます",
        "他にパートナーいます",
        "ペット・従者がいます",
        "お仕えする主がいます",
        "フリーです",
        "まずはDMでじっくり話したい",
        "カフェなどでお話しする",
        "すぐ会いたい",
        "デートしたい",
        "写真は交換したい",
        "電話や通話をしたい",
        "界隈でお仕事してます",
      ],
    };
  },
  async created() {
    this.count = 3.846 * 15;
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";
    this.user = store.user;
  },
  mounted() {
    this.selectedTags =
      this.user.selectTag == undefined ? [] : this.user.selectTag;
  },
  components: {
    AppHeader,
  },
  methods: {
    async update() {
      try {
        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        await updateDoc(docUpdateUsersRef, {
          selectTag: this.selectedTags,
        });
        await setDoc(doc(db, "shareUsers", this.user.uid), {
          selectTag: this.selectedTags,
        });

        this.user.selectTag = this.selectedTags;

        setUser(this.user, this.user.uid);

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push(
            "/user/edit/sadistic_and_masochist/" + this.$route.params.type,
          );
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
    isSelectedTags(value) {
      return this.selectedTags.includes(value);
    },
    clickSelectedTags(value) {
      if (this.isSelectedTags(value)) {
        const index = this.selectedTags.indexOf(value);
        if (index !== -1) {
          this.selectedTags.splice(index, 1); // 配列から削除
        }
      } else {
        this.selectedTags.push(value);
      }
    },
  },
};
</script>

<style scoped>
.user-edit-profile {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
</style>
