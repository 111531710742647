<template>
  <v-pull-to-refresh @load="reloadPage">
    <div class="header-back">
      <div class="header-item header-item-size" style="width: 30%"></div>
      <div class="text-center header-item" style="padding-top: 5px; width: 40%">
        <span class="title cherry-bomb-one-regular">Kinkyer</span>
      </div>
      <div class="header-item header-item-size" style="width: 30%"></div>
    </div>
    <div v-if="isWithdrawal">
      <div class="page text-center">
        <div style="padding-top: 50px">
          <img
            :src="require('@/assets/images/nouser.png')"
            style="width: 210px"
          />
        </div>
        <div style="font-size: 14px; color: #aaa; padding: 10px">
          こちらのユーザーは退会しております
        </div>
      </div>
    </div>
    <div class="user-detail" ref="scrollContainer" v-else>
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <div v-if="isCreated">
                <!-- メインアイコン -->
                <div
                  style="
                    animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                      forwards;
                  "
                >
                  <div v-if="imageUrl">
                    <img :src="imageUrl" width="100%" />
                  </div>
                  <div v-else>
                    <img
                      :src="require('@/assets/icon_user.webp')"
                      width="100%"
                    />
                  </div>
                </div>
                <!-- サブアイコン -->
                <div>
                  <listProfileImageView :uids="imageList" />
                </div>
                <!-- プロフィール -->
                <div
                  style="
                    animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                      forwards;
                  "
                >
                  <ProfileView
                    :data="userDetail"
                    :is_mod="false"
                    :is_share="true"
                  />
                </div>
                <!-- フェチ -->
                <div
                  style="
                    animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                      forwards;
                  "
                >
                  <FetishismView :data="userDetail" :is_mod="false" />
                </div>
                <!-- BDSM -->
                <div
                  style="
                    animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                      forwards;
                  "
                >
                  <BDSMView :data="userDetail" :is_mod="false" />
                </div>
                <!-- 性癖 -->
                <div
                  style="
                    animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                      forwards;
                  "
                >
                  <SeihekiView :data="userDetail" :is_mod="false" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 65px;
        padding: 5px 20px;
        background-color: #fff;
      "
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <button
              type="button"
              style="width: 100%; height: 40px"
              class="btn btn-kinkyer m-plus-medium"
              @click="login()"
            >
              ログインして表示する
            </button>
          </div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import listProfileImageView from "@/components/user/listProfileImageView.vue";
import ProfileView from "@/components/user/ProfileView.vue";
import FetishismView from "@/components/user/FetishismView.vue";
import BDSMView from "@/components/user/BDSMView.vue";
import SeihekiView from "@/components/user/SeihekiView.vue";
import { storage, db } from "../../plugins/firebase";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { doc, getDoc } from "firebase/firestore";
import { setShareUserId } from "../../plugins/store";

export default {
  data() {
    return {
      userDetail: {},
      imageList: [],
      imageUrl: null,
      isCreated: false,
      isWithdrawal: false,
    };
  },
  async mounted() {
    try {
      const uid = this.$route.params.uid;
      const docUserRef = doc(db, "shareUsers", uid);
      const docUserSnap = await getDoc(docUserRef);

      this.userDetail = docUserSnap.data();

      this.isWithdrawal =
        this.userDetail.isWithdrawal == undefined
          ? false
          : this.userDetail.isWithdrawal;

      this.getUserIcon(uid);
      const exists = this.userDetail?.imageList !== undefined;
      if (exists) {
        this.imageList = this.userDetail.imageList;
      }
    } finally {
      this.isCreated = true;
    }
  },
  components: {
    listProfileImageView,
    ProfileView,
    FetishismView,
    BDSMView,
    SeihekiView,
    VPullToRefresh,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getUserIcon(uid) {
      const imageRef = storageRef(storage, `users/icon/${uid}/user_icon1.png`);

      try {
        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;
      } catch (error) {
        console.log("---------- share/profile ---------");
        console.error("Error fetching image URL:", error);
      }
    },
    login() {
      setShareUserId(this.$route.params.uid);
      this.$router.push("/auth");
    },
  },
};
</script>

<style scoped>
.user-detail {
  min-height: 100%;
  background-color: #fff;
}

.page {
  padding-top: 60px;
  padding-bottom: 80px;
}

.footer-left {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 99;
}
.btn-footer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #784ba0;
  color: #fff;
  padding-top: 10px;
  padding-left: 10px;
  margin-left: 15px;
  border: solid 1px rgb(215 201 227 / 70%);
}
.list-group-item {
  padding: 0 !important; /* paddingを0に */
  margin: 0 !important; /* marginを0に */
  border: none !important; /* borderを非表示に */
}

.header-item {
  float: left;
}
.header-item-size {
  padding-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
}
.title {
  color: #fff;
  font-size: 30px;
}

.header-back {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background: -moz-linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background: -webkit-linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background: linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background-color: #784ba0;
  z-index: 999;
}
</style>
