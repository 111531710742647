<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader
      :is_icon="false"
      :is_back="true"
      :title="''"
      :type="pageType"
      @header-action-click="headerActionClick"
    />
    <div v-if="isWithdrawal">
      <div class="page text-center">
        <div style="padding-top: 50px">
          <img
            :src="require('@/assets/images/nouser.png')"
            style="width: 210px"
          />
        </div>
        <div style="font-size: 14px; color: #aaa; padding: 10px">
          こちらのユーザーは退会しております
        </div>
      </div>
    </div>
    <div v-else-if="isBlocked">
      <div class="page text-center">
        <div style="padding-top: 50px">
          <img
            :src="require('@/assets/images/blockuser.png')"
            style="width: 210px"
          />
        </div>
        <div style="font-size: 14px; color: #aaa; padding: 10px">
          こちらのユーザーにはアクセスできません
        </div>
      </div>
    </div>
    <div class="user-detail" ref="scrollContainer" v-else>
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <!-- メインアイコン -->
              <div
                style="
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              >
                <div v-if="imageUrl">
                  <img :src="imageUrl" width="100%" />
                </div>
                <div v-else>
                  <img :src="require('@/assets/icon_user.webp')" width="100%" />
                </div>
              </div>
              <!-- サブアイコン -->
              <div>
                <listProfileImageView :uids="imageList" />
              </div>
              <!-- プロフィール -->
              <div
                style="
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              >
                <ProfileView
                  :data="userDetail"
                  :is_mod="false"
                  :is_share="false"
                />
              </div>
              <!-- 界隈 -->
              <div
                style="
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              >
                <KaiwaiView :data="userDetail" :is_mod="false" />
              </div>
              <!-- フェチ -->
              <div
                style="
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              >
                <FetishismView :data="userDetail" :is_mod="false" />
              </div>
              <!-- BDSM -->
              <div
                style="
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              >
                <BDSMView :data="userDetail" :is_mod="false" />
              </div>
              <!-- 性癖 -->
              <div
                style="
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              >
                <SeihekiView :data="userDetail" :is_mod="false" />
              </div>
              <!-- お気に入り -->
              <div
                style="
                  padding: 50px;
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
                v-if="user.isEntry"
              >
                <button
                  v-if="isBookmark"
                  type="button"
                  class="btn btn-kinkyer m-plus-medium"
                  @click="setBookmark(false)"
                >
                  お気に入りに追加
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-kinkyer-outline-by-color m-plus-medium"
                  @click="setBookmark(true)"
                >
                  お気に入り解除
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-left">
        <div style="display: flex; flex-wrap: wrap; width: 100%">
          <div
            class="btn-footer-d"
            v-show="matchingStatus == 1 && user.maxGoodCount == 0"
          >
            いいね上限に達しました
          </div>
          <div
            class="btn-footer"
            v-show="matchingStatus == 1 && user.maxGoodCount > 0"
            @click="((myBottomSheet = true), (sheetType = 2))"
          >
            <img
              :src="require('@/assets/comment_thumb_up.png')"
              style="margin-top: 5px; margin-left: 6px"
              width="28px"
            />
          </div>
          <div
            class="btn-footer"
            v-show="matchingStatus == 1 && user.maxGoodCount > 0"
          >
            <span
              class="material-symbols-rounded"
              style="font-size: 28px; margin-top: 4px; margin-left: 5px"
              @click="setThumbUp"
            >
              thumb_up
            </span>
          </div>
          <div
            class="btn-footer-d"
            v-show="matchingStatus == 3 && !isMatchingBtn"
          >
            マッチング上限に達しました
          </div>
          <div class="btn-footer" v-show="matchingStatus == 3 && isMatchingBtn">
            <span
              class="material-symbols-rounded"
              style="font-size: 28px; margin-top: 6px; margin-left: 5px"
              @click="setMatching"
            >
              favorite
            </span>
          </div>
          <div
            class="btn-footer"
            v-show="matchingStatus == 2 || matchingStatus == 3"
          >
            <span
              class="material-symbols-rounded"
              style="font-size: 28px; margin-top: 6px; margin-left: 5px"
              @click="linkAppealDM"
            >
              comment
            </span>
          </div>
          <div class="btn-footer" v-show="matchingStatus == 4">
            <span
              class="material-symbols-rounded"
              style="font-size: 28px; margin-top: 5px; margin-left: 6px"
              @click="linkDM"
            >
              chat_bubble
            </span>
          </div>
        </div>
      </div>
    </div>
    <v-bottom-sheet v-model="myBottomSheet">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8" style="padding: 0">
            <v-sheet height="330px" style="border-radius: 15px 15px 0 0">
              <div style="padding: 0px" class="text-center">
                <span
                  class="material-symbols-rounded"
                  style="color: #ccc; font-size: 40px"
                  @click="myBottomSheet = !myBottomSheet"
                >
                  remove
                </span>
              </div>
              <div v-if="sheetType == 1" style="padding: 0 20px">
                <div style="margin-bottom: 15px" @click="setBlock">
                  <i
                    class="bi bi-person-slash"
                    style="font-size: 24px; margin-right: 10px"
                  ></i
                  >ブロックする
                </div>
                <div style="margin-bottom: 15px" @click="setReport">
                  <i
                    class="bi bi-exclamation-triangle-fill"
                    style="font-size: 24px; margin-right: 10px"
                  ></i
                  >通報する
                </div>
                <!-- <div style="margin-bottom: 15px" @click="unMatching">
                  <i
                    class="bi bi-person-dash-fill"
                    style="font-size: 24px; margin-right: 10px"
                  ></i
                  >マッチング解除
                </div> -->
                <!-- <div style="margin-bottom: 15px" @click="linkGroupChat">
                  <i
                    class="bi bi-chat-square-text"
                    style="font-size: 24px; margin-right: 10px"
                  ></i
                  >グループチャット一覧
                </div> -->
              </div>
              <div v-else-if="sheetType == 2" style="padding: 0 15px">
                <div style="font-weight: 600; color: #784ba0">
                  アピールいいね
                </div>
                <div style="font-size: 11px">
                  メッセージを添えていいねをしましょう。<br />あなたのことをもっと知ってもらえるきっかけにしてみませんか？
                </div>
                <div style="padding: 10px 0">
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="message"
                    placeholder="アピールをどうぞ"
                  ></textarea>
                </div>
                <div>
                  <button
                    type="button"
                    @click="setAppealThumbUp"
                    class="btn btn-kinkyer m-plus-medium"
                  >
                    アピールいいねを送る
                  </button>
                </div>
              </div>
              <div v-else></div>
            </v-sheet>
          </div>
        </div>
      </div>
    </v-bottom-sheet>
    <div
      v-show="isLiked"
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      "
    >
      <div style="">
        <div
          style="
            width: 250px;
            height: 280px;
            background-color: #784ba0;
            border-radius: 30px;
            border: solid 1px #fff;
            text-align: center;
            display: flex;
            padding: 20px;
          "
        >
          <div class="text-center" style="width: 100%">
            <span
              class="material-symbols-rounded"
              style="color: #fff; font-size: 100px"
            >
              thumb_up
            </span>
            <div style="color: #fff; padding-bottom: 10px">
              いいね！を送りました
            </div>
            <div style="color: #fff; font-size: 12px; padding-bottom: 20px">
              お相手からのメッセージや<br />マッチングをお待ちください
            </div>
            <button
              type="button"
              @click="isLiked = false"
              class="btn btn-kinkyer-d m-plus-medium"
            >
              閉じる
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="isMatching"
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #784ba0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      "
    >
      <div style="">
        <div>
          <div class="text-center" style="width: 100%">
            <div style="display: flex; width: 250px">
              <div>
                <div v-if="imageLoginUrl">
                  <img :src="imageLoginUrl" class="img-user-icon" />
                </div>
                <div v-else>
                  <img
                    :src="require('@/assets/icon_user.webp')"
                    class="img-user-icon"
                  />
                </div>
              </div>
              <div style="padding: 10px 20px 0 20px">
                <span
                  class="material-symbols-rounded"
                  style="color: #fff; font-size: 50px"
                >
                  volunteer_activism
                </span>
              </div>
              <div>
                <div v-if="imageUrl">
                  <img :src="imageUrl" class="img-user-icon" />
                </div>
                <div v-else>
                  <img
                    :src="require('@/assets/icon_user.webp')"
                    class="img-user-icon"
                  />
                </div>
              </div>
            </div>
            <br />
            <div style="color: #fff; padding-bottom: 10px; font-size: 20px">
              マッチング成立しました
            </div>
            <div style="color: #fff; font-size: 14px; padding-bottom: 30px">
              おめでとうございます♥︎
            </div>
            <div style="padding-bottom: 10px">
              <button
                style="width: 200px"
                type="button"
                @click="setAppealThumbUp"
                class="btn btn-kinkyer-d m-plus-medium"
              >
                メッセージへ
              </button>
            </div>
            <div>
              <button
                style="width: 200px"
                type="button"
                @click="isMatching = false"
                class="btn btn-kinkyer-d m-plus-medium"
              >
                閉じる
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import { nextTick } from "vue";
import AppHeader from "@/components/HeaderView.vue";
import listProfileImageView from "@/components/user/listProfileImageView.vue";
import ProfileView from "@/components/user/ProfileView.vue";
import FetishismView from "@/components/user/FetishismView.vue";
import BDSMView from "@/components/user/BDSMView.vue";
import SeihekiView from "@/components/user/SeihekiView.vue";
import KaiwaiView from "@/components/user/KaiwaiView.vue";
import { db, storage } from "../../plugins/firebase";
import {
  doc,
  collection,
  getDoc,
  addDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { store, setUser } from "../../plugins/store";
import { setInformation } from "../../plugins/push";
import { setReport } from "../../plugins/report";

export default {
  data() {
    return {
      user: "",
      userDetail: {},
      imageList: [],
      imageUrl: null,
      imageLoginUrl: null,
      myBottomSheet: false,
      /**
       * 0:自分
       * 1:何もしてない（これからいいねを送る
       * 2:いいねを送った
       * 3:いいねをもらった
       * 4:マッチング済み
       * 9:ブロック（したorされた
       */
      matchingStatus: 1,
      sheetType: 1,
      message: "",
      isBookmark: true,
      isLikeBotton: true,
      rid: null,
      isLiked: false,
      isMatching: false,
      isMatchingBtn: true,

      isWithdrawal: false,
      pageType: "",
      isBlocked: false,
    };
  },
  async created() {
    this.user = store.user;
    this.imageLoginUrl = store.userIconUrl;
    this.userDetail = store.userDetail;

    this.isWithdrawal =
      this.userDetail.isWithdrawal == undefined
        ? false
        : this.userDetail.isWithdrawal;
    this.pageType = this.isWithdrawal ? "" : "userDetail";
    this.isBlocked =
      this.userDetail.blockedUID == undefined
        ? false
        : this.userDetail.blockedUID.includes(this.user.uid);

    this.isMatchingBtn =
      this.user.isSubscription || this.user.sex == "女性"
        ? true
        : this.user.isReplyGood;

    if (this.user.uid == this.userDetail.uid) {
      // 自分かチェック
      this.matchingStatus = 0;
    }
    if (this.user.likedUID.includes(this.userDetail.uid)) {
      // いいねしたユーザチェック
      this.matchingStatus = 2;
      const docLikedRef = doc(
        db,
        `users/${this.user.uid}/likedUsers`,
        this.userDetail.uid,
      );
      const docLikedSnap = await getDoc(docLikedRef);
      this.rid = docLikedSnap.data().roomId;
    }
    if (this.user.likeUID.includes(this.userDetail.uid)) {
      // いいねもらったユーザチェック
      this.matchingStatus = 3;
      const docLikedRef = doc(
        db,
        `users/${this.user.uid}/likeUsers`,
        this.userDetail.uid,
      );
      const docLikedSnap = await getDoc(docLikedRef);
      this.rid = docLikedSnap.data().roomId;
    }
    if (this.user.matchingUID.includes(this.userDetail.uid)) {
      // マッチングユーザチェック
      this.matchingStatus = 4;
      const docLikedRef = doc(
        db,
        `users/${this.user.uid}/matchingUsers`,
        this.userDetail.uid,
      );
      const docLikedSnap = await getDoc(docLikedRef);
      this.rid = docLikedSnap.data().matching_room;
    }

    if (this.user.blockedUID.includes(this.userDetail.uid)) {
      // ブロックユーザチェック
      this.matchingStatus = 9;
    }
    const exists = this.userDetail?.imageList !== undefined;
    if (exists) {
      this.imageList = this.userDetail.imageList;
    }
    this.userDetail.isLikeBotton = this.userDetail.isLikeBotton
      ? this.userDetail.isLikeBotton
      : true;

    // お気に入りボタン
    if (this.user.bookmarks) {
      this.isBookmark = !this.user.bookmarks.includes(this.userDetail.uid);
    }

    // 本人確認済みかチェック
    if (!this.user.isEntry) {
      this.matchingStatus = 0;
    }
  },
  mounted() {
    this.getUserIcon();
  },
  watch: {
    // データが変更されたときにスクロールをリセット
    content: {
      async handler() {
        await nextTick();
        if (this.$refs.scrollContainer) {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
  },
  components: {
    AppHeader,
    listProfileImageView,
    ProfileView,
    FetishismView,
    BDSMView,
    SeihekiView,
    KaiwaiView,
    VPullToRefresh,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getUserIcon() {
      const imageRef = storageRef(
        storage,
        `users/icon/${store.userDetail.uid}/user_icon1.png`,
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;
      } catch (error) {
        console.log("---------- user/detail ---------");
        console.error("Error fetching image URL:", error);
      }
    },
    headerActionClick(data) {
      if (data == "userDetail-sheet") {
        this.myBottomSheet = true;
        this.sheetType = 1;
      } else if (data == "userDetail-timeline") {
        this.$router.push("/user/timeline");
      }
    },
    /**
     * いいね送信
     */
    async setThumbUp() {
      var inforValue = {
        loginUser: this.user,
        showUser: this.userDetail,
        roomId: null,
      };
      const count = this.user.maxGoodCount - 1;
      var isNoError = true;
      try {
        // チャットを作成
        /**
         * Like:相手側の情報
         * Liked:ログイン側の情報
         */
        var room_id = "";

        const docRef = await addDoc(collection(db, "chat_room"), {
          isSendLiked: false,
          sendCountLiked: this.user.isSubscription ? 10 : 3,
          likedUid: this.user.uid,
          isSendLike: true,
          sendCountLike: this.userDetail.isSubscription ? 10 : 3,
          likeUid: this.userDetail.uid,
          createdAt: new Date(),
        });
        room_id = docRef.id;
        inforValue.roomId = room_id;
        this.rid = room_id;

        // 相手のいいね一覧に自分の情報を追加
        const docLikeForUserDetail = doc(db, "users", this.userDetail.uid);
        await updateDoc(docLikeForUserDetail, {
          isGoodBadges: false,
          likeUID: arrayUnion(this.user.uid),
        });

        await setDoc(
          doc(db, `users/${this.userDetail.uid}/likeUsers`, this.user.uid),
          {
            uid: this.user.uid,
            name: this.user.name,
            birthday: this.user.birthday,
            sex: this.user.sex,
            roomId: room_id,
            likeAt: new Date(),
            lastMessage: "いいねしました",
            updatedAt: new Date(),
            isIconUrl: this.user.isIconUrl,
            isMyMessage: false,
          },
        );

        // 自分のいいねをした一覧に相手の情報を追加
        const docLikeForUser = doc(db, "users", this.user.uid);
        await updateDoc(docLikeForUser, {
          likedUID: arrayUnion(this.userDetail.uid),
          lastGoodAt: new Date(),
          maxGoodCount: count,
        });

        await setDoc(
          doc(db, `users/${this.user.uid}/likedUsers`, this.userDetail.uid),
          {
            uid: this.userDetail.uid,
            name: this.userDetail.name,
            birthday: this.userDetail.birthday,
            sex: this.userDetail.sex,
            roomId: room_id,
            likeAt: new Date(),
            lastMessage: "いいねしました",
            updatedAt: new Date(),
            isIconUrl: this.userDetail.isIconUrl,
            isMyMessage: true,
          },
        );
      } catch (e) {
        isNoError = false;
        console.error(e);
      } finally {
        // 通知(エラーがなかったら送る)
        if (isNoError) {
          this.user.likedUID.push(this.userDetail.uid);
          this.user.maxGoodCount = count;
          setUser(this.user, this.user.uid);
          const infoUser = {
            uid: this.user.uid,
            icon_url: store.userIconUrl,
            name: this.user.name,
          };
          setInformation(
            this.userDetail.uid,
            `からいいねされました`,
            "like",
            "",
            inforValue,
            this.userDetail.token ? this.userDetail.token : "",
            false,
            infoUser,
          );
          this.matchingStatus = 2;
          this.isLiked = true;

          setReport(1, this.user, this.userDetail);
        }
      }
    },
    /**
     * アピール付きいいね送信
     */
    async setAppealThumbUp() {
      var inforValue = {
        loginUser: this.user,
        showUser: this.userDetail,
        roomId: null,
      };
      const count = this.user.maxGoodCount - 1;
      var isNoError = true;
      try {
        // チャットを作成
        /**
         * Like:相手側の情報
         * Liked:ログイン側の情報
         */
        var room_id = "";

        const docRef = await addDoc(collection(db, "chat_room"), {
          isSendLiked: false,
          sendCountLiked: this.user.isSubscription ? 10 : 3,
          likedUid: this.user.uid,
          isSendLike: true,
          sendCountLike: this.userDetail.isSubscription ? 10 : 3,
          likeUid: this.userDetail.uid,
          createdAt: new Date(),
        });
        room_id = docRef.id;
        inforValue.roomId = room_id;
        this.rid = room_id;

        await addDoc(collection(db, `chat_room/${room_id}/contents`), {
          uid: this.user.uid,
          name: this.user.name,
          createdAt: new Date(),
          id: this.randomString(),
          text: this.message,
          url: null,
          width: null,
        });

        // 相手のいいね一覧に自分の情報を追加
        const docLikeForUserDetail = doc(db, "users", this.userDetail.uid);
        await updateDoc(docLikeForUserDetail, {
          isGoodBadges: false,
          likeUID: arrayUnion(this.user.uid),
        });

        await setDoc(
          doc(db, `users/${this.userDetail.uid}/likeUsers`, this.user.uid),
          {
            uid: this.user.uid,
            name: this.user.name,
            birthday: this.user.birthday,
            sex: this.user.sex,
            roomId: room_id,
            likeAt: new Date(),
            lastMessage: "アピールいいねが届きました：" + this.message,
            updatedAt: new Date(),
            isIconUrl: this.user.isIconUrl,
            isMyMessage: false,
          },
        );

        // 自分のいいねをした一覧に相手の情報を追加&いいね回数更新
        const docLikeForUser = doc(db, "users", this.user.uid);
        await updateDoc(docLikeForUser, {
          likedUID: arrayUnion(this.userDetail.uid),
          lastGoodAt: new Date(),
          maxGoodCount: count,
        });

        await setDoc(
          doc(db, `users/${this.user.uid}/likedUsers`, this.userDetail.uid),
          {
            uid: this.userDetail.uid,
            name: this.userDetail.name,
            birthday: this.userDetail.birthday,
            sex: this.userDetail.sex,
            roomId: room_id,
            likeAt: new Date(),
            lastMessage: "アピールいいねが届きました：" + this.message,
            updatedAt: new Date(),
            isIconUrl: this.userDetail.isIconUrl,
            isMyMessage: true,
          },
        );
      } catch (e) {
        isNoError = false;
        console.error(e);
      } finally {
        // 通知(エラーがなかったら送る)
        if (isNoError) {
          this.user.likedUID.push(this.userDetail.uid);
          this.user.maxGoodCount = count;
          setUser(this.user, this.user.uid);
          const infoUser = {
            uid: this.user.uid,
            icon_url: store.userIconUrl,
            name: this.user.name,
          };
          setInformation(
            this.userDetail.uid,
            `からいいねされました`,
            "like",
            "",
            inforValue,
            this.userDetail.token ? this.userDetail.token : "",
            false,
            infoUser,
          );
          this.matchingStatus = 2;
          this.isLiked = true;

          setReport(2, this.user, this.userDetail);
        }
      }
    },
    /**
     * マッチングする
     */
    async setMatching() {
      var inforValue = {
        loginUser: this.user,
        showUser: this.userDetail,
        roomId: this.rid,
      };
      var isNoError = true;
      try {
        // 相手のマッチングに自分を追加
        const docMatchingForUserDetail = doc(db, "users", this.userDetail.uid);
        await updateDoc(docMatchingForUserDetail, {
          isChatBadges: false,
          matchingUID: arrayUnion(this.user.uid),
        });

        await setDoc(
          doc(db, `users/${this.userDetail.uid}/matchingUsers`, this.user.uid),
          {
            uid: this.user.uid,
            name: this.user.name,
            birthday: this.user.birthday,
            sex: this.user.sex,
            matching_room: this.rid,
            matchingAt: new Date(),
            updatedAt: new Date(),
            isIconUrl: this.user.isIconUrl,
            lastMessage: "マッチングしました",
            isMyMessage: false,
          },
        );

        // 自分のマッチングに相手を追加
        const docMatchingForUser = doc(db, "users", this.user.uid);
        await updateDoc(docMatchingForUser, {
          matchingUID: arrayUnion(this.userDetail.uid),
          lastReplyGoodAt: new Date(),
          isReplyGood: false,
        });

        await setDoc(
          doc(db, `users/${this.user.uid}/matchingUsers`, this.userDetail.uid),
          {
            uid: this.userDetail.uid,
            name: this.userDetail.name,
            birthday: this.userDetail.birthday,
            sex: this.userDetail.sex,
            matching_room: this.rid,
            matchingAt: new Date(),
            updatedAt: new Date(),
            isIconUrl: this.userDetail.isIconUrl,
            lastMessage: "マッチングしました",
            isMyMessage: true,
          },
        );

        // タイムライン作成
        var timeline_id = "";

        const docTimelineRef = await addDoc(
          collection(db, "matching_timeline"),
          {
            createdAt: new Date(),
          },
        );
        timeline_id = docTimelineRef.id;

        await addDoc(
          collection(db, `matching_timeline/${timeline_id}/contents`),
          {
            message: "マッチングしました",
            isFirst: true,
            createdAt: new Date(),
          },
        );

        const docTimeline = doc(db, "chat_room", this.rid);
        await updateDoc(docTimeline, {
          timelineId: timeline_id,
        });
      } catch (e) {
        isNoError = false;
        console.error(e);
      } finally {
        // 通知(エラーがなかったら送る)
        if (isNoError) {
          this.user.likedUID.push(this.userDetail.uid);
          setUser(this.user, this.user.uid);
          const infoUser = {
            uid: this.user.uid,
            icon_url: store.userIconUrl,
            name: this.user.name,
          };
          setInformation(
            this.userDetail.uid,
            `とマッチングしました`,
            "matching",
            "",
            inforValue,
            this.userDetail.token ? this.userDetail.token : "",
            false,
            infoUser,
          );
          this.matchingStatus = 4;
          this.isMatching = true;

          setReport(3, this.user, this.userDetail);
        }
      }
    },
    /**
     * アピールDMへ遷移
     */
    linkAppealDM() {
      this.$router.push("/message/appea/" + this.rid);
    },
    /**
     * DMへ遷移
     */
    linkDM() {
      this.$router.push("/message/person/" + this.rid);
    },
    /**
     * ブロックする
     */
    async setBlock() {
      // 相手のusers
      const docUserDetail = doc(db, "users", this.userDetail.uid);
      await updateDoc(docUserDetail, {
        blockedUID: arrayUnion(this.user.uid),
      });
      // 自分のusers
      const docUser = doc(db, "users", this.user.uid);
      const blockUser = {
        uid: this.userDetail.uid,
        name: this.userDetail.name,
      };
      await updateDoc(docUser, {
        blockedUID: arrayUnion(this.userDetail.uid),
        blockUsers: arrayUnion(blockUser),
      });
      this.myBottomSheet = false;
      this.$toast.show("ブロックしました", {
        position: "bottom",
        duration: 2000,
      });
    },
    /**
     * 通報する
     * googleフォームを表示して通報されたユーザを記録する
     */
    async setReport() {
      const docUserDetail = doc(db, "users", this.userDetail.uid);
      await updateDoc(docUserDetail, {
        blockedUID: arrayUnion(this.user.uid),
      });
      const docUser = doc(db, "users", this.user.uid);
      const reportUser = {
        uid: this.userDetail.uid,
        name: this.userDetail.name,
      };
      await updateDoc(docUser, {
        blockedUID: arrayUnion(this.userDetail.uid),
        reportUsers: arrayUnion(reportUser),
      });
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSetVhNaUBJbpYYxLV-dLh2w8UXmZM33uoH8oBxcF2_60X5sOg/viewform",
        "_blank",
        "noopener,noreferrer",
      );
      this.myBottomSheet = false;
      this.$toast.show("通報しました", {
        position: "bottom",
        duration: 2000,
      });
    },
    /**
     * マッチング解除
     */
    async unMatching() {
      console.log("test");

      // 相手の情報から自分を削除
      // いいねされた
      const docRef1 = doc(
        db,
        "users",
        this.userDetail.uid,
        "likeUsers",
        this.user.uid,
      );
      await deleteDoc(docRef1);

      // いいねした
      const docRef2 = doc(
        db,
        "users",
        this.userDetail.uid,
        "likedUsers",
        this.user.uid,
      );
      await deleteDoc(docRef2);

      // マッチング
      const docRef3 = doc(
        db,
        "users",
        this.userDetail.uid,
        "matchingUsers",
        this.user.uid,
      );
      await deleteDoc(docRef3);

      const docForUserDetai = doc(db, "users", this.userDetail.uid);
      await updateDoc(docForUserDetai, {
        likeUID: arrayRemove(this.user.uid),
        likedUID: arrayRemove(this.user.uid),
        matchingUID: arrayRemove(this.user.uid),
      });

      // 自分の情報から相手を削除
      // いいねされた
      const docUserRef1 = doc(
        db,
        "users",
        this.user.uid,
        "likeUsers",
        this.userDetail.uid,
      );
      await deleteDoc(docUserRef1);

      // いいねした
      const docUserRef2 = doc(
        db,
        "users",
        this.user.uid,
        "likedUsers",
        this.userDetail.uid,
      );
      await deleteDoc(docUserRef2);

      // マッチング
      const docUserRef3 = doc(
        db,
        "users",
        this.user.uid,
        "matchingUsers",
        this.userDetail.uid,
      );
      await deleteDoc(docUserRef3);

      const docForUser = doc(db, "users", this.user.uid);
      await updateDoc(docForUser, {
        likeUID: arrayRemove(this.userDetail.uid),
        likedUID: arrayRemove(this.userDetail.uid),
        matchingUID: arrayRemove(this.userDetail.uid),
      });

      //戻る
    },
    /**
     * グループチャットへ遷移
     */
    linkGroupChat() {
      this.$router.push("/message/list?tab=2");
    },
    randomString() {
      const array = new Uint8Array(16);
      window.crypto.getRandomValues(array);
      const r = btoa(String.fromCharCode(...array))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");

      return r;
    },
    async setBookmark(f) {
      this.isBookmark = f;
      const docForUser = doc(db, "users", this.user.uid);

      if (f) {
        // お気に入り解除
        await updateDoc(docForUser, {
          bookmarks: arrayRemove(this.userDetail.uid),
        });
        const docBookmarkUserRef = doc(
          db,
          "users",
          this.user.uid,
          "bookmarks",
          this.userDetail.uid,
        );
        await deleteDoc(docBookmarkUserRef);
      } else {
        // お気に入り追加
        await updateDoc(docForUser, {
          bookmarks: arrayUnion(this.userDetail.uid),
        });
        await setDoc(
          doc(db, `users/${this.user.uid}/bookmarks`, this.userDetail.uid),
          {
            uid: this.userDetail.uid,
            name: this.userDetail.name,
            createdAt: new Date(),
          },
        );
      }
    },
  },
};
</script>

<style scoped>
.user-detail {
  min-height: 100%;
  background-color: #fff;
}

.page {
  padding-top: 55px;
  padding-bottom: 80px;
}

.footer-left {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 99;
}
.btn-footer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #784ba0;
  color: #fff;
  padding-top: 10px;
  padding-left: 10px;
  margin-left: 15px;
  border: solid 1px rgb(215 201 227 / 70%);
}
.btn-footer-d {
  height: 60px;
  border-radius: 30px;
  background-color: #a0a0a0;
  color: #fff;
  padding: 15px 20px;
  margin-left: 15px;
  border: solid 1px rgb(215 201 227 / 70%);
}
.list-group-item {
  padding: 0 !important; /* paddingを0に */
  margin: 0 !important; /* marginを0に */
  border: none !important; /* borderを非表示に */
}
.btn-kinkyer-d {
  border: solid 1px #ffffff;
  background-color: #ffffff;
  color: #784ba0;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 20px;
}

.btn-kinkyer-d:hover {
  background-color: #ffffff;
  color: #784ba0;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer-d:active {
  background-color: #ffffff;
  color: #784ba0;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer-d:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer-d:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #ffffff;
  color: #784ba0;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.img-user-icon {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  border: solid 1px #ddd;
}
</style>
