<template>
  <AppHeader :is_icon="false" :is_back="true" :title="'subscription'" type="" />
  <div class="subscription-page">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="text-center" style="padding-top: 15px">
              ご利用中のプラン
              <div style="padding: 10px; font-weight: 600; font-size: 18px">
                <div v-if="user.isSubscription">Kinkyer+</div>
                <div v-else>Free</div>
              </div>
              <div style="padding: 10px">
                <div class="box-kinkyer m-plus-medium">
                  <div class="cherry-bomb-one-regular" style="font-size: 28px">
                    Kinkyer+
                  </div>
                  <div
                    class="m-plus-regular"
                    style="
                      font-size: 12px;
                      font-weight: 600;
                      padding-bottom: 6px;
                    "
                  >
                    マッチング率がアップ！<br />
                    まずはプラン内容を確認してみてください
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row d-flex align-items-center justify-content-center">
              <div class="col" style="font-size: 14px; font-weight: 600">
                特典
              </div>
              <div
                class="col-3 text-center"
                style="font-size: 10px; font-weight: 600"
              >
                <span style="font-size: 12px">Kinkyer＋</span><br />女性ユーザー
              </div>
              <div class="col-2 text-center" style="font-size: 12px">Free</div>
            </div>
            <div class="row" style="margin-top: 5px">
              <div class="col" style="font-size: 12px">
                いいね、アピールいいねを送る
              </div>
              <div class="col-3 text-center" style="font-size: 13px">
                1日10回
              </div>
              <div
                class="col-2 text-center"
                style="font-size: 11px; color: #888"
              >
                1日3回
              </div>
            </div>
            <div class="row" style="margin-top: 5px">
              <div class="col" style="font-size: 12px">
                マッチング前メッセージ
              </div>
              <div class="col-3 text-center" style="font-size: 13px">
                10通迄
              </div>
              <div
                class="col-2 text-center"
                style="font-size: 11px; color: #888"
              >
                3通迄
              </div>
            </div>
            <div class="row" style="margin-top: 5px">
              <div class="col" style="font-size: 12px">マッチングいいね</div>
              <div class="col-3 text-center" style="font-size: 13px">
                無制限
              </div>
              <div
                class="col-2 text-center"
                style="font-size: 11px; color: #888"
              >
                1日1回
              </div>
            </div>
            <div class="row" style="margin-top: 5px">
              <div class="col" style="font-size: 12px">おでかけマッチング</div>
              <div class="col-3 text-center" style="font-size: 18px">
                <i class="bi bi-check-lg"></i>
              </div>
              <div
                class="col-2 text-center"
                style="font-size: 18px; color: #888"
              >
                <i class="bi bi-x"></i>
              </div>
            </div>
            <div class="row" style="margin-top: 5px">
              <div class="col" style="font-size: 12px">メッセージ</div>
              <div class="col-3 text-center" style="font-size: 18px">
                <i class="bi bi-check-lg"></i>
              </div>
              <div
                class="col-2 text-center"
                style="font-size: 18px; color: #888"
              >
                <i class="bi bi-x"></i>
              </div>
            </div>
            <div class="row" style="margin-top: 5px">
              <div class="col" style="font-size: 12px">思い出タイムライン</div>
              <div class="col-3 text-center" style="font-size: 18px">
                <i class="bi bi-check-lg"></i>
              </div>
              <div
                class="col-2 text-center"
                style="font-size: 18px; color: #888"
              >
                <i class="bi bi-check-lg"></i>
              </div>
            </div>
            <div class="row" style="margin-top: 5px">
              <div class="col" style="font-size: 12px">グループチャット</div>
              <div class="col-3 text-center" style="font-size: 18px">
                <i class="bi bi-check-lg"></i>
              </div>
              <div
                class="col-2 text-center"
                style="font-size: 18px; color: #888"
              >
                <i class="bi bi-x"></i>
              </div>
            </div>
            <div class="row" style="margin-top: 5px">
              <div class="col" style="font-size: 12px">イベントカレンダー</div>
              <div class="col-3 text-center" style="font-size: 18px">
                <i class="bi bi-check-lg"></i>
              </div>
              <div
                class="col-2 text-center"
                style="font-size: 18px; color: #888"
              >
                <i class="bi bi-check-lg"></i>
              </div>
            </div>
            <div class="row" style="margin-top: 5px">
              <div class="col" style="font-size: 12px">投稿</div>
              <div class="col-3 text-center" style="font-size: 18px">
                <i class="bi bi-check-lg"></i>
              </div>
              <div
                class="col-2 text-center"
                style="font-size: 18px; color: #888"
              >
                <i class="bi bi-check-lg"></i>
              </div>
            </div>
            <div class="row" style="margin-top: 5px">
              <div class="col" style="font-size: 12px">お気に入り</div>
              <div class="col-3 text-center" style="font-size: 18px">
                <i class="bi bi-check-lg"></i>
              </div>
              <div
                class="col-2 text-center"
                style="font-size: 18px; color: #888"
              >
                <i class="bi bi-check-lg"></i>
              </div>
            </div>
            <br />
            <div class="text-center" style="font-size: 12px">
              女性ユーザー様は無料でKinkyer＋と同じ特典をご利用できます<br />
              こちらは公的書類上の性別によって判別しております
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 75px;
      padding: 5px 20px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="
              openExternalLink(
                'https://secure.telecomcredit.co.jp/inetcredit/secure/order.pl?clientip=31728&money=1280&rebill_param_id=1month1280yen&kinkyerid=' +
                  user.uid,
              )
            "
          >
            1ヶ月1,280円へ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/HeaderView.vue";
import { store } from "../../plugins/store";

export default {
  data() {
    return {
      user: {},
      login: null,
      isEdit: false,
      isUserRemove: false,
    };
  },
  created() {
    this.user = store.user;
  },
  components: {
    AppHeader,
  },
  methods: {
    openExternalLink(url) {
      window.open(url, "_blank", "noopener,noreferrer");
    },
  },
};
</script>

<style scoped>
.subscription-page {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 10px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
  padding-top: 0px;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
  padding: 0 20px;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.box-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  background: -moz-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: -webkit-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  width: 100%;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
