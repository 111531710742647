<template>
  <AppHeader :is_icon="false" :is_back="false" :title="''" type="none" />
  <div class="message-list">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div
              class="text-center"
              style="
                padding: 30px 0;
                color: #784ba0;
                font-size: 22px;
                font-weight: bold;
              "
            >
              ページが見つかりません
            </div>
            <div class="text-center" style="padding-top: 20px">
              <img
                :src="require('@/assets/images/404.png')"
                style="width: 210px"
              />
            </div>
            <div
              class="text-center"
              style="padding: 30px 0; color: #784ba0; font-size: 12px"
            >
              お探しのページが見つかりません<br />
              一時的にアクセスできない状況にあるか<br />
              移動もしくは削除された可能性があります
            </div>
            <div class="text-center">
              <router-link to="/">
                <button
                  type="button"
                  class="btn btn-kinkyer m-plus-medium"
                  style="padding: 10px 50px"
                >
                  トップへ戻る
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/HeaderView.vue";

export default {
  data() {
    return {
      uid: "",
      tabIndex: 1,
      androidIndex: 1,
      iphoneIndex: 1,
    };
  },
  created() {},
  components: {
    AppHeader,
  },
  methods: {},
};
</script>

<style scoped>
.page {
  padding-top: 60px;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
