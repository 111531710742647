<template>
  <AppHeader
    :is_icon="false"
    :is_back="true"
    :title="''"
    :type="isEntry ? 'skip_icon' : 'none'"
    @header-action-click="headerActionClick"
  />
  <div class="user-edit-profile" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div style="padding: 0 8px">
              <div style="padding-bottom: 15px" v-show="isEntry">
                <div
                  class="progress"
                  role="progressbar"
                  aria-label="Basic example"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    class="progress-bar"
                    :style="
                      'background-color: #784ba0 !important; width: ' +
                      count +
                      '%'
                    "
                  ></div>
                </div>
              </div>
              <div class="item-box">
                <div
                  style="
                    padding-top: 0px;
                    padding-bottom: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #827490;
                  "
                >
                  界隈
                </div>
                <div
                  style="
                    padding: 10px 20px;
                    font-size: 12px;
                    font-weight: 400;
                    background-color: rgb(244, 245, 247);
                    border-radius: 6px;
                  "
                >
                  興味ある界隈をチェックしてみましょう（５つまで）<br />
                  新しい界隈の場合は追加をお願いします<br />
                  たとえば「Kinkyer界隈」を追加する場合は「Kinkyer」または「Kinkyer界隈」と入力してください
                </div>
                <div style="padding-top: 10px">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="おすすめの界隈は？"
                      v-model="kaiwai"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-kinkyer"
                        type="button"
                        style="
                          border-radius: 0 6px 6px 0 !important;
                          font-size: 16px !important;
                        "
                        @click="addKaiwai"
                      >
                        界隈追加
                      </button>
                    </div>
                  </div>
                </div>
                <div style="padding-top: 10px">
                  <button
                    v-for="(item, i) in this.kaiwais"
                    :key="i"
                    type="button"
                    style="
                      margin-right: 5px;
                      margin-bottom: 5px;
                      width: auto !important;
                      border-radius: 30px !important;
                    "
                    :class="
                      isSelectedKaiwais(item)
                        ? 'btn btn-kinkyer m-plus-medium'
                        : 'btn btn-kinkyer-outline-by-color m-plus-medium'
                    "
                    @click="clickSelectedKaiwais(item)"
                  >
                    #{{ item }}界隈
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 75px;
      padding: 5px 20px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="((isEdit = true), update())"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-none d-md-block"
    style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
  >
    <div class="container" style="height: 100%">
      <div class="row" style="height: 100%">
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
        ></div>
        <div class="col-8"></div>
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
        ></div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import AppHeader from "@/components/HeaderView.vue";
import "vue-select/dist/vue-select.css";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc, getDoc, arrayUnion } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      isEdit: false,
      uid: "",
      user: {},
      isEntry: true,
      btnName: "",
      selectedKaiwais: [],
      selectedNoteKaiwai: [],
      kaiwai: "",
      count: 0,

      /// オプション設定
      kaiwais: [],
    };
  },
  watch: {
    // データが変更されたときにスクロールをリセット
    content: {
      async handler() {
        await nextTick();
        if (this.$refs.scrollContainer) {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.count = 3.846 * 22;
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";

    const docRef = doc(db, "tags", "SXJLna3qLwK5iCp7CMuV");
    const docSnap = await getDoc(docRef);

    this.kaiwais = docSnap.data().kaiwai;

    this.user = store.user;
    this.selectedKaiwais =
      this.user.selectedKaiwais == undefined ? [] : this.user.selectedKaiwais;
    this.selectedNoteKaiwai =
      this.user.selectedNoteKaiwai == undefined
        ? []
        : this.user.selectedNoteKaiwai;
  },
  components: {
    AppHeader,
  },
  methods: {
    async update() {
      try {
        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        const uniqueArray = [
          ...new Set([...this.selectedKaiwais, ...this.selectedNoteKaiwai]),
        ];

        await updateDoc(docUpdateUsersRef, {
          selectedKaiwais: this.selectedKaiwais,
          selectedNoteKaiwai: uniqueArray,
        });

        const docUpdateShareUsersRef = doc(db, "shareUsers", this.user.uid);

        await updateDoc(docUpdateShareUsersRef, {
          selectedKaiwais: this.selectedKaiwais,
          selectedNoteKaiwai: uniqueArray,
        });

        this.user.selectedKaiwais = this.selectedKaiwais;
        this.user.selectedNoteKaiwai = uniqueArray;

        setUser(this.user, this.user.uid);

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push("/user/edit/fetishism/" + this.$route.params.type);
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
    headerActionClick(data) {
      if (data == "skip_icon") {
        this.update();
      }
    },
    isSelectedKaiwais(value) {
      return this.selectedKaiwais.includes(value);
    },
    clickSelectedKaiwais(value) {
      if (this.isSelectedKaiwais(value)) {
        const index = this.selectedKaiwais.indexOf(value);
        if (index !== -1) {
          this.selectedKaiwais.splice(index, 1); // 配列から削除
        }
      } else {
        if (this.selectedKaiwais.length < 5) {
          this.selectedKaiwais.push(value);
        }
      }
    },
    async addKaiwai() {
      if (this.kaiwai.length > 0) {
        var item = this.kaiwai;
        if (item.endsWith("界隈")) {
          item = item.slice(0, -2);
        }

        this.kaiwais.push(item);

        const userRef = doc(db, "tags", "SXJLna3qLwK5iCp7CMuV");
        await updateDoc(userRef, {
          kaiwai: arrayUnion(item),
        });

        this.kaiwai = "";
      }
    },
  },
};
</script>

<style scoped>
.user-edit-profile {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
</style>
