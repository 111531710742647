<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
    <div class="user-preview" ref="scrollContainer">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <!-- メインアイコン -->
              <div
                style="
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              >
                <div v-if="imageUrl">
                  <img :src="imageUrl" width="100%" />
                </div>
                <div v-else>
                  <img :src="require('@/assets/icon_user.webp')" width="100%" />
                </div>
              </div>
              <!-- サブアイコン -->
              <div>
                <listProfileImageView :uids="imageList" />
              </div>
              <div class="text-center" style="padding: 5px">
                <button
                  @click="linkIconMod"
                  type="button"
                  class="btn btn-secondary btn-sm"
                >
                  <i class="bi bi-camera"></i>&nbsp;アイコン設定
                </button>
              </div>
              <!-- プロフィール -->
              <div
                style="
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              >
                <ProfileView
                  :data="userDetail"
                  :is_mod="true"
                  :is_share="false"
                />
              </div>
              <!-- 界隈 -->
              <div
                style="
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              >
                <KaiwaiView :data="userDetail" :is_mod="true" />
              </div>
              <!-- フェチ -->
              <div
                style="
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              >
                <FetishismView :data="userDetail" :is_mod="true" />
              </div>
              <!-- BDSM -->
              <div
                style="
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              >
                <BDSMView :data="userDetail" :is_mod="true" />
              </div>
              <!-- 性癖 -->
              <div
                style="
                  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1
                    forwards;
                "
              >
                <SeihekiView :data="userDetail" :is_mod="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import { nextTick } from "vue";
import AppHeader from "@/components/HeaderView.vue";
import listProfileImageView from "@/components/user/listProfileImageView.vue";
import ProfileView from "@/components/user/ProfileView.vue";
import FetishismView from "@/components/user/FetishismView.vue";
import BDSMView from "@/components/user/BDSMView.vue";
import SeihekiView from "@/components/user/SeihekiView.vue";
import KaiwaiView from "@/components/user/KaiwaiView.vue";
import { storage } from "../../plugins/firebase";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { store } from "../../plugins/store";

export default {
  data() {
    return {
      user: "",
      userDetail: {},
      imageList: [],
      imageUrl: null,
    };
  },
  async created() {
    this.user = store.user;
    this.userDetail = store.user;

    const exists = this.user?.imageList !== undefined;
    if (exists) {
      this.imageList = this.user.imageList;
    }
  },
  mounted() {
    this.getUserIcon();
    this.user = store.user;
    this.userDetail = store.user;
  },
  watch: {
    // データが変更されたときにスクロールをリセット
    content: {
      async handler() {
        await nextTick();
        if (this.$refs.scrollContainer) {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
  },
  components: {
    AppHeader,
    listProfileImageView,
    ProfileView,
    FetishismView,
    BDSMView,
    SeihekiView,
    KaiwaiView,
    VPullToRefresh,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getUserIcon() {
      if (this.userDetail.isIconUrl) {
        const imageRef = storageRef(
          storage,
          `users/icon/${store.user.uid}/user_icon1.png`,
        );

        try {
          const url = await getDownloadURL(imageRef);
          this.imageUrl = url;
        } catch (error) {
          console.log("---------- user/preview ---------");
          console.error("Error fetching image URL:", error);
        }
      }
    },
    linkIconMod() {
      this.$router.push("/user/edit/icon/mod");
    },
  },
};
</script>

<style scoped>
.user-preview {
  min-height: 100%;
  background-color: #fff;
}

.page {
  padding-top: 55px;
  padding-bottom: 80px;
}

.footer-left {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 99;
}
.btn-footer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #784ba0;
  color: #fff;
  padding-top: 10px;
  padding-left: 10px;
  margin-left: 15px;
  border: solid 1px rgb(215 201 227 / 70%);
}
.list-group-item {
  padding: 0 !important; /* paddingを0に */
  margin: 0 !important; /* marginを0に */
  border: none !important; /* borderを非表示に */
}
</style>
