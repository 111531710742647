<template>
  <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
  <div class="user-edit-profile" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div style="padding: 0 8px">
              <div style="padding-bottom: 15px" v-show="isEntry">
                <div
                  class="progress"
                  role="progressbar"
                  aria-label="Basic example"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    class="progress-bar"
                    :style="
                      'background-color: #784ba0 !important; width: ' +
                      count +
                      '%'
                    "
                  ></div>
                </div>
              </div>
              <div
                style="
                  padding-top: 10px;
                  padding-bottom: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  color: #827490;
                "
              >
                名前・ニックネーム
              </div>
              <div
                style="
                  padding: 5px 0px 10px 0;
                  font-size: 12px;
                  font-weight: 400;
                "
              >
                20文字以内で入力してください<br />
                名前は後から変更もできます<br />
                <span
                  class="badge bg-danger"
                  style="
                    margin-top: 5px;
                    font-size: 10px;
                    position: relative;
                    font-weight: 700;
                    bottom: 2px;
                  "
                  >必須項目</span
                >
              </div>
              <div class="item-box">
                <div>
                  <input
                    type="text"
                    class="form-control kinkyer-form-control"
                    placeholder=""
                    v-model="name"
                  />
                  <div
                    class="text-end"
                    :style="
                      name.length > 20
                        ? 'color: red;font-size: 10px'
                        : 'font-size: 10px'
                    "
                  >
                    {{ name.length }}/20
                  </div>
                </div>
              </div>
              <div
                style="
                  padding-top: 10px;
                  padding-bottom: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  color: #827490;
                "
              >
                一言コメント
              </div>
              <div
                style="
                  padding: 5px 0px 10px 0;
                  font-size: 12px;
                  font-weight: 400;
                "
              >
                プロフィールや検索リストに表示するコメントです<br />
                是非みんなにアピールしてみましょう<br />
                ※30文字までです
              </div>
              <div class="item-box">
                <div>
                  <input
                    type="text"
                    class="form-control kinkyer-form-control"
                    placeholder=""
                    :style="CommentText.length > 30 ? 'border-color: red' : ''"
                    v-model="CommentText"
                  />
                  <div
                    class="text-end"
                    :style="
                      CommentText.length > 30
                        ? 'color: red;font-size: 10px'
                        : 'font-size: 10px'
                    "
                  >
                    {{ CommentText.length }}/30
                  </div>
                </div>
              </div>
              <div
                style="
                  padding-top: 10px;
                  padding-bottom: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  color: #827490;
                "
              >
                アピールタグ
              </div>
              <div
                style="
                  padding: 5px 0px 10px 0;
                  font-size: 12px;
                  font-weight: 400;
                "
              >
                特にこの話題で交流したいタグがあれば選択しましょう<br />
                ※必ず決められたタグで話す必要はありません
              </div>
              <div class="item-box">
                <div style="padding-top: 5px">
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagGreen"
                      v-on:change="isTagGreen = !isTagGreen"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(76, 175, 80)"
                      ></i
                      >&nbsp;Kinky世界の初心者
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagYellow"
                      v-on:change="isTagYellow = !isTagYellow"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(255, 235, 59)"
                      ></i
                      >&nbsp;気軽に話しかけてね
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagCyan"
                      v-on:change="isTagCyan = !isTagCyan"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(0, 188, 212)"
                      ></i
                      >&nbsp;イベントやお店に行きたい
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagBlue"
                      v-on:change="isTagBlue = !isTagBlue"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(33, 150, 243)"
                      ></i
                      >&nbsp;何かおすすめ紹介して
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagPurple"
                      v-on:change="isTagPurple = !isTagPurple"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(156, 39, 176)"
                      ></i
                      >&nbsp;パートナー募集中
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagOrange"
                      v-on:change="isTagOrange = !isTagOrange"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(255, 152, 0)"
                      ></i
                      >&nbsp;みんなで猥談しましょう
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagRed"
                      v-on:change="isTagRed = !isTagRed"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(244, 67, 54)"
                      ></i
                      >&nbsp;お悩み相談したい
                    </label>
                  </div>
                </div>
              </div>
              <div
                style="
                  padding-top: 10px;
                  padding-bottom: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  color: #827490;
                "
              >
                募集タグ
              </div>
              <div
                style="
                  padding: 5px 0px 10px 0;
                  font-size: 12px;
                  font-weight: 400;
                "
              >
                どのような相手を探しているか選択しましょう
              </div>
              <div class="item-box">
                <div style="padding-top: 5px">
                  <button
                    v-for="(item, i) in this.tags"
                    :key="i"
                    type="button"
                    style="
                      margin-right: 5px;
                      margin-bottom: 5px;
                      width: auto !important;
                      border-radius: 30px !important;
                    "
                    :class="
                      isSelectedTags(item)
                        ? 'btn btn-kinkyer m-plus-medium'
                        : 'btn btn-kinkyer-outline-by-color m-plus-medium'
                    "
                    @click="clickSelectedTags(item)"
                  >
                    {{ item }}
                  </button>
                </div>
              </div>
              <div
                style="
                  padding-top: 10px;
                  padding-bottom: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  color: #827490;
                "
              >
                S度＆M度
              </div>
              <div
                style="
                  padding: 5px 0px 10px 0;
                  font-size: 12px;
                  font-weight: 400;
                "
              >
                あなたのS度またはM度がどれくらいあるか教えてください
              </div>
              <div class="item-box" style="padding-bottom: 0">
                <div class="item-title">S度【{{ sadistic }}％】</div>
                <div style="padding-top: 5px">
                  <v-slider
                    v-model="sadistic"
                    color="#543FDD"
                    label=""
                    :max="100"
                    :min="0"
                    :step="1"
                  ></v-slider>
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">M度【{{ masochist }}％】</div>
                <div style="padding-top: 5px">
                  <v-slider
                    v-model="masochist"
                    color="#D85992"
                    label=""
                    :max="100"
                    :min="0"
                    :step="1"
                  ></v-slider>
                </div>
              </div>
              <div
                style="
                  padding-top: 10px;
                  padding-bottom: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  color: #827490;
                "
              >
                プロフィール用URL
              </div>
              <div
                style="
                  padding: 5px 0px 10px 0;
                  font-size: 12px;
                  font-weight: 400;
                "
              >
                SNSやサイトなどのURLが入力できます<br />
                ※未入力のまま次へ進めます
              </div>
              <div class="item-box">
                <div>
                  <input
                    type="url"
                    class="form-control kinkyer-form-control"
                    placeholder=""
                    v-model="weburl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 75px;
      padding: 5px 8px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="((isEdit = true), update())"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/HeaderView.vue";
import "vue-select/dist/vue-select.css";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc, setDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      isEdit: false,
      isEntry: false,
      user: {},
      sex: null,
      btnName: "",
      name: "",
      count: 0,
      CommentText: "はじめまして",
      isTagGreen: false,
      isTagYellow: false,
      isTagCyan: false,
      isTagBlue: false,
      isTagPurple: false,
      isTagOrange: false,
      isTagRed: false,
      selectedTags: [],
      tags: [
        "恋人募集",
        "日常的なパートナー募集",
        "一時的なプレイパートナー募集",
        "友達募集",
        "趣味友や仲間募集",
        "真剣交際",
        "既婚・恋人がいます",
        "他にパートナーいます",
        "ペット・従者がいます",
        "お仕えする主がいます",
        "フリーです",
        "まずはDMでじっくり話したい",
        "カフェなどでお話しする",
        "すぐ会いたい",
        "デートしたい",
        "写真は交換したい",
        "電話や通話をしたい",
        "界隈でお仕事してます",
      ],
      sadistic: 50,
      masochist: 50,
      weburl: "",
    };
  },
  async created() {
    this.count = 3.846 * 1;
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";
    this.user = store.user;
  },
  mounted() {
    this.name = this.user.name == undefined ? "" : this.user.name;
    this.CommentText =
      this.user.comment == undefined ? "はじめまして" : this.user.comment;
    this.isTagGreen =
      this.user.isTagGreen == undefined ? false : this.user.isTagGreen;
    this.isTagYellow =
      this.user.isTagYellow == undefined ? false : this.user.isTagYellow;
    this.isTagCyan =
      this.user.isTagCyan == undefined ? false : this.user.isTagCyan;
    this.isTagBlue =
      this.user.isTagBlue == undefined ? false : this.user.isTagBlue;
    this.isTagPurple =
      this.user.isTagPurple == undefined ? false : this.user.isTagPurple;
    this.isTagOrange =
      this.user.isTagOrange == undefined ? false : this.user.isTagOrange;
    this.isTagRed =
      this.user.isTagRed == undefined ? false : this.user.isTagRed;
    this.selectedTags =
      this.user.selectTag == undefined ? [] : this.user.selectTag;
    this.sadistic = this.user.sadistic == undefined ? 50 : this.user.sadistic;
    this.masochist =
      this.user.masochist == undefined ? 50 : this.user.masochist;
    this.weburl = this.user.weburl == undefined ? "" : this.user.weburl;
  },
  components: {
    AppHeader,
  },
  methods: {
    async update() {
      try {
        if (this.name == "" || this.name.length > 20) {
          this.isEdit = false;
          this.$toast.show("名前・ニックネームを入力してください", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
          return;
        } else if (this.CommentText.length > 30) {
          this.isEdit = false;
          this.$toast.show("30文字以内で登録してください", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
          return;
        }

        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        await updateDoc(docUpdateUsersRef, {
          name: this.name,
          comment: this.CommentText,
          isTagGreen: this.isTagGreen,
          isTagYellow: this.isTagYellow,
          isTagCyan: this.isTagCyan,
          isTagBlue: this.isTagBlue,
          isTagPurple: this.isTagPurple,
          isTagOrange: this.isTagOrange,
          isTagRed: this.isTagRed,
          selectTag: this.selectedTags,
          sadistic: this.sadistic,
          masochist: this.masochist,
          weburl: this.weburl,
        });
        await setDoc(doc(db, "shareUsers", this.user.uid), {
          name: this.name,
          comment: this.CommentText,
          isTagGreen: this.isTagGreen,
          isTagYellow: this.isTagYellow,
          isTagCyan: this.isTagCyan,
          isTagBlue: this.isTagBlue,
          isTagPurple: this.isTagPurple,
          isTagOrange: this.isTagOrange,
          isTagRed: this.isTagRed,
          selectTag: this.selectedTags,
          sadistic: this.sadistic,
          masochist: this.masochist,
          weburl: this.weburl,
        });

        this.user.name = this.name;
        this.user.comment = this.CommentText;
        this.user.isTagGreen = this.isTagGreen;
        this.user.isTagYellow = this.isTagYellow;
        this.user.isTagCyan = this.isTagCyan;
        this.user.isTagBlue = this.isTagBlue;
        this.user.isTagPurple = this.isTagPurple;
        this.user.isTagOrange = this.isTagOrange;
        this.user.isTagRed = this.isTagRed;
        this.user.selectTag = this.selectedTags;
        this.user.sadistic = this.sadistic;
        this.user.masochist = this.masochist;
        this.user.weburl = this.weburl;

        setUser(this.user, this.user.uid);

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push("/user/edit/birthday/" + this.$route.params.type);
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
    isSelectedTags(value) {
      return this.selectedTags.includes(value);
    },
    clickSelectedTags(value) {
      if (this.isSelectedTags(value)) {
        const index = this.selectedTags.indexOf(value);
        if (index !== -1) {
          this.selectedTags.splice(index, 1); // 配列から削除
        }
      } else {
        this.selectedTags.push(value);
      }
    },
  },
};
</script>

<style scoped>
.user-edit-profile {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
</style>
