<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
    <div class="like-list">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div style="padding: 0 8px">
                <div>
                  <div
                    style="
                      padding: 10px 20px;
                      font-size: 12px;
                      font-weight: 400;
                      background-color: rgb(244, 245, 247);
                      border-radius: 6px;
                    "
                  >
                    検索条件を入力してください
                  </div>
                </div>
                <br />
                <div class="item-box">
                  <div>
                    <div class="item-title">年齢</div>
                    <div style="padding: 40px 20px 0px 20px">
                      <v-range-slider
                        v-model="search.rangeAge"
                        step="1"
                        :max="100"
                        :min="18"
                        thumb-label="always"
                        color="#784ba0"
                      >
                      </v-range-slider>
                    </div>
                  </div>
                  <div>
                    <div class="item-title">性別</div>
                    <div style="padding: 5px 0">
                      <v-select
                        :options="['男性', '女性']"
                        v-model="search.sex"
                        placeholder="性別"
                      />
                    </div>
                  </div>
                  <div style="padding-top: 10px">
                    <div class="item-title">居住地</div>
                    <div style="padding: 5px 0">
                      <v-select
                        :options="optionsArea"
                        v-model="search.selectedArea"
                        :close-on-select="false"
                        placeholder="居住地"
                        multiple
                      />
                    </div>
                  </div>
                </div>
                <br />
                <!-- <div>
                  <div
                    style="
                      padding: 10px 20px;
                      font-size: 12px;
                      font-weight: 400;
                      background-color: rgb(244, 245, 247);
                      border-radius: 6px;
                    "
                  >
                    フェチズム、BDSM、性癖の各項目は指定した数値以上を検索します
                  </div>
                  <div style="padding-top: 20px">
                    <div class="item-title">フェチズム</div>
                    <div style="padding: 5px">
                      <div
                        v-for="(item, i) in this.search.questionList1"
                        :key="i"
                      >
                        <div class="item-subtitle">
                          {{ item.label }}【 {{ item.value }} 以上】
                        </div>
                        <div style="padding: 5px 20px">
                          <v-slider
                            v-model="item.value"
                            color="#784ba0"
                            :max="6"
                            :min="0"
                            :ticks="tickLabels"
                            :step="1"
                          ></v-slider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="padding-top: 20px">
                    <div class="item-title">BDSM</div>
                    <div style="padding: 5px">
                      <div
                        v-for="(item, i) in this.search.questionList2"
                        :key="i"
                      >
                        <div class="item-subtitle">
                          {{ item.label }}【 {{ item.value }} 以上】
                        </div>
                        <div style="padding: 5px 20px">
                          <v-slider
                            v-model="item.value"
                            color="#784ba0"
                            :max="6"
                            :min="0"
                            :ticks="tickLabels"
                            :step="1"
                          ></v-slider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="padding-top: 20px">
                    <div class="item-title">性癖</div>
                    <div style="padding: 5px">
                      <div
                        v-for="(item, i) in this.search.questionList3"
                        :key="i"
                      >
                        <div class="item-subtitle">
                          {{ item.label }}【 {{ item.value }} 以上】
                        </div>
                        <div style="padding: 5px 20px">
                          <v-slider
                            v-model="item.value"
                            color="#784ba0"
                            :max="6"
                            :min="0"
                            :ticks="tickLabels"
                            :step="1"
                          ></v-slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 65px;
        padding: 5px 20px;
      "
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8" style="height: 65px; background-color: #fff">
            <button
              type="button"
              style="width: 100%; height: 40px"
              class="btn btn-kinkyer m-plus-medium"
              @click="((isEdit = true), update())"
            >
              検索する
            </button>
          </div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { db } from "../../plugins/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { store, setUser } from "../../plugins/store";

export default {
  data() {
    return {
      user: {},
      search: {
        rangeAge: [18, 100],
        sex: null,
        selectedArea: [],
        isProfile: false,
        isFetishism: false,
        isBDSM: false,
        isSeiheki: false,
        questionList1: {
          question1: {
            label: "匂い",
            column: "question1",
            value: 0,
          },
          question2: {
            label: "体液・血",
            column: "question2",
            value: 0,
          },
          question3: {
            label: "唇",
            column: "question3",
            value: 0,
          },
          question4: {
            label: "声",
            column: "question4",
            value: 0,
          },
          question5: {
            label: "お尻",
            column: "question5",
            value: 0,
          },
          question6: {
            label: "胸",
            column: "question6",
            value: 0,
          },
          question7: {
            label: "手・指",
            column: "question7",
            value: 0,
          },
          question8: {
            label: "腕",
            column: "question8",
            value: 0,
          },
          question9: {
            label: "太腿",
            column: "question9",
            value: 0,
          },
          question10: {
            label: "鼠蹊部",
            column: "question10",
            value: 0,
          },
          question11: {
            label: "腰",
            column: "question11",
            value: 0,
          },
          question12: {
            label: "耳",
            column: "question12",
            value: 0,
          },
          question13: {
            label: "首",
            column: "question13",
            value: 0,
          },
          question14: {
            label: "筋肉",
            column: "question14",
            value: 0,
          },
          question15: {
            label: "骨格・鎖骨",
            column: "question15",
            value: 0,
          },
          question16: {
            label: "髪の毛",
            column: "question16",
            value: 0,
          },
          question64: {
            label: "黒子（ほくろ）",
            column: "question64",
            value: 0,
          },
          question65: {
            label: "ラバー",
            column: "question65",
            value: 0,
          },
          question66: {
            label: "ウェット&メッシー",
            column: "question66",
            value: 0,
          },
          question67: {
            label: "ケモナー",
            column: "question67",
            value: 0,
          },
          question68: {
            label: "オブジェクトフィリア（対物性愛）",
            column: "question68",
            value: 0,
          },
        },
        questionList2: {
          question17: {
            label: "サディスト",
            column: "question17",
            value: 0,
          },
          question18: {
            label: "マゾヒスト",
            column: "question18",
            value: 0,
          },
          question19: {
            label: "ディシプリン",
            column: "question19",
            value: 0,
          },
          question20: {
            label: "主（ダディ・マミー）",
            column: "question20",
            value: 0,
          },
          question21: {
            label: "従（リトル・全てを委ねたい人）",
            column: "question21",
            value: 0,
          },
          question22: {
            label: "リガー（縛り手・拘束する人）",
            column: "question22",
            value: 0,
          },
          question23: {
            label: "ロープバニー（受け手）",
            column: "question23",
            value: 0,
          },
          question24: {
            label: "ドミナント",
            column: "question24",
            value: 0,
          },
          question25: {
            label: "サブミッシブ",
            column: "question25",
            value: 0,
          },
          question26: {
            label: "ブラットテイマー",
            column: "question26",
            value: 0,
          },
          question27: {
            label: "ブラット",
            column: "question27",
            value: 0,
          },
          question28: {
            label: "プライマルハンター（狩人）",
            column: "question28",
            value: 0,
          },
          question29: {
            label: "プライマルプリ（本能的なプレイを好む獲物側）",
            column: "question29",
            value: 0,
          },
          question30: {
            label: "デグレーダ（人間扱いをしない・モノ扱いする人）",
            column: "question30",
            value: 0,
          },
          question31: {
            label: "デグレード（人間家具やモノ扱いされる人）",
            column: "question31",
            value: 0,
          },
          question32: {
            label: "マスター",
            column: "question32",
            value: 0,
          },
          question33: {
            label: "スレーブ（奴隷）",
            column: "question33",
            value: 0,
          },
          question34: {
            label: "オーナー",
            column: "question34",
            value: 0,
          },
          question35: {
            label: "ペット",
            column: "question35",
            value: 0,
          },
        },
        questionList3: {
          question36: {
            label: "SEX",
            column: "question36",
            value: 0,
          },
          question37: {
            label: "キス・ハグ",
            column: "question37",
            value: 0,
          },
          question38: {
            label: "フェラ・クンニ",
            column: "question38",
            value: 0,
          },
          question39: {
            label: "イラマチオ",
            column: "question39",
            value: 0,
          },
          question40: {
            label: "アナル",
            column: "question40",
            value: 0,
          },
          question41: {
            label: "コスプレ",
            column: "question41",
            value: 0,
          },
          question42: {
            label: "撮影",
            column: "question42",
            value: 0,
          },
          question43: {
            label: "首絞め",
            column: "question43",
            value: 0,
          },
          question44: {
            label: "噛み跡",
            column: "question44",
            value: 0,
          },
          question45: {
            label: "露出願望",
            column: "question45",
            value: 0,
          },
          question46: {
            label: "ボイジャー（覗きたい）",
            column: "question46",
            value: 0,
          },
          question47: {
            label: "スイッチ（攻と受どちらも楽しめる）",
            column: "question47",
            value: 0,
          },
          question48: {
            label: "実験家（新しい遊びをしたい）",
            column: "question48",
            value: 0,
          },
          question49: {
            label: "エイジプレイヤー（年の差が好き）",
            column: "question49",
            value: 0,
          },
          question50: {
            label: "バニラ（一般的なプレイが好き）",
            column: "question50",
            value: 0,
          },
          question51: {
            label: "ノンモノガミスト（複数プレイが好き）",
            column: "question51",
            value: 0,
          },
          question52: {
            label: "モノガミスト（一人の人とプレイしたい）",
            column: "question52",
            value: 0,
          },
          question53: {
            label: "サピオシャス（知能が優れた人が好き）",
            column: "question53",
            value: 0,
          },
          question54: {
            label: "鞭・打具を使うプレイ",
            column: "question54",
            value: 0,
          },
          question55: {
            label: "拘束具を使うプレイ",
            column: "question55",
            value: 0,
          },
          question56: {
            label: "玩具を使うプレイ",
            column: "question56",
            value: 0,
          },
          question57: {
            label: "蝋燭を使うプレイ",
            column: "question57",
            value: 0,
          },
          question58: {
            label: "緊縛プレイ",
            column: "question58",
            value: 0,
          },
          question59: {
            label: "マミフィケーションプレイ",
            column: "question59",
            value: 0,
          },
          question60: {
            label: "全身タイツプレイ",
            column: "question60",
            value: 0,
          },
          question61: {
            label: "ぐりぐら",
            column: "question61",
            value: 0,
          },
          question69: {
            label: "ソフレ",
            column: "question69",
            value: 0,
          },
          question70: {
            label: "舐め犬",
            column: "question70",
            value: 0,
          },
          question62: {
            label: "スローセックス・ポリネシアンセックス",
            column: "question62",
            value: 0,
          },
          question63: {
            label: "スパンキング",
            column: "question63",
            value: 0,
          },
        },
      },

      /// オプション設定
      tickLabels: {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
      },
      optionsArea: [
        "北海道（札幌）",
        "北海道（道東）",
        "北海道（道南）",
        "北海道（道北）",
        "北海道（道央）",
        "青森県",
        "岩手県",
        "宮城県",
        "秋田県",
        "山形県",
        "福島県",
        "茨城県",
        "栃木県",
        "群馬県",
        "埼玉県",
        "千葉県",
        "東京都",
        "神奈川県",
        "新潟県",
        "富山県",
        "石川県",
        "福井県",
        "山梨県",
        "長野県",
        "岐阜県",
        "静岡県",
        "愛知県",
        "三重県",
        "滋賀県",
        "京都府",
        "大阪府",
        "兵庫県",
        "奈良県",
        "和歌山県",
        "鳥取県",
        "島根県",
        "岡山県",
        "広島県",
        "山口県",
        "徳島県",
        "香川県",
        "愛媛県",
        "高知県",
        "福岡県",
        "佐賀県",
        "長崎県",
        "熊本県",
        "大分県",
        "宮崎県",
        "鹿児島県",
        "沖縄県",
        "海外",
        "ネットのみ",
      ],
      isEdit: false,
    };
  },
  created() {
    this.user = store.user;

    if (this.user?.search) {
      this.search.rangeAge = [
        this.user.search.age_from,
        this.user.search.age_to,
      ];
      this.search.sex = this.user.search.sex;
      this.search.selectedArea = this.user.search.area;
    }
  },
  components: {
    AppHeader,
    vSelect,
    VPullToRefresh,
  },
  methods: {
    async update() {
      try {
        this.isEdit = true;
        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        this.user["search"] = {
          age_from: this.search.rangeAge[0],
          age_to: this.search.rangeAge[1],
          sex: this.search.sex,
          area: this.search.selectedArea,
          isProfile: false,
          isFetishism: false,
          isBDSM: false,
          isSeiheki: false,
        };

        await updateDoc(docUpdateUsersRef, {
          search: this.user["search"],
        });

        setUser(this.user, this.user.uid);

        this.$router.go(-1);
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("更新時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      } finally {
        this.isEdit = false;
      }
    },
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style scoped>
.like-list {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 10px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
  padding-top: 0px;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
  padding: 0 20px;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
