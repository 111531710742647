<template>
  <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
  <div class="user-edit-profile" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div style="padding: 0 8px">
              <div style="padding-bottom: 15px" v-show="isEntry">
                <div
                  class="progress"
                  role="progressbar"
                  aria-label="Basic example"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    class="progress-bar"
                    :style="
                      'background-color: #784ba0 !important; width: ' +
                      count +
                      '%'
                    "
                  ></div>
                </div>
              </div>
              <div
                style="
                  padding-top: 10px;
                  padding-bottom: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  color: #827490;
                "
              >
                S度＆M度
              </div>
              <div
                style="
                  padding: 10px 0px 20px 0;
                  font-size: 12px;
                  font-weight: 400;
                "
              >
                あなたのS度またはM度がどれくらいあるか教えてください
              </div>
              <div class="item-box" style="padding-bottom: 0">
                <div class="item-title">S度【{{ sadistic }}％】</div>
                <div style="padding-top: 5px">
                  <v-slider
                    v-model="sadistic"
                    color="#543FDD"
                    label=""
                    :max="100"
                    :min="0"
                    :step="1"
                  ></v-slider>
                </div>
              </div>
              <div class="item-box">
                <div class="item-title">M度【{{ masochist }}％】</div>
                <div style="padding-top: 5px">
                  <v-slider
                    v-model="masochist"
                    color="#D85992"
                    label=""
                    :max="100"
                    :min="0"
                    :step="1"
                  ></v-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 75px;
      padding: 5px 8px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="((isEdit = true), update())"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/HeaderView.vue";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc, setDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      isEdit: false,
      isEntry: false,
      user: {},
      btnName: "",
      count: 0,
      sadistic: 50,
      masochist: 50,
    };
  },
  async created() {
    this.count = 3.846 * 16;
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";
    this.user = store.user;
  },
  mounted() {
    this.sadistic = this.user.sadistic == undefined ? 50 : this.user.sadistic;
    this.masochist =
      this.user.masochist == undefined ? 50 : this.user.masochist;
  },
  components: {
    AppHeader,
  },
  methods: {
    async update() {
      try {
        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        await updateDoc(docUpdateUsersRef, {
          sadistic: this.sadistic,
          masochist: this.masochist,
        });
        await setDoc(doc(db, "shareUsers", this.user.uid), {
          sadistic: this.sadistic,
          masochist: this.masochist,
        });

        this.user.sadistic = this.sadistic;
        this.user.masochist = this.masochist;

        setUser(this.user, this.user.uid);

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push("/user/edit/appeal/" + this.$route.params.type);
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
  },
};
</script>

<style scoped>
.user-edit-profile {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
</style>
