import { db } from "./firebase";
import { addDoc, updateDoc, doc, collection } from "firebase/firestore";

/**
 * サービス内通知
 * 通知のタイミング
 * ・いいねしたorされた
 * ・マッチングが成立した
 * ・メッセージが送信された
 * ・グループチャットにメッセージがきた
 * @param {ユーザID} uid
 * @param {メッセージ} message
 * @param {通知タイプ} type
 * none：ページ遷移なし、user,like,matching,dm,dm_appeal,group,group_chat,note_reply,note_reaction,note_like：ページ遷移あり、url：外部リンク
 * group_chatは追加値（念の為
 * @param {遷移先} code
 * URLを指定する
 * @param {パラメータ} value
 * （遷移先がユーザ詳細の場合はuidなど各データのID
 * @param {push通知用のトークン} token
 * informationに登録するuidとログイン中のuidが一致する場合はいらない（自分へのアクションになるため
 * @param {表示フラグ} isOld
 * trueの場合はすでに保存済みの通知で古いUIで対応
 * @param {通知ユーザー} infoUser
 * 通知一覧に表示するユーザー情報でicon_urlとnameを保持させる
 */
export async function setInformation(
  uid,
  message,
  type,
  code,
  value,
  token,
  isOld,
  infoUser,
) {
  if (uid != infoUser.uid) {
    const docUpdateRef = doc(db, "users", uid);
    await updateDoc(docUpdateRef, {
      isHeaderBadges: true,
    });
    await addDoc(collection(db, `users/${uid}/information`), {
      message: message,
      type: type,
      code: code,
      value: value,
      isView: false,
      token: token,
      isOld: isOld,
      infoUser: infoUser,
      createdAt: new Date(),
    });
  }

  if (token != "") {
    console.log("----- 通知登録_トークン -----", token);
  }
}
