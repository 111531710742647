<template>
  <div class="auth-body">
    <div class="top">
      <div class="logo-box text-center">
        <span class="title cherry-bomb-one-regular">Kinkyer</span>
        <br />
        <span class="subtitle cherry-bomb-one-regular">
          〜すべてのkinkyへ〜
        </span>
      </div>
      <div class="btn-box text-center">
        <div style="padding-bottom: 10px">
          <router-link to="/entry/top">
            <button
              type="button"
              style="width: 250px !important; border-radius: 30px !important"
              class="btn btn-kinkyer-outline m-plus-medium"
            >
              アカウントを作成する
            </button>
          </router-link>
        </div>
        <div style="padding-bottom: 10px">
          <router-link to="/auth">
            <button
              type="button"
              style="width: 250px !important; border-radius: 30px !important"
              class="btn btn-kinkyer-outline m-plus-medium"
            >
              ログインする
            </button>
          </router-link>
        </div>
        <p class="subnote">18歳未満の方はご利用いただけません</p>
        <p class="subnote">
          <a href="https://kinkyer.jp/transactions/" target="_blank">
            特定商法取引法に基づく表示
          </a>
        </p>
        <br />
        <p class="subnote">三鷹24-040623</p>
      </div>
    </div>
  </div>
  <div
    v-if="isInit"
    style="
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      background-color: #784ba0;
      margin: 0 auto;
      text-align: center;
      display: flex;
      align-items: center;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import { db, auth } from "./../plugins/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { setUser, setNoteTag } from "./../plugins/store";
import { setReport } from "./../plugins/report";

export default {
  data() {
    return {
      isInit: true,
    };
  },
  mounted() {
    onAuthStateChanged(auth, async (user) => {
      if (user != null) {
        const docRef = doc(db, "authLink", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const docUserRef = doc(db, "users", docSnap.data().uid);
          const docUserSnap = await getDoc(docUserRef);

          if (docUserSnap.exists()) {
            setUser(docUserSnap.data(), docSnap.data().uid);
            console.log("------ [kinkyer/top-init] ------");
            if (docUserSnap.data().birthday == undefined) {
              this.$router.push("/user/edit/name/entry");
            } else {
              setNoteTag("#Home");
              setReport(4, docUserSnap.data(), undefined);
              this.$router.push("/home");
            }
          } else {
            console.log("login?");
          }
        } else {
          console.log("login???");
        }
        this.isInit = false;
      } else {
        this.isInit = false;
      }
    });
  },
};
</script>

<style scoped>
p {
  margin-bottom: 5px;
}
a {
  color: #fff !important;
  text-decoration: underline !important;
}
a:focus {
  color: #fff !important;
  text-decoration: underline !important;
}

.top {
  height: 100%;
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
}
.title {
  color: #fff;
  font-size: 65px;
}

.subtitle {
  color: #fff;
  font-size: 24px;
}

.subnote {
  color: #fff;
  font-size: 12px;
}

.logo-box {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 180px;
}

.btn-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 50px;
}
</style>
