<template>
  <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
  <div class="user-edit-profile" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div style="padding: 0 8px">
              <div style="padding-bottom: 15px" v-show="isEntry">
                <div
                  class="progress"
                  role="progressbar"
                  aria-label="Basic example"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    class="progress-bar"
                    :style="
                      'background-color: #784ba0 !important; width: ' +
                      count +
                      '%'
                    "
                  ></div>
                </div>
              </div>
              <div
                style="
                  padding-top: 10px;
                  padding-bottom: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  color: #827490;
                "
              >
                アピールタグ
              </div>
              <div
                style="
                  padding: 10px 0px 20px 0;
                  font-size: 12px;
                  font-weight: 400;
                "
              >
                特にこの話題で交流したいタグがあれば選択しましょう<br />
                ※必ず決められたタグで話す必要はありません
              </div>
              <div class="item-box">
                <div style="padding-top: 5px">
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagGreen"
                      v-on:change="isTagGreen = !isTagGreen"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(76, 175, 80)"
                      ></i
                      >&nbsp;Kinky世界の初心者
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagYellow"
                      v-on:change="isTagYellow = !isTagYellow"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(255, 235, 59)"
                      ></i
                      >&nbsp;気軽に話しかけてね
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagCyan"
                      v-on:change="isTagCyan = !isTagCyan"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(0, 188, 212)"
                      ></i
                      >&nbsp;イベントやお店に行きたい
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagBlue"
                      v-on:change="isTagBlue = !isTagBlue"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(33, 150, 243)"
                      ></i
                      >&nbsp;何かおすすめ紹介して
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagPurple"
                      v-on:change="isTagPurple = !isTagPurple"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(156, 39, 176)"
                      ></i
                      >&nbsp;パートナー募集中
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagOrange"
                      v-on:change="isTagOrange = !isTagOrange"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(255, 152, 0)"
                      ></i
                      >&nbsp;みんなで猥談しましょう
                    </label>
                  </div>
                  <div class="form-check" style="padding-bottom: 6px">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      style="margin-top: 6px"
                      v-bind:checked="isTagRed"
                      v-on:change="isTagRed = !isTagRed"
                    />
                    <label class="form-check-label">
                      <i
                        class="bi bi-square-fill"
                        style="color: rgb(244, 67, 54)"
                      ></i
                      >&nbsp;お悩み相談したい
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 75px;
      padding: 5px 8px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="((isEdit = true), update())"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/HeaderView.vue";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc, setDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      isEdit: false,
      isEntry: false,
      user: {},
      btnName: "",
      count: 0,
      isTagGreen: false,
      isTagYellow: false,
      isTagCyan: false,
      isTagBlue: false,
      isTagPurple: false,
      isTagOrange: false,
      isTagRed: false,
    };
  },
  async created() {
    this.count = 3.846 * 17;
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";
    this.user = store.user;
  },
  mounted() {
    this.isTagGreen =
      this.user.isTagGreen == undefined ? false : this.user.isTagGreen;
    this.isTagYellow =
      this.user.isTagYellow == undefined ? false : this.user.isTagYellow;
    this.isTagCyan =
      this.user.isTagCyan == undefined ? false : this.user.isTagCyan;
    this.isTagBlue =
      this.user.isTagBlue == undefined ? false : this.user.isTagBlue;
    this.isTagPurple =
      this.user.isTagPurple == undefined ? false : this.user.isTagPurple;
    this.isTagOrange =
      this.user.isTagOrange == undefined ? false : this.user.isTagOrange;
    this.isTagRed =
      this.user.isTagRed == undefined ? false : this.user.isTagRed;
  },
  components: {
    AppHeader,
  },
  methods: {
    async update() {
      try {
        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        await updateDoc(docUpdateUsersRef, {
          isTagGreen: this.isTagGreen,
          isTagYellow: this.isTagYellow,
          isTagCyan: this.isTagCyan,
          isTagBlue: this.isTagBlue,
          isTagPurple: this.isTagPurple,
          isTagOrange: this.isTagOrange,
          isTagRed: this.isTagRed,
        });
        await setDoc(doc(db, "shareUsers", this.user.uid), {
          isTagGreen: this.isTagGreen,
          isTagYellow: this.isTagYellow,
          isTagCyan: this.isTagCyan,
          isTagBlue: this.isTagBlue,
          isTagPurple: this.isTagPurple,
          isTagOrange: this.isTagOrange,
          isTagRed: this.isTagRed,
        });

        this.user.isTagGreen = this.isTagGreen;
        this.user.isTagYellow = this.isTagYellow;
        this.user.isTagCyan = this.isTagCyan;
        this.user.isTagBlue = this.isTagBlue;
        this.user.isTagPurple = this.isTagPurple;
        this.user.isTagOrange = this.isTagOrange;
        this.user.isTagRed = this.isTagRed;

        setUser(this.user, this.user.uid);

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push("/user/edit/comment/" + this.$route.params.type);
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
  },
};
</script>

<style scoped>
.user-edit-profile {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
</style>
