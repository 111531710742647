<template>
  <div v-if="imageUrl">
    <img :src="imageUrl" />
  </div>
  <div v-else>
    <img :src="require('@/assets/icon_user.webp')" />
  </div>
</template>

<script>
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { storage } from "../../plugins/firebase";

export default {
  data() {
    return {
      imageUrl: null,
      userId: "",
      isIconUrl: false,
    };
  },
  props: ["uid", "is_icon"],
  created() {
    this.userId = this.uid;
    this.isIconUrl = this.is_icon == undefined ? true : this.is_icon;
    if (this.isIconUrl) {
      this.getUserIcon();
    }
  },
  methods: {
    async getUserIcon() {
      const imageRef = storageRef(
        storage,
        `users/icon/${this.userId}/user_icon1.png`,
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;
      } catch (error) {
        console.log("---------- components/timeline ---------");
        console.error("Error fetching image URL:", error);
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
</style>
