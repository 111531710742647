<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="false" :is_back="true" title="" type="none" />
    <div class="sheet">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <div class="subtitle-box">
                <div class="subtitle">ブロックリスト</div>
              </div>
              <div v-if="items.length">
                <div v-for="(item, i) in items" :key="i" class="item">
                  <div class="row">
                    <div class="col">
                      <div style="padding: 10px 15px">{{ item.name }}</div>
                    </div>
                    <div class="col-3 text-center">
                      <div style="padding-top: 10px">
                        <a @click="unBlock(item)">解除</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-center" style="font-size: 12px">
                  <br /><br /><br />
                  ブロックしたユーザーはいません
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import { store, setUser } from "../../plugins/store";
import { db } from "../../plugins/firebase";
import { doc, updateDoc, arrayRemove, getDoc } from "firebase/firestore";

export default {
  data() {
    return {
      user: {},
      pageType: "",
      items: [],
      pageTitle: "",
      whereItem: "",
      isCreated: true,
    };
  },
  async created() {
    this.user = store.user;
    this.items = this.user.blockUsers == undefined ? [] : this.user.blockUsers;
  },
  components: {
    AppHeader,
    VPullToRefresh,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async unBlock(item) {
      const index = this.items.findIndex((user) => user.uid === item.uid);
      if (index !== -1) {
        // 相手のusersから削除
        const docUserDetail = doc(db, "users", item.uid);
        const docSnap = await getDoc(docUserDetail);
        if (docSnap.exists()) {
          await updateDoc(docUserDetail, {
            blockedUID: arrayRemove(this.user.uid),
          });
        }
        // 自分のusers
        const docUser = doc(db, "users", this.user.uid);
        await updateDoc(docUser, {
          blockedUID: arrayRemove(item.uid),
          blockUsers: arrayRemove(item),
        });
        this.items.splice(index, 1);
        this.user.blockUsers = this.items;
        setUser(this.user, this.user.uid);
      }
    },
  },
};
</script>

<style scoped>
.sheet {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 55px;
}
.subtitle-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 15px;
  background-color: #efefef;
}
.subtitle {
  width: calc(100% / 2);
  font-size: 12px;
}
</style>
