<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="false" :is_back="true" title="" />
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div v-if="chatMember.length">
              <div v-for="(item, i) in chatMember" :key="i" class="item">
                <div>
                  <ListView
                    :item="item.data"
                    :type="item.data.isGroup ? 'group' : 'chat'"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div class="text-center" style="font-size: 12px">
                <br /><br /><br />
                メンバーを追加しましょう
              </div>
            </div>
            <div
              class="text-center"
              style="padding: 30px 0; font-size: 13px; color: red"
            >
              <a @click="exitRoom">退出する</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-left" v-if="isOwner">
      <div
        style="
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: #784ba0 !important;
          color: #fff;
          padding-top: 15px;
          padding-left: 15px;
        "
        @click="myBottomSheet = true"
      >
        <span class="material-symbols-rounded" style="font-size: 31px">
          person_add
        </span>
      </div>
    </div>
    <v-bottom-sheet v-model="myBottomSheet" style="z-index: 400000">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8" style="padding: 0">
            <v-sheet
              class="text-center"
              height="500px"
              style="border-radius: 15px 15px 0 0"
            >
              <div style="padding: 0px" class="text-center">
                <span
                  class="material-symbols-rounded"
                  style="color: #ccc; font-size: 40px"
                  @click="myBottomSheet = !myBottomSheet"
                >
                  remove
                </span>
              </div>
              <div style="height: 200px; padding: 0 15px">
                <div>
                  <div style="padding-bottom: 5px">ユーザー追加</div>
                  <div
                    class="text-start"
                    style="font-size: 12px; padding-bottom: 10px"
                  >
                    ユーザー名を検索してチャットに追加します
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control form-control-reset"
                      aria-describedby="emailHelp"
                      placeholder="ユーザー名..."
                      v-model="seatchTag"
                      @keydown.enter="setQuery"
                    />
                  </div>
                  <div style="padding-top: 10px">
                    <div v-if="groupUsers.length">
                      <div v-for="item in groupUsers" :key="item.id">
                        <ChatUserListByEntryView
                          :item="item.data"
                          :member="room.enttyUids"
                          @header-action-click="headerActionClick"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-sheet>
          </div>
        </div>
      </div>
    </v-bottom-sheet>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import ListView from "@/components/user/GroupListView.vue";
import ChatUserListByEntryView from "@/components/user/ChatUserListByEntryView.vue";
import { store } from "../../plugins/store";
import { db } from "../../plugins/firebase";
import {
  collection,
  getDocs,
  query,
  orderBy,
  addDoc,
  setDoc,
  doc,
  where,
  onSnapshot,
  getDoc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

export default {
  data() {
    return {
      user: {},
      room: {},
      chatMember: [],
      myBottomSheet: false,
      seatchTag: "",
      groupUsers: [],
      usersCollection: null,
      usersQuery: null,
      isOwner: false,
    };
  },
  components: {
    AppHeader,
    ListView,
    ChatUserListByEntryView,
    VPullToRefresh,
  },
  async created() {
    this.user = store.user;

    const docRef = doc(db, "chat_room", this.$route.params.matching_id);
    const docSnap = await getDoc(docRef);
    this.room = docSnap.data();

    this.isOwner = this.room.ownerUid == this.user.uid;

    const queryLike = query(
      collection(db, `chat_room/${this.$route.params.matching_id}/members`),
      orderBy("createdAt", "desc"),
    );

    const queryLikeSnapshot = await getDocs(queryLike);

    this.chatMember = queryLikeSnapshot.docs
      .map((doc) => {
        const data = doc.data();
        const uid = doc.id;
        return {
          id: uid,
          data: data,
        };
      })
      .filter((item) => item !== null);
  },
  methods: {
    reloadPage() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    /**
     * グループチャット追加
     */
    async addGrupChat() {
      if (this.seatchTag == "") {
        this.$toast.show("チャット名を入力してください", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
      try {
        var room_id = "";

        // チャットルーム作成
        const docRef = await addDoc(collection(db, "chat_room"), {
          enttyUids: [this.user.uid],
          ownerUid: this.user.uid,
          name: this.seatchTag,
          createdAt: new Date(),
        });
        room_id = docRef.id;

        await setDoc(doc(db, `chat_room/${room_id}/members`, this.user.uid), {
          uid: this.user.uid,
          name: this.user.name,
          accountId: this.user.accountId,
          createdAt: new Date(),
          isOwner: true,
        });

        // users/{uid}/matchingUsersへ追加
        // roomIDで扱うnameはチャット名でアイコンは固定
        await setDoc(doc(db, `users/${this.user.uid}/chatRooms`, room_id), {
          uid: room_id,
          name: this.seatchTag,
          roomId: room_id,
          updatedAt: new Date(),
          isIconUrl: false,
          lastMessage: "グループチャットを作成しました",
          isMyMessage: false,
          isGroup: true,
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.reloadPage();
      }
    },
    setQuery() {
      this.usersCollection = collection(db, `users`);
      const baseQuery = query(
        this.usersCollection,
        where("name", "==", this.seatchTag),
        where("uid", "!=", this.user.uid),
      );
      this.usersQuery = query(baseQuery);

      if (this.unsubscribe) this.unsubscribe();
      this.unsubscribe = onSnapshot(this.usersQuery, (snapshot) => {
        const docs = snapshot.docs
          .map((doc) => {
            const data = doc.data();
            const uid = doc.id;

            return {
              id: uid,
              data: data,
            };
          })
          .filter((item) => item !== null);

        this.groupUsers = docs;
      });
    },
    // メンバーに追加する
    async headerActionClick(data) {
      const docUsersRef = doc(db, "users", data);
      const docUsersSnap = await getDoc(docUsersRef);
      const showUser = docUsersSnap.data();
      await setDoc(
        doc(
          db,
          `chat_room/${this.$route.params.matching_id}/members`,
          showUser.uid,
        ),
        {
          uid: showUser.uid,
          name: showUser.name,
          createdAt: new Date(),
          isOwner: true,
        },
      );
      const roomRef = doc(db, "chat_room", this.$route.params.matching_id);
      await updateDoc(roomRef, {
        enttyUids: arrayUnion(showUser.uid),
      });

      await setDoc(
        doc(
          db,
          `users/${showUser.uid}/matchingUsers`,
          this.$route.params.matching_id,
        ),
        {
          uid: this.$route.params.matching_id,
          name: this.room.name,
          matching_room: this.$route.params.matching_id,
          matchingAt: new Date(),
          updatedAt: new Date(),
          isIconUrl: false,
          lastMessage: "グループチャットを作成しました",
          isMyMessage: false,
          isGroup: true,
        },
      );

      this.reloadPage();
    },
    async exitRoom() {
      const docShowUserRef = doc(
        db,
        "users",
        this.user.uid,
        "matchingUsers",
        this.$route.params.matching_id,
      );
      await deleteDoc(docShowUserRef);

      const docMemberUserRef = doc(
        db,
        "chat_room",
        this.$route.params.matching_id,
        "members",
        this.user.uid,
      );
      await deleteDoc(docMemberUserRef);

      const roomRef = doc(db, "chat_room", this.$route.params.matching_id);
      await updateDoc(roomRef, {
        enttyUids: arrayRemove(this.user.uid),
      });
      this.$router.push("/message/list");
    },
  },
};
</script>

<style scoped>
.page {
  padding-top: 60px;
}
.footer-left {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 99;
}
</style>
