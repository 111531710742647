<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
    <div class="like-list">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div style="padding: 0 8px">
                <div>
                  <div
                    style="
                      padding: 10px 20px;
                      font-size: 12px;
                      font-weight: 400;
                      background-color: rgb(244, 245, 247);
                      border-radius: 6px;
                    "
                  >
                    募集したい日を設定します。<br />
                    日付を設定せず曜日のみ選択も可能です。<br />
                    例）毎週金曜日に募集したい
                  </div>
                </div>
                <br />
                <div class="item-box">
                  <div>
                    <div class="item-title">性別</div>
                    <div style="padding: 5px 0">
                      <v-select
                        :options="['男性', '女性']"
                        v-model="search.sex"
                        placeholder="性別"
                      />
                    </div>
                  </div>
                  <div style="padding-top: 10px">
                    <div class="item-title">居住地</div>
                    <div style="padding: 5px 0">
                      <v-select
                        :options="optionsArea"
                        v-model="search.selectedArea"
                        :close-on-select="false"
                        placeholder="居住地"
                        multiple
                      />
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 65px;
        padding: 5px 20px;
      "
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8" style="height: 65px; background-color: #fff">
            <button
              type="button"
              style="width: 100%; height: 40px"
              class="btn btn-kinkyer m-plus-medium"
              @click="((isEdit = true), update())"
            >
              検索する
            </button>
          </div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      user: {},
      /// オプション設定
      optionsArea: [
        "北海道（札幌）",
        "北海道（道東）",
        "北海道（道南）",
        "北海道（道北）",
        "北海道（道央）",
        "青森県",
        "岩手県",
        "宮城県",
        "秋田県",
        "山形県",
        "福島県",
        "茨城県",
        "栃木県",
        "群馬県",
        "埼玉県",
        "千葉県",
        "東京都",
        "神奈川県",
        "新潟県",
        "富山県",
        "石川県",
        "福井県",
        "山梨県",
        "長野県",
        "岐阜県",
        "静岡県",
        "愛知県",
        "三重県",
        "滋賀県",
        "京都府",
        "大阪府",
        "兵庫県",
        "奈良県",
        "和歌山県",
        "鳥取県",
        "島根県",
        "岡山県",
        "広島県",
        "山口県",
        "徳島県",
        "香川県",
        "愛媛県",
        "高知県",
        "福岡県",
        "佐賀県",
        "長崎県",
        "熊本県",
        "大分県",
        "宮崎県",
        "鹿児島県",
        "沖縄県",
        "海外",
        "ネットのみ",
      ],
      isEdit: false,
    };
  },
  created() {
    this.user = store.user;

    if (this.user?.search) {
      this.search.rangeAge = [
        this.user.search.age_from,
        this.user.search.age_to,
      ];
      this.search.sex = this.user.search.sex;
      this.search.selectedArea = this.user.search.area;
    }
  },
  components: {
    AppHeader,
    vSelect,
    VPullToRefresh,
  },
  methods: {
    async update() {
      try {
        this.isEdit = true;
        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        this.user["search"] = {
          age_from: this.search.rangeAge[0],
          age_to: this.search.rangeAge[1],
          sex: this.search.sex,
          area: this.search.selectedArea,
          isProfile: false,
          isFetishism: false,
          isBDSM: false,
          isSeiheki: false,
        };

        await updateDoc(docUpdateUsersRef, {
          search: this.user["search"],
        });

        setUser(this.user, this.user.uid);

        this.$router.go(-1);
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("更新時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      } finally {
        this.isEdit = false;
      }
    },
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style scoped>
.like-list {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 10px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
  padding-top: 0px;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
  padding: 0 20px;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
