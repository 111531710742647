<template>
  <router-view />
</template>

<script>
import { messaging, getToken, onMessage } from "./plugins/firebase";
import { setToken } from "./plugins/store";

export default {
  data() {
    return {};
  },
  created() {
    setInterval(
      () => {
        window.location.reload(true);
      },
      24 * 60 * 60 * 1000,
    );
  },
  mounted() {
    this.requestPermission();
    onMessage(messaging, (payload) => {
      console.log("メッセージを受信:", payload);
      // 受信したメッセージに基づいてアラートを表示
      alert(payload.notification.body);
    });
  },
  methods: {
    async requestPermission() {
      try {
        const token = await getToken(messaging, {
          vapidKey: process.env.VUE_APP_VAPID,
        });
        setToken(token);
      } catch (error) {
        console.error("トークンの取得に失敗:", error);
      }
    },
  },
};
</script>

<style>
html {
  height: 100%;
}

body {
  height: 100%;
}

.auth-body {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: -moz-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: -webkit-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background-color: #784ba0;
}

#app {
  height: 100%;
  font-family: "M PLUS 1", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.m-plus-light {
  font-family: "M PLUS 1", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 100;
}

.m-plus-regular {
  font-family: "M PLUS 1", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}

.m-plus-medium {
  font-family: "M PLUS 1", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 500;
}

.cherry-bomb-one-regular {
  font-family: "Cherry Bomb One", system-ui;
  font-weight: 400;
  font-style: normal;
}

/* 通常のボタン色 */
.btn-kinkyer {
  border: solid 1px #784ba0 !important;
  background-color: #784ba0 !important;
  color: #fff !important;
  width: 100% !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: bold !important;
}
.btn-kinkyer:hover {
  background-color: #633988 !important;
  color: #fff !important;
  outline: none !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}
.btn-kinkyer:active {
  background-color: #633988 !important;
  color: #fff !important;
  outline: none !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}
.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-kinkyer.dropdown-toggle {
  background-color: #633988 !important;
  color: #fff !important;
  outline: none !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

/* 白抜きのボタン色 */
.btn-kinkyer-outline,
.btn-kinkyer-outline.disa-outlinebled,
.btn-kinkyer-outline:disabled {
  border: solid 1px #fff !important;
  color: #fff !important;
  border-radius: 8px !important;
  width: 100% !important;
  z-index: 9 !important;
}

/* focusされた時の枠線の色 */
.btn-kinkyer-outline:focus,
.btn-kinkyer-outline.focus,
.btn-kinkyer-outline:not(:disabled):not(.disabled):a-outlinective:focus,
.btn-kinkyer-outline:not(:disabled):not(.disabled).active:focus,
.show > .btn-kinkyer-outline.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #d2f0ee !important;
}

/* hover時（マウスカーソルを重ねた時）の色（通常より濃いor暗めの色を指定） */
.btn-kinkyer-outline:hover {
  color: #784ba0 !important;
  background-color: #784ba0 !important;
  border-color: #fff !important;
}

/* active時の色（hover時と同等かさらに濃いor暗めの色を指定） */
.btn-kinkyer-outline:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer-outline:not(:disabled):not(.disabled).active,
.show > .btn-kinkyer-outline.dropdown-toggle {
  color: #fff !important;
  border-color: #fff !important;
}

/* twitterのボタン色 */
.btn-twitter,
.btn-twitter.disabled,
.btn-twitter:disabled {
  color: #fff !important;
  background-color: #00acee !important;
  border-color: #00acee !important;
  border-radius: 50px !important;
  z-index: 9 !important;
}

.btn-twitter-d,
.btn-twitter-d.disabled,
.btn-twitter-d:disabled {
  color: #fff !important;
  background-color: #bbb !important;
  border-color: #bbb !important;
  border-radius: 50px !important;
}

/* focusされた時の枠線の色 */
.btn-twitter:focus,
.btn-twitter.focus,
.btn-twitter:not(:disabled):not(.disabled):active:focus,
.btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #d2f0ee !important;
}

/* hover時（マウスカーソルを重ねた時）の色（通常より濃いor暗めの色を指定） */
.btn-twitter:hover {
  color: #fff !important;
  background-color: #00acee !important;
  border-color: #00acee !important;
}

/* active時の色（hover時と同等かさらに濃いor暗めの色を指定） */
.btn-twitter:not(:disabled):not(.disabled):active,
.btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle {
  color: #fff !important;
  background-color: #5432ff !important;
  border-color: #5432ff !important;
}

/* lineのボタン色 */
.btn-line,
.btn-line.disabled,
.btn-line:disabled {
  color: #fff !important;
  background-color: #00b900 !important;
  border-color: #00b900 !important;
  border-radius: 50px !important;
  z-index: 9 !important;
}

.btn-line-d,
.btn-line-d.disabled,
.btn-line-d:disabled {
  color: #fff !important;
  background-color: #bbb !important;
  border-color: #bbb !important;
  border-radius: 50px !important;
}

/* focusされた時の枠線の色 */
.btn-line:focus,
.btn-line.focus,
.btn-line:not(:disabled):not(.disabled):active:focus,
.btn-line:not(:disabled):not(.disabled).active:focus,
.show > .btn-line.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #d2f0ee !important;
}

/* hover時（マウスカーソルを重ねた時）の色（通常より濃いor暗めの色を指定） */
.btn-line:hover {
  color: #fff !important;
  background-color: #00b900 !important;
  border-color: #00b900 !important;
}

/* active時の色（hover時と同等かさらに濃いor暗めの色を指定） */
.btn-line:not(:disabled):not(.disabled):active,
.btn-line:not(:disabled):not(.disabled).active,
.show > .btn-line.dropdown-toggle {
  color: #fff !important;
  background-color: #5432ff !important;
  border-color: #5432ff !important;
}

/* outline（色付き） */
.btn-kinkyer-outline-by-color {
  border: solid 1px #784ba0 !important;
  color: #784ba0 !important;
  width: 100% !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.btn-kinkyer-outline-by-color:hover {
  background-color: #784ba016 !important;
  color: #784ba0 !important;
  border: solid 1px #784ba0 !important;
}
.btn-kinkyer-outline-by-color:active {
  color: #784ba0 !important;
  background-color: #784ba016 !important;
  border: solid 1px #784ba0 !important;
}
.btn-kinkyer-outline-by-color:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer-outline-by-color:not(:disabled):not(.disabled).active,
.show > .btn-kinkyer-outline-by-color.dropdown-toggle {
  color: #784ba0 !important;
  border-color: #784ba0 !important;
  border: solid 1px #784ba0 !important;
}

.btn-kinkyer-by-color {
  border: solid 1px #fff !important;
  background-color: #fff !important;
  color: #784ba0 !important;
  width: 100% !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.btn-kinkyer-by-color:hover {
  background-color: #fff !important;
  color: #784ba0 !important;
  outline: none !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}
.btn-kinkyer-by-color:active {
  background-color: #fff !important;
  color: #784ba0 !important;
  outline: none !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}
.btn-kinkyer-by-color:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer-by-color:not(:disabled):not(.disabled).active,
.show > .btn-kinkyer-by-color.dropdown-toggle {
  background-color: #fff !important;
  color: #784ba0 !important;
  outline: none !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

.v--default-css {
  font-size: 10px !important;
  font-weight: bold !important;
  bottom: 100px !important;
}
.v--default-css .c-toast--default {
  background-color: #784ba0 !important;
}
.v-bottom-sheet > .v-bottom-sheet__content.v-overlay__content {
  box-shadow: none !important;
}
.alert {
  border: 0 !important;
  margin-bottom: 3px !important;
  margin-right: 5px !important;
  padding: 4px 6px !important;
}
.alert-dismissible {
  padding-right: 30px !important;
}
.alert-dismissible .close {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 5px !important;
}
.vue-simple-suggest.designed .input-wrapper input {
  border-radius: 8px 0 0 8px !important;
}
.list-group-flush {
  border-radius: 8px !important;
}
.list-group-item {
  padding: 0 !important;
  border: 1px solid rgb(242 242 247) !important;
}
.toggle-container:focus {
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}
table,
td,
th {
  border: none !important;
}
.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 0px !important;
  text-align: center !important;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 4px !important;
  text-align: center !important;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #784ba01d;
  border-radius: 5px !important;
  font-weight: 600;
}
.fc .fc-daygrid-day {
  color: #999 !important;
}
.fc .fc-daygrid-day-number,
.fc .fc-col-header-cell-cushion {
  color: #777 !important;
  font-size: 13px !important;
}
.fc .fc-daygrid-day-top {
  display: flex !important;
  flex-direction: column !important;
  text-align: center !important;
}
.fc-h-event {
  background-color: #784ba0 !important;
  border: 0 !important;
}
.fc-h-event .fc-event-title-container {
  height: 3px !important;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 12px !important;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 12px !important;
}
a {
  text-decoration: none !important;
}
.form-control:focus {
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
}
.v-input__control {
  padding-left: 0 !important;
}
.v-simple-checkbox {
  display: none !important;
}
.vs__selected {
  background-color: #784ba0 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: 0 !important;
  font-size: 14px !important;
}
.vs__deselect svg {
  fill: #fff !important; /* SVGアイコンの色を青に変更 */
}

.vs__deselect:hover svg {
  fill: #fff !important; /* ホバー時の色を変更 */
}

.v-pull-to-refresh__pull-down {
  top: 0px !important;
  z-index: 9;
}
.v-progress-circular--size-default {
  top: 50px !important;
  color: #784ba0 !important;
}
.preserve-linebreak {
  white-space: pre-wrap; /* 改行コードを適切に処理 */
  word-wrap: break-word; /* 幅を超えた場合の改行 */
}
.tooltip-inner {
  background-color: #784ba0 !important; /* 背景色 */
  color: #fff !important; /* 文字色 */
  font-size: 14px !important;
  padding: 12px 15px !important;
  border-radius: 6px !important;
  text-align: left !important;
}
.tooltip {
  --bs-tooltip-bg: #784ba0 !important; /* ← これで矢印と背景どちらも統一される */
}
.tooltip.show {
  opacity: 1 !important;
}

/* フォーム追加要素 */
.kinkyer-form-control {
  background-color: #efefef !important;
  border: 0 !important;
  color: #717171 !important;
  padding: 10px !important;
}
</style>
