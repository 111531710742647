<template>
  <AppHeader
    :is_icon="false"
    :is_back="true"
    :title="''"
    :type="isEntry ? 'skip_icon' : 'none'"
    @header-action-click="headerActionClick"
  />
  <div class="user-edit-detail" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div style="padding: 0 8px">
              <div style="padding-bottom: 15px" v-show="isEntry">
                <div
                  class="progress"
                  role="progressbar"
                  aria-label="Basic example"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    class="progress-bar"
                    :style="
                      'background-color: #784ba0 !important; width: ' +
                      count +
                      '%'
                    "
                  ></div>
                </div>
              </div>
              <div class="item-box">
                <div
                  style="
                    padding-top: 0px;
                    padding-bottom: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #827490;
                  "
                >
                  プロフィールアイコン編集
                </div>
                <div
                  style="
                    padding: 10px 0px 20px 0;
                    font-size: 12px;
                    font-weight: 400;
                  "
                >
                  プロフィールに表示するアイコン画像を設定しましょう
                </div>
                <div style="padding-top: 10px">
                  <div v-if="imageUrl">
                    <img :src="imageUrl" class="img-icon" />
                  </div>
                  <div v-else>
                    <img
                      :src="require('@/assets/icon_user.webp')"
                      class="img-icon"
                    />
                  </div>
                </div>
                <div class="text-center" style="padding: 10px 0">
                  <input
                    id="file-icon-upload"
                    type="file"
                    style="display: none"
                    @change="fileUpload"
                    ref="fileInput"
                  />
                  <a
                    type="button"
                    class="btn btn-secondary btn-sm"
                    for="file-icon-upload"
                    @click="this.$refs.fileInput.click()"
                  >
                    画像を選択する
                  </a>
                </div>
              </div>
              <div class="item-box" v-if="!isEntry">
                <div
                  style="
                    padding-top: 0px;
                    padding-bottom: 10px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #827490;
                  "
                >
                  プロフィール写真リスト
                </div>
                <div
                  style="
                    padding: 10px 0px 20px 0;
                    font-size: 12px;
                    font-weight: 400;
                  "
                >
                  アイコン画像の他に最大で6枚の画像が設定できます
                </div>
                <div style="padding-top: 10px">
                  <div
                    v-if="img_urls.length > 0"
                    style="
                      height: 210px;
                      display: flex;
                      overflow-x: scroll;
                      margin-bottom: 10px;
                    "
                  >
                    <div
                      v-for="(img_url, i) in img_urls"
                      :key="i"
                      style="
                        float: left;
                        flex-shrink: 0;
                        width: auto;
                        height: 210px;
                      "
                    >
                      <div
                        style="
                          position: relative;
                          float: right;
                          right: 30px;
                          top: 5px;
                          text-shadow:
                            1px 1px 2px #ffffff,
                            -1px 1px 2px #ffffff,
                            1px -1px 2px #ffffff,
                            -1px -1px 2px #ffffff,
                            1px 0px 2px #ffffff,
                            0px 1px 2px #ffffff,
                            -1px 0px 2px #ffffff,
                            0px -1px 2px #ffffff;
                        "
                      >
                        <span
                          class="material-symbols-rounded"
                          style="color: red"
                          @click="removeImgs(i)"
                        >
                          close
                        </span>
                      </div>
                      <img
                        style="
                          width: auto;
                          height: 200px;
                          margin: 5px 5px 5px 0;
                          border-radius: 8px;
                          border: solid 1px #eee;
                        "
                        :src="img_url.url"
                      />
                    </div>
                  </div>
                </div>
                <div class="text-center" style="padding: 10px 0">
                  <input
                    id="file-images-upload"
                    type="file"
                    style="display: none"
                    @change="filesUpload"
                    ref="filesInput"
                  />
                  <a
                    type="button"
                    class="btn btn-secondary btn-sm"
                    for="file-images-upload"
                    @click="this.$refs.filesInput.click()"
                  >
                    画像を追加する
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 75px;
      padding: 5px 20px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="((isEdit = true), update())"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-none d-md-block"
    style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
  >
    <div class="container" style="height: 100%">
      <div class="row" style="height: 100%">
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
        ></div>
        <div class="col-8"></div>
        <div
          class="col-2"
          style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
        ></div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import AppHeader from "@/components/HeaderView.vue";
import { db, storage } from "../../../plugins/firebase";
import {
  doc,
  updateDoc,
  addDoc,
  collection,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import {
  ref as storageRef,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import {
  store,
  setUser,
  setUserIconUrl,
  removeUserIconUrl,
} from "../../../plugins/store";
import imageCompression from "browser-image-compression";

export default {
  data() {
    return {
      isEdit: false,
      isEntry: true,
      uid: "",
      user: {},
      imageUrl: null,
      iconFile: null,
      img_urls: [],
      removeUrls: [],
      files: [],
      isUploadError: false,
      count: 0,
    };
  },
  watch: {
    // データが変更されたときにスクロールをリセット
    content: {
      async handler() {
        await nextTick();
        if (this.$refs.scrollContainer) {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.count = 3.846 * 5;
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";

    this.user = store.user;

    if (this.user.imageList) {
      for (var i in this.user.imageList) {
        const imageRef = storageRef(storage, this.user.imageList[i]);

        const url = await getDownloadURL(imageRef);
        this.img_urls.push({
          url: url,
          type: "https",
          path: this.user.imageList[i],
        });
      }
    }
  },
  mounted() {
    if (store.userIconUrl == "") {
      this.getUserIcon();
    } else {
      this.imageUrl = store.userIconUrl;
    }
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  components: {
    AppHeader,
  },
  methods: {
    fileUpload(event) {
      const file = event.target.files[0];

      this.imageUrl = URL.createObjectURL(file);
      this.iconFile = file;
    },
    filesUpload(event) {
      if (this.img_urls.length >= 6) {
        this.$toast.show("画像は6枚までです", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
      } else {
        const file = event.target.files[0];

        this.img_urls.push({ url: URL.createObjectURL(file), type: "blob" });
        this.files.push(file);
      }
    },
    removeImgs(i) {
      this.isUploadError = false;
      if (this.img_urls[i].type == "https") {
        this.removeUrls.push(this.img_urls[i].path);
      }
      this.img_urls.splice(i, 1);
      this.files.splice(i, 1);
    },
    async getUserIcon() {
      try {
        const imageRef = storageRef(
          storage,
          `users/icon/${store.uid}/user_icon1.png`,
        );

        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;

        setUserIconUrl(url);
      } catch (error) {
        console.log("---------- user/edit/usericon ---------");
        console.error("Error fetching image URL:", error);
      }
    },
    handleBeforeUnload(event) {
      // eslint-disable-next-line no-unused-vars
      event = null;
      removeUserIconUrl();
    },
    async update() {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };

        // メインアイコン
        if (this.iconFile != null) {
          const compressedFile = await imageCompression(this.iconFile, options);
          const fileRef = storageRef(
            storage,
            `users/icon/${this.user.uid}/user_icon1.png`,
          );
          await uploadBytes(fileRef, compressedFile);

          const docUpdateUsersRef = doc(db, "users", this.user.uid);
          await updateDoc(docUpdateUsersRef, {
            isIconUrl: true,
          });

          this.user.isIconUrl = true;
          setUser(this.user, this.user.uid);

          this.getUserIcon();
        }

        var imagesList = this.user.imageList ? this.user.imageList : [];

        if (this.files.length > 0) {
          const docUpdateImageRef = doc(db, "users", this.user.uid);

          // 画像追加
          for (var i in this.files) {
            var imageId = "";
            const docImageRef = await addDoc(
              collection(db, `users/${this.user.uid}/images`),
              {
                uid: this.user.uid,
              },
            );
            imageId = docImageRef.id;

            imagesList.push(
              `users/images/${imageId}/${compressedSubFile.name}`,
            );

            const compressedSubFile = await imageCompression(
              this.files[i],
              options,
            );
            const fileSubRef = storageRef(
              storage,
              `users/images/${imageId}/${compressedSubFile.name}`,
            );
            await uploadBytes(fileSubRef, compressedSubFile);

            await updateDoc(docUpdateImageRef, {
              imageList: arrayUnion(
                `users/images/${imageId}/${compressedSubFile.name}`,
              ),
            });
          }
        }
        // 画像削除
        if (this.removeUrls.length > 0) {
          const docRemoveImageRef = doc(db, "users", this.user.uid);
          for (var r in this.removeUrls) {
            const index = imagesList.indexOf(this.removeUrls[r]);
            if (index !== -1) {
              imagesList.splice(index, 1); // 配列から削除
            }

            const fileRef = storageRef(storage, this.removeUrls[r]);
            await deleteObject(fileRef);

            await updateDoc(docRemoveImageRef, {
              imageList: arrayRemove(this.removeUrls[r]),
            });
          }
        }
        this.user.imageList = imagesList;
        setUser(this.user, this.user.uid);

        /**
         * iconは基本的にfile変数があれば交換
         * 写真一覧は少し考えないといけないかな
         * 削除したものがある場合は退避させる必要あり
         * そうなると更新時の表示も考えないとあかんか
         *
         * 登録するときにトークン付きでやった方がいいかも?
         */

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push("/user/edit/url/" + this.$route.params.type);
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error uploading file:", error);

        this.$toast.show("投稿時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
    async headerActionClick(data) {
      if (data == "skip_icon") {
        const docUpdateUsersRef = doc(db, "users", this.user.uid);
        await updateDoc(docUpdateUsersRef, {
          isIconImage: false,
        });
        this.$router.push("/user/edit/profile/" + this.$route.params.type);
      }
    },
  },
};
</script>

<style scoped>
.user-edit-detail {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
.img-icon {
  width: 100%;
  height: auto;
}
</style>
