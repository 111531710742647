<template>
  <div class="page">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="text-center" style="padding-top: 70px">
            <span
              class="cherry-bomb-one-regular"
              style="color: #fff; font-size: 40px"
            >
              Kinkyer
            </span>
            <span class="m-plus-medium" style="font-size: 18px; color: #fff"
              >へようこそ</span
            >
          </div>
          <div class="text-center" style="margin: 0 auto; padding-top: 30px">
            <div class="message-window text-start">
              <span
                class="cherry-bomb-one-regular"
                style="color: #784ba0; font-size: 30px"
              >
                Kinkyer</span
              >は性癖に特化した<br />
              「マッチング機能」と「SNS機能」が特徴のアプリです。
              <div style="padding-top: 14px">
                あなたの”好き”がつながる場所で、新しい出会いをお楽しみください。
              </div>
              <br />
              <router-link to="/mypage/tutorial/1">
                <button type="button" class="btn btn-kinkyer m-plus-medium">
                  Kinkyerの機能について
                </button>
              </router-link>
              <div style="padding: 8px 0 0 0">
                <router-link to="/home">
                  <button
                    type="button"
                    class="btn btn-kinkyer-outline-by-color"
                    style="font-size: 12px !important"
                  >
                    スキップしてはじめる
                  </button>
                </router-link>
              </div>
            </div>
            <br />
            <div
              class="item-description text-center"
              style="padding: 10px 40px; color: #fff; font-size: 12px"
            >
              年齢確認が完了するまでお待ちください。<br />
              ※※ また一部機能は利用できます。 ※※
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setNoteTag } from "../../../plugins/store";

export default {
  data() {
    return {};
  },
  async created() {
    setNoteTag("#Home");
  },
  methods: {},
};
</script>

<style scoped>
.page {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: -moz-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: -webkit-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background-color: #784ba0;
}
.message-window {
  margin: 0 auto;
  padding: 15px 18px;
  width: 300px;
  border-radius: 12px;
  background-color: #fff;
  font-size: 15px;
}
</style>
