<template>
  <div class="template-sheet">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 text-center" v-if="false">
            <div>デバッグ用</div>
            <br />
            <div class="d-grid gap-2">
              <!-- <button type="button" class="btn btn-primary" @click="test1">
                isEntry = true
              </button> -->
              <button type="button" class="btn btn-primary" @click="test2">
                生年月日の月日のみ更新
              </button>
              <!-- <button type="button" class="btn btn-primary" @click="test3">
                これじっこう
              </button>
              <button type="button" class="btn btn-primary" @click="test4">
                logout
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../plugins/firebase";
import { removeAll } from "../plugins/store";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  setDoc,
} from "firebase/firestore";

export default {
  data() {
    return {
      uid: "",
      status: {
        "Status.status0": 0,
        "Status.status1": 1,
        "Status.status2": 2,
        "Status.status3": 3,
        "Status.status4": 4,
        "Status.status5": 5,
        "Status.status6": 6,
      },
    };
  },
  methods: {
    async test0() {
      const querySnapshot = await getDocs(collection(db, "users"));

      // 取得したすべてのドキュメントのisValueフィールドを更新
      const updatePromises = querySnapshot.docs.map((document) => {
        return updateDoc(doc(db, "users", document.id), {
          //isEntry: true,
          isIconUrl: true,
          isAgeView: true,
        });
      });

      // すべての更新が完了するのを待つ
      await Promise.all(updatePromises);

      this.$toast.show("おわり", {
        position: "bottom",
        duration: 2000,
      });
    },
    async test1() {
      const querySnapshot = await getDocs(collection(db, "users"));

      const updatePromises = querySnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();
        await setDoc(doc(db, "shareUsers", docSnapshot.id), {
          uid: data.uid == undefined ? null : data.uid,
          name: data.name == undefined ? null : data.name,
          birthday: data.birthday == undefined ? null : data.birthday,
          age: data.age == undefined ? null : data.age,
          sex: data.sex == undefined ? null : data.sex,
          area: data.selectedArea == undefined ? null : data.selectedArea,
          isIconImage: data.isIconImage == undefined ? null : data.isIconImage,
          comment: data.CommentText == undefined ? null : data.CommentText,
          profile: data.ProfileText == undefined ? null : data.ProfileText,
          like: data.LikeText == undefined ? null : data.LikeText,
          ng: data.NGText == undefined ? null : data.NGText,
          weburl: data.weburl == undefined ? null : data.weburl,
          selectedBloods:
            data.selectedBloods == undefined ? null : data.selectedBloods,
          selectedBodyType:
            data.selectedBodyType == undefined ? null : data.selectedBodyType,
          selectedBodyHight:
            data.selectedBodyHight == undefined ? null : data.selectedBodyHight,
          selectedAlcohol:
            data.selectedAlcohol == undefined ? null : data.selectedAlcohol,
          selectedSmoking:
            data.selectedSmoking == undefined ? null : data.selectedSmoking,
          selectedGender:
            data.selectedGender == undefined ? null : data.selectedGender,
          selectedPersonality:
            data.selectedPersonality == undefined
              ? null
              : data.selectedPersonality,
          selectedDirectional:
            data.selectedDirectional == undefined
              ? null
              : data.selectedDirectional,
          selectTag: data.selectedTags == undefined ? null : data.selectedTags,
          sadistic: data.sadistic == undefined ? null : data.sadistic,
          masochist: data.masochist == undefined ? null : data.masochist,
          isTagGreen: data.isTagGreen == undefined ? null : data.isTagGreen,
          isTagYellow: data.isTagYellow == undefined ? null : data.isTagYellow,
          isTagCyan: data.isTagCyan == undefined ? null : data.isTagCyan,
          isTagBlue: data.isTagBlue == undefined ? null : data.isTagBlue,
          isTagPurple: data.isTagPurple == undefined ? null : data.isTagPurple,
          isTagOrange: data.isTagOrange == undefined ? null : data.isTagOrange,
          isTagRed: data.isTagRed == undefined ? null : data.isTagRed,
          question1: data.question1 == undefined ? null : data.question1,
          question2: data.question2 == undefined ? null : data.question2,
          question3: data.question3 == undefined ? null : data.question3,
          question4: data.question4 == undefined ? null : data.question4,
          question5: data.question5 == undefined ? null : data.question5,
          question6: data.question6 == undefined ? null : data.question6,
          question7: data.question7 == undefined ? null : data.question7,
          question8: data.question8 == undefined ? null : data.question8,
          question9: data.question9 == undefined ? null : data.question9,
          question10: data.question10 == undefined ? null : data.question10,
          question11: data.question11 == undefined ? null : data.question11,
          question12: data.question12 == undefined ? null : data.question12,
          question13: data.question13 == undefined ? null : data.question13,
          question14: data.question14 == undefined ? null : data.question14,
          question15: data.question15 == undefined ? null : data.question15,
          question16: data.question16 == undefined ? null : data.question16,
          question64: data.question64 == undefined ? null : data.question64,
          question65: data.question65 == undefined ? null : data.question65,
          question66: data.question66 == undefined ? null : data.question66,
          question67: data.question67 == undefined ? null : data.question67,
          question68: data.question68 == undefined ? null : data.question68,
          question17: data.question17 == undefined ? null : data.question17,
          question18: data.question18 == undefined ? null : data.question18,
          question19: data.question19 == undefined ? null : data.question19,
          question20: data.question20 == undefined ? null : data.question20,
          question21: data.question21 == undefined ? null : data.question21,
          question22: data.question22 == undefined ? null : data.question22,
          question23: data.question23 == undefined ? null : data.question23,
          question24: data.question24 == undefined ? null : data.question24,
          question25: data.question25 == undefined ? null : data.question25,
          question26: data.question26 == undefined ? null : data.question26,
          question27: data.question27 == undefined ? null : data.question27,
          question28: data.question28 == undefined ? null : data.question28,
          question29: data.question29 == undefined ? null : data.question29,
          question30: data.question30 == undefined ? null : data.question30,
          question31: data.question31 == undefined ? null : data.question31,
          question32: data.question32 == undefined ? null : data.question32,
          question33: data.question33 == undefined ? null : data.question33,
          question34: data.question34 == undefined ? null : data.question34,
          question35: data.question35 == undefined ? null : data.question35,
          question36: data.question36 == undefined ? null : data.question36,
          question37: data.question37 == undefined ? null : data.question37,
          question38: data.question38 == undefined ? null : data.question38,
          question39: data.question39 == undefined ? null : data.question39,
          question40: data.question40 == undefined ? null : data.question40,
          question41: data.question41 == undefined ? null : data.question41,
          question42: data.question42 == undefined ? null : data.question42,
          question43: data.question43 == undefined ? null : data.question43,
          question44: data.question44 == undefined ? null : data.question44,
          question45: data.question45 == undefined ? null : data.question45,
          question46: data.question46 == undefined ? null : data.question46,
          question47: data.question47 == undefined ? null : data.question47,
          question48: data.question48 == undefined ? null : data.question48,
          question49: data.question49 == undefined ? null : data.question49,
          question50: data.question50 == undefined ? null : data.question50,
          question51: data.question51 == undefined ? null : data.question51,
          question52: data.question52 == undefined ? null : data.question52,
          question53: data.question53 == undefined ? null : data.question53,
          question54: data.question54 == undefined ? null : data.question54,
          question55: data.question55 == undefined ? null : data.question55,
          question56: data.question56 == undefined ? null : data.question56,
          question57: data.question57 == undefined ? null : data.question57,
          question58: data.question58 == undefined ? null : data.question58,
          question59: data.question59 == undefined ? null : data.question59,
          question60: data.question60 == undefined ? null : data.question60,
          question61: data.question61 == undefined ? null : data.question61,
          question62: data.question62 == undefined ? null : data.question62,
          question63: data.question63 == undefined ? null : data.question63,
          question69: data.question69 == undefined ? null : data.question69,
          question70: data.question70 == undefined ? null : data.question70,
        });
      });

      // すべての更新が完了するのを待つ
      await Promise.all(updatePromises);
      console.log("end");
      this.$toast.show("おわり", {
        position: "bottom",
        duration: 2000,
      });
    },
    calculateAge(birthday) {
      const birthDate = new Date(birthday);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
    async test2() {
      const querySnapshot = await getDocs(collection(db, "users"));
      const updatePromises = querySnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();

        // birthdayフィールドが存在しない場合はスキップ
        if (!data.birthday) return;

        console.log(data.birthday);

        const timestamp = data.birthday; // FirestoreのTimestamp
        const date = timestamp.toDate(); // TimestampをJavaScriptのDateに変換

        const month = String(date.getMonth() + 1).padStart(2, "0"); // 月は0始まりなので+1
        const day = String(date.getDate()).padStart(2, "0");

        // Firestoreの該当ドキュメントにageフィールドを更新
        const docRef = doc(db, "users", docSnapshot.id);
        await updateDoc(docRef, {
          birthdayMMDD: month + day,
        });
      });

      // すべての更新が完了するのを待つ
      await Promise.all(updatePromises);

      this.$toast.show("おわり", {
        position: "bottom",
        duration: 2000,
      });
    },
    async test3() {
      // eslint-disable-next-line no-unused-vars
      const search = {
        age_from: 18,
        age_to: 100,
        sex: null,
        area: [],
        isProfile: false,
        isFetishism: false,
        isBDSM: false,
        isSeiheki: false,
      };
      const querySnapshot = await getDocs(collection(db, "users"));
      const updatePromises = querySnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();

        // birthdayフィールドが存在しない場合はスキップ
        var age = null;
        if (data.birthday) {
          // eslint-disable-next-line no-unused-vars
          age = this.calculateAge(data.birthday.toDate());
        }

        var blockedUID = [];
        if (data.blockUsers) {
          data.blockUsers.forEach((item) => {
            blockedUID.push(item.uid);
          });
        }
        /* 
        await updateDoc(doc(db, "users", docSnapshot.id), {
          blockedUID: blockedUID,
          search: search,
          age: age,
          isIconUrl: true,
          isIconImage: true,
          question1: data.question1 ? this.status[data.question1] : 0,
          question2: data.question2 ? this.status[data.question2] : 0,
          question3: data.question3 ? this.status[data.question3] : 0,
          question4: data.question4 ? this.status[data.question4] : 0,
          question5: data.question5 ? this.status[data.question5] : 0,
          question6: data.question6 ? this.status[data.question6] : 0,
          question7: data.question7 ? this.status[data.question7] : 0,
          question8: data.question8 ? this.status[data.question8] : 0,
          question9: data.question9 ? this.status[data.question9] : 0,
          question10: data.question10 ? this.status[data.question10] : 0,
          question11: data.question11 ? this.status[data.question11] : 0,
          question12: data.question12 ? this.status[data.question12] : 0,
          question13: data.question13 ? this.status[data.question13] : 0,
          question14: data.question14 ? this.status[data.question14] : 0,
          question15: data.question15 ? this.status[data.question15] : 0,
          question16: data.question16 ? this.status[data.question16] : 0,
          question64: data.question64 ? this.status[data.question64] : 0,
          question65: data.question65 ? this.status[data.question65] : 0,
          question66: data.question66 ? this.status[data.question66] : 0,
          question67: data.question67 ? this.status[data.question67] : 0,
          question68: data.question68 ? this.status[data.question68] : 0,
          question17: data.question17 ? this.status[data.question17] : 0,
          question18: data.question18 ? this.status[data.question18] : 0,
          question19: data.question19 ? this.status[data.question19] : 0,
          question20: data.question20 ? this.status[data.question20] : 0,
          question21: data.question21 ? this.status[data.question21] : 0,
          question22: data.question22 ? this.status[data.question22] : 0,
          question23: data.question23 ? this.status[data.question23] : 0,
          question24: data.question24 ? this.status[data.question24] : 0,
          question25: data.question25 ? this.status[data.question25] : 0,
          question26: data.question26 ? this.status[data.question26] : 0,
          question27: data.question27 ? this.status[data.question27] : 0,
          question28: data.question28 ? this.status[data.question28] : 0,
          question29: data.question29 ? this.status[data.question29] : 0,
          question30: data.question30 ? this.status[data.question30] : 0,
          question31: data.question31 ? this.status[data.question31] : 0,
          question32: data.question32 ? this.status[data.question32] : 0,
          question33: data.question33 ? this.status[data.question33] : 0,
          question34: data.question34 ? this.status[data.question34] : 0,
          question35: data.question35 ? this.status[data.question35] : 0,
          question36: data.question36 ? this.status[data.question36] : 0,
          question37: data.question37 ? this.status[data.question37] : 0,
          question38: data.question38 ? this.status[data.question38] : 0,
          question39: data.question39 ? this.status[data.question39] : 0,
          question40: data.question40 ? this.status[data.question40] : 0,
          question41: data.question41 ? this.status[data.question41] : 0,
          question42: data.question42 ? this.status[data.question42] : 0,
          question43: data.question43 ? this.status[data.question43] : 0,
          question44: data.question44 ? this.status[data.question44] : 0,
          question45: data.question45 ? this.status[data.question45] : 0,
          question46: data.question46 ? this.status[data.question46] : 0,
          question47: data.question47 ? this.status[data.question47] : 0,
          question48: data.question48 ? this.status[data.question48] : 0,
          question49: data.question49 ? this.status[data.question49] : 0,
          question50: data.question50 ? this.status[data.question50] : 0,
          question51: data.question51 ? this.status[data.question51] : 0,
          question52: data.question52 ? this.status[data.question52] : 0,
          question53: data.question53 ? this.status[data.question53] : 0,
          question54: data.question54 ? this.status[data.question54] : 0,
          question55: data.question55 ? this.status[data.question55] : 0,
          question56: data.question56 ? this.status[data.question56] : 0,
          question57: data.question57 ? this.status[data.question57] : 0,
          question58: data.question58 ? this.status[data.question58] : 0,
          question59: data.question59 ? this.status[data.question59] : 0,
          question60: data.question60 ? this.status[data.question60] : 0,
          question61: data.question61 ? this.status[data.question61] : 0,
          question62: data.question62 ? this.status[data.question62] : 0,
          question63: data.question63 ? this.status[data.question63] : 0,
          question69: data.question69 ? this.status[data.question69] : 0,
          question70: data.question70 ? this.status[data.question70] : 0,
        }); */
      });
      const queryNotesSnapshot = await getDocs(collection(db, "notes"));
      const updateNotesPromises = queryNotesSnapshot.docs.map(
        async (docSnapshot) => {
          await updateDoc(doc(db, "notes", docSnapshot.id), {
            isRemove: false,
            isProfileShare: false,
            range: "all",
          });
        },
      );

      // すべての更新が完了するのを待つ
      await Promise.all(updatePromises);
      await Promise.all(updateNotesPromises);

      this.$toast.show("おわり", {
        position: "bottom",
        duration: 2000,
      });
    },
    test4() {
      removeAll();
    },
  },
};
</script>

<style scoped>
.template-sheet {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 0px;
}
</style>
