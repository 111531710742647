<template>
  <div class="entry-top">
    <div class="header-back">
      <div class="header-item header-item-size" style="width: 50%">
        <div>
          <a @click="$router.go(-1)" style="cursor: pointer; color: #784ba0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="30px"
              viewBox="0 -960 960 960"
              width="30px"
              fill="currentColor"
            >
              <path
                d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z"
              />
            </svg>
          </a>
        </div>
      </div>
      <div
        class="header-item header-item-size text-end m-plus-medium"
        style="width: 50%; padding-right: 10px"
      >
        <span style="font-size: 13px">ログイン</span>
      </div>
    </div>
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div>page</div>
            <div style="padding-bottom: 10px">
              <button type="button" class="btn btn-kinkyer m-plus-medium">
                次へ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
a {
  font-weight: bold;
  color: #827490 !important;
  text-decoration: underline !important;
}
a:focus {
  font-weight: bold;
  color: #827490 !important;
  text-decoration: underline !important;
}
.entry-top {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}

.page {
  padding-top: 60px;
}
.title {
  padding-top: 20px;
  padding-bottom: 30px;
  font-size: 25px;
  font-weight: bold;
  color: #827490;
}
.subtitle {
  padding: 20px 0;
  font-size: 11px;
  color: #827490;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  width: 100%;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}
.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0.25);
}
.icon {
  /*color: #fff;*/
  font-size: 30px;
}
.header-item {
  float: left;
}
.header-item-size {
  padding-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
}

.header-back {
  color: #784ba0 !important;
}
</style>
