import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Toaster from "@meforma/vue-toaster";
import { VueSpinnersPlugin } from "vue3-spinners";
/**
 * 処理（登録、更新、削除）：VueSpinnerBars
 * 初期、読み込み：VueSpinnerDots
 */

createApp(App)
  .use(vuetify)
  .use(Toaster)
  .use(VueSpinnersPlugin)
  .use(router)
  .mount("#app");
