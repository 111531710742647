<template>
  <div class="entry-top">
    <div class="header-back">
      <div class="header-item header-item-size" style="width: 50%">
        <div>
          <a @click="$router.go(-1)" style="cursor: pointer; color: #784ba0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="30px"
              viewBox="0 -960 960 960"
              width="30px"
              fill="currentColor"
            >
              <path
                d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z"
              />
            </svg>
          </a>
        </div>
      </div>
      <div
        class="header-item header-item-size text-end m-plus-medium"
        style="width: 50%; padding-right: 10px"
      >
        <span style="font-size: 13px" @click="pageAuth">ログイン</span>
      </div>
    </div>
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="text-center title">新規登録</div>
            <div class="text-center">
              <img
                :src="require('@/assets/images/entry_lock.webp')"
                style="width: 80px; height: auto"
              />
            </div>
            <div class="text-center subtitle">
              認証を行なってもSNSや外部に<br />
              情報が公開されることはありません
            </div>
            <br />
            <div style="width: 190px; margin: auto">
              <div class="form-check" style="padding-bottom: 6px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  style="margin-top: 7px"
                  v-bind:checked="isCheckTerms"
                  v-on:change="isCheckTerms = !isCheckTerms"
                />
                <label class="form-check-label">
                  <a href="https://kinkyer.jp/terms/" target="_blank"
                    >利用規約</a
                  >
                </label>
              </div>
              <div class="form-check" style="padding-bottom: 6px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  style="margin-top: 7px"
                  v-bind:checked="isCheckPrivacypolicy"
                  v-on:change="isCheckPrivacypolicy = !isCheckPrivacypolicy"
                />
                <label class="form-check-label">
                  <a href="https://kinkyer.jp/privacypolicy/" target="_blank"
                    >プライバシーポリシー</a
                  >
                </label>
              </div>
              <div class="form-check" style="padding-bottom: 6px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  style="margin-top: 7px"
                  v-bind:checked="isCheckOver18"
                  v-on:change="isCheckOver18 = !isCheckOver18"
                />
                <label
                  class="form-check-label"
                  style="font-weight: bold; color: #827490"
                >
                  18歳以上です
                </label>
              </div>
            </div>
            <br />
            <div style="width: 250px; margin: auto">
              <div class="text-center">
                <div style="padding-bottom: 10px">
                  <button
                    type="button"
                    class="btn btn-kinkyer m-plus-medium"
                    @click="setTwitter"
                  >
                    X(Twitter)で続ける
                  </button>
                </div>
                <div style="padding-bottom: 10px">
                  <button
                    type="button"
                    class="btn btn-kinkyer m-plus-medium"
                    @click="setPassword"
                  >
                    メールアドレスで続ける
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="isLoad"
        style="
          position: fixed;
          top: 0px;
          width: 100%;
          height: 100%;
          background-color: rgb(0 0 0 / 50%);
          margin: 0 auto;
          padding-top: 200px;
          text-align: center;
          z-index: 999;
        "
      >
        <div style="position: absolute; z-index: 9999; width: 100%">
          <VueSpinnerOval size="50" color="#fff" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db, auth } from "../../plugins/firebase";
import { TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { setUser } from "../../plugins/store";

export default {
  data() {
    return {
      isLoad: false,
      isCheckTerms: false,
      isCheckPrivacypolicy: false,
      isCheckOver18: false,
    };
  },
  methods: {
    setTwitter() {
      if (!this.isCheckTerms) {
        this.$toast.show("利用規約を確認してください", {
          position: "bottom",
          duration: 2000,
        });
      } else if (!this.isCheckPrivacypolicy) {
        this.$toast.show("プライバシーポリシーを確認してください", {
          position: "bottom",
          duration: 2000,
        });
      } else if (!this.isCheckOver18) {
        this.$toast.show("18歳未満のご利用はできません", {
          position: "bottom",
          duration: 2000,
        });
      } else {
        this.loginTwitter();
      }
    },
    setPassword() {
      if (!this.isCheckTerms) {
        this.$toast.show("利用規約を確認してください", {
          position: "bottom",
          duration: 2000,
        });
      } else if (!this.isCheckPrivacypolicy) {
        this.$toast.show("プライバシーポリシーを確認してください", {
          position: "bottom",
          duration: 2000,
        });
      } else if (!this.isCheckOver18) {
        this.$toast.show("18歳未満のご利用はできません", {
          position: "bottom",
          duration: 2000,
        });
      } else {
        this.$router.push("/entry/password");
      }
    },
    loginTwitter() {
      this.isLoad = true;
      const provider = new TwitterAuthProvider();

      signInWithPopup(auth, provider)
        .then(async (result) => {
          // The signed-in user info.
          const user = result.user;
          if (user) {
            await setDoc(doc(db, "users", user.uid), {
              uid: user.uid,
              token: "",
              isEntry: false,
              isRegistration: false,
              isIdentification: false,
              isSubscription: false,
              isViewSTDtest: false,
              isViewLicense: false,
              isViewExchange: false,
              isAuthMail: true,
              isAuthTwitter: false,
              isPreUser: false,
              createdAt: new Date(),
              loginAt: new Date(),
              recommendCount: 3,
              lastRecommendAt: new Date(),
              maxGoodCount: 10,
              lastGoodAt: new Date(),
              isReplyGood: true,
              lastReplyGoodAt: new Date(),
              isDM: true,
              groupChatEntryCount: 3,
              groupChatCreateCount: 10,
              lastGroupChatEntryAt: new Date(),
              lastGroupChatCreateAt: new Date(),
              isAgeView: true,
              isSensitiveImage: true,
            });

            await setDoc(doc(db, "authLink", user.uid), {
              uid: user.uid,
              type: "twitter",
            });

            const docRef = doc(db, "authLink", user.uid);
            const docSnap = await getDoc(docRef);

            const docUserRef = doc(db, "users", docSnap.data().uid);
            const docUserSnap = await getDoc(docUserRef);

            setUser(docUserSnap.data(), docSnap.data().uid);
            this.user = docUserSnap.data();
            this.uid = docSnap.data().uid;

            this.$router.push("/user/edit/name/entry");

            this.isLoad = false;
          } else {
            this.isLoad = false;
            // 失敗時処理
            this.$toast.show("有効なアカウントではありません", {
              type: "error",
              position: "bottom",
              duration: 2000,
            });
          }
        })
        .catch(() => {
          this.isLoad = false;
          // 失敗時処理
          this.$toast.show("有効なアカウントではありません", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
        });
    },
    pageAuth() {
      this.$router.push("/auth");
    },
  },
};
</script>

<style scoped>
a {
  font-weight: bold;
  color: #827490 !important;
  text-decoration: underline !important;
}
a:focus {
  font-weight: bold;
  color: #827490 !important;
  text-decoration: underline !important;
}
.entry-top {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}

.page {
  padding-top: 60px;
}
.title {
  padding-top: 20px;
  padding-bottom: 30px;
  font-size: 25px;
  font-weight: bold;
  color: #827490;
}
.subtitle {
  padding: 20px 0;
  font-size: 11px;
  color: #827490;
}
.icon {
  /*color: #fff;*/
  font-size: 30px;
}
.header-item {
  float: left;
}
.header-item-size {
  padding-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
}

.header-back {
  color: #784ba0 !important;
}
</style>
