<template>
  <div
    style="
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    "
    class="text-start"
  >
    <div style="display: flex">
      <div v-if="imageUrl">
        <img :src="imageUrl" />
      </div>
      <div v-else>
        <img :src="require('@/assets/icon_user.webp')" />
      </div>
      <div style="padding-left: 10px; padding-top: 7px">
        {{ user.name }}
      </div>
    </div>
    <div
      class="text-end"
      v-if="memberList.includes(this.user.uid)"
      style="padding-top: 8px; font-size: 14px; color: #bbb"
    >
      追加済み
    </div>
    <div
      v-else
      class="text-end"
      style="padding-top: 8px; font-size: 14px"
      @click="headerActionClick(user.uid)"
    >
      追加する
    </div>
  </div>
</template>

<script>
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { storage } from "../../plugins/firebase";
import { store } from "../../plugins/store";

export default {
  data() {
    return {
      loginUser: {},
      user: {},
      imageUrl: null,
      memberList: [],
    };
  },
  props: ["item", "member"],
  async mounted() {
    this.user = this.item;
    this.loginUser = store.user;
    this.memberList = this.member == undefined ? [] : this.member;

    this.getUserIcon();
  },
  methods: {
    headerActionClick(t) {
      this.$emit("header-action-click", t);
    },
    async getUserIcon() {
      const imageRef = storageRef(
        storage,
        `users/icon/${this.user.uid}/user_icon1.png`,
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;
      } catch (error) {
        console.log("---------- components/timeline ---------");
        console.error("Error fetching image URL:", error);
      }
    },
    async linkUser() {
      this.$router.push("/u/" + this.user.accountId);
    },
  },
};
</script>

<style scoped>
img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.list-sub-tag {
  font-size: 10px;
  white-space: nowrap;
}
</style>
