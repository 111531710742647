<template>
  <div class="footer d-md-none">
    <div class="item-box text-center">
      <router-link to="/home" class="link" @click="resetTab">
        <div :style="footerIndex == 1 ? 'color: #784ba0' : 'color: #bbb'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="25px"
            viewBox="0 -960 960 960"
            width="25px"
            fill="currentColor"
          >
            <path
              d="M240-200h120v-200q0-17 11.5-28.5T400-440h160q17 0 28.5 11.5T600-400v200h120v-360L480-740 240-560v360Zm-80 0v-360q0-19 8.5-36t23.5-28l240-180q21-16 48-16t48 16l240 180q15 11 23.5 28t8.5 36v360q0 33-23.5 56.5T720-120H560q-17 0-28.5-11.5T520-160v-200h-80v200q0 17-11.5 28.5T400-120H240q-33 0-56.5-23.5T160-200Zm320-270Z"
            />
          </svg>
          <div style="font-size: 10px">ホーム</div>
        </div>
      </router-link>
    </div>
    <div class="item-box text-center">
      <router-link to="/matching" class="link">
        <div :style="footerIndex == 2 ? 'color: #784ba0' : 'color: #bbb'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="25px"
            viewBox="0 -960 960 960"
            width="25px"
            fill="currentColor"
          >
            <path
              d="m558-144 238-74q-5-9-14.5-15.5T760-240H558q-27 0-43-2t-33-8l-57-19q-16-5-23-20t-2-31q5-16 19.5-23.5T450-346l42 14q17 5 38.5 8t58.5 4h11q0-11-6.5-21T578-354l-234-86h-64v220l278 76Zm-21 78-257-72q-8 26-31.5 42T200-80h-80q-33 0-56.5-23.5T40-160v-280q0-33 23.5-56.5T120-520h224q7 0 14 1.5t13 3.5l235 87q33 12 53.5 42t20.5 66h80q50 0 85 33t35 87q0 22-11.5 34.5T833-145L583-67q-11 4-23 4t-23-3Zm-417-94h80v-280h-80v280Zm520-312q-15 0-29.5-5.5T584-494L474-602q-31-30-52.5-66.5T400-748q0-55 38.5-93.5T532-880q32 0 60 13.5t48 36.5q20-23 48-36.5t60-13.5q55 0 93.5 38.5T880-748q0 43-21 79.5T807-602L696-494q-12 11-26.5 16.5T640-472Zm0-80 109-107q19-19 35-40.5t16-48.5q0-22-15-37t-37-15q-14 0-26.5 5.5T700-778l-29 35q-12 14-31 14t-31-14l-29-35q-9-11-21.5-16.5T532-800q-22 0-37 15t-15 37q0 27 16 48.5t35 40.5l109 107Zm0-154Z"
            />
          </svg>
          <div style="font-size: 10px">マッチング</div>
        </div>
      </router-link>
    </div>
    <div class="item-box text-center">
      <router-link to="/event" class="link">
        <div :style="footerIndex == 3 ? 'color: #784ba0' : 'color: #bbb'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="25px"
            viewBox="0 -960 960 960"
            width="25px"
            fill="currentColor"
          >
            <path
              d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-40q0-17 11.5-28.5T280-880q17 0 28.5 11.5T320-840v40h320v-40q0-17 11.5-28.5T680-880q17 0 28.5 11.5T720-840v40h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z"
            />
          </svg>
          <div style="font-size: 10px">イベント</div>
        </div>
      </router-link>
    </div>
    <!-- <div class="item-box text-center">
      <router-link to="/week" class="link">
        <div :style="footerIndex == 4 ? 'color: #784ba0' : 'color: #bbb'">
          <span class="material-symbols-rounded icon" style="font-size: 25px">
            local_cafe
          </span>
          <div style="font-size: 10px">おでかけ</div>
        </div>
      </router-link>
    </div> -->
    <div class="item-box text-center">
      <router-link to="/like/list" class="link">
        <div :style="footerIndex == 5 ? 'color: #784ba0' : 'color: #bbb'">
          <div style="position: relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="25px"
              viewBox="0 -960 960 960"
              width="25px"
              fill="currentColor"
            >
              <path
                d="M840-640q32 0 56 24t24 56v80q0 7-2 15t-4 15L794-168q-9 20-30 34t-44 14H280v-520l240-238q15-15 35.5-17.5T595-888q19 10 28 28t4 37l-45 183h258Zm-480 34v406h360l120-280v-80H480l54-220-174 174ZM160-120q-33 0-56.5-23.5T80-200v-360q0-33 23.5-56.5T160-640h120v80H160v360h120v80H160Zm200-80v-406 406Z"
              />
            </svg>
            <span
              v-if="goodCount > 0"
              style="
                position: absolute;
                border-radius: 4.5px;
                height: 9px;
                min-width: 0;
                padding: 0;
                width: 9px;
                background: red;
              "
            ></span>
          </div>
          <div style="font-size: 10px">いいね</div>
        </div>
      </router-link>
    </div>
    <div class="item-box text-center" @click="messageCount = 0">
      <router-link to="/message/list" class="link">
        <div :style="footerIndex == 6 ? 'color: #784ba0' : 'color: #bbb'">
          <div style="position: relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="25px"
              viewBox="0 -960 960 960"
              width="25px"
              fill="currentColor"
            >
              <path
                d="m240-240-92 92q-19 19-43.5 8.5T80-177v-623q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240Zm-34-80h594v-480H160v525l46-45Zm-46 0v-480 480Z"
              />
            </svg>
            <span
              v-if="messageCount > 0"
              style="
                position: absolute;
                border-radius: 4.5px;
                height: 9px;
                min-width: 0;
                padding: 0;
                width: 9px;
                background: red;
              "
            ></span>
          </div>
          <div style="font-size: 10px">メッセージ</div>
        </div>
      </router-link>
    </div>
  </div>
  <div class="d-none d-md-block">
    <div
      style="
        position: fixed;
        top: 0;
        width: 100px;
        height: 100%;
        margin: 0 auto;
        text-align: center;
        display: flex !important;
        align-items: center;
        z-index: 9;
      "
    >
      <div style="position: absolute; z-index: 9999; padding-left: 15px">
        <div
          style="
            background-color: #f3f3f344;
            padding: 10px 7px;
            border: solid 1px #f3f3f3;
            border-radius: 50px;
          "
        >
          <div class="text-center" style="padding: 13px 0">
            <router-link to="/home" class="link">
              <div :style="footerIndex == 1 ? 'color: #784ba0' : 'color: #bbb'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill="currentColor"
                >
                  <path
                    d="M240-200h120v-200q0-17 11.5-28.5T400-440h160q17 0 28.5 11.5T600-400v200h120v-360L480-740 240-560v360Zm-80 0v-360q0-19 8.5-36t23.5-28l240-180q21-16 48-16t48 16l240 180q15 11 23.5 28t8.5 36v360q0 33-23.5 56.5T720-120H560q-17 0-28.5-11.5T520-160v-200h-80v200q0 17-11.5 28.5T400-120H240q-33 0-56.5-23.5T160-200Zm320-270Z"
                  />
                </svg>
                <div style="font-size: 10px">ホーム</div>
              </div>
            </router-link>
          </div>
          <div class="text-center" style="padding: 13px 0">
            <router-link to="/matching" class="link">
              <div :style="footerIndex == 2 ? 'color: #784ba0' : 'color: #bbb'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill="currentColor"
                >
                  <path
                    d="m558-144 238-74q-5-9-14.5-15.5T760-240H558q-27 0-43-2t-33-8l-57-19q-16-5-23-20t-2-31q5-16 19.5-23.5T450-346l42 14q17 5 38.5 8t58.5 4h11q0-11-6.5-21T578-354l-234-86h-64v220l278 76Zm-21 78-257-72q-8 26-31.5 42T200-80h-80q-33 0-56.5-23.5T40-160v-280q0-33 23.5-56.5T120-520h224q7 0 14 1.5t13 3.5l235 87q33 12 53.5 42t20.5 66h80q50 0 85 33t35 87q0 22-11.5 34.5T833-145L583-67q-11 4-23 4t-23-3Zm-417-94h80v-280h-80v280Zm520-312q-15 0-29.5-5.5T584-494L474-602q-31-30-52.5-66.5T400-748q0-55 38.5-93.5T532-880q32 0 60 13.5t48 36.5q20-23 48-36.5t60-13.5q55 0 93.5 38.5T880-748q0 43-21 79.5T807-602L696-494q-12 11-26.5 16.5T640-472Zm0-80 109-107q19-19 35-40.5t16-48.5q0-22-15-37t-37-15q-14 0-26.5 5.5T700-778l-29 35q-12 14-31 14t-31-14l-29-35q-9-11-21.5-16.5T532-800q-22 0-37 15t-15 37q0 27 16 48.5t35 40.5l109 107Zm0-154Z"
                  />
                </svg>
                <div style="font-size: 10px">マッチング</div>
              </div>
            </router-link>
          </div>
          <div class="text-center" style="padding: 13px 0">
            <router-link to="/event" class="link">
              <div :style="footerIndex == 3 ? 'color: #784ba0' : 'color: #bbb'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill="currentColor"
                >
                  <path
                    d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-40q0-17 11.5-28.5T280-880q17 0 28.5 11.5T320-840v40h320v-40q0-17 11.5-28.5T680-880q17 0 28.5 11.5T720-840v40h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z"
                  />
                </svg>
                <div style="font-size: 10px">イベント</div>
              </div>
            </router-link>
          </div>
          <!-- <div class="text-center" style="padding: 13px 0">
            <router-link to="/week" class="link">
              <div :style="footerIndex == 4 ? 'color: #784ba0' : 'color: #bbb'">
                <span
                  class="material-symbols-rounded icon"
                  style="font-size: 30px"
                >
                  local_cafe
                </span>
                <div style="font-size: 10px">おでかけ</div>
              </div>
            </router-link>
          </div> -->
          <div class="text-center" style="padding: 13px 0">
            <router-link to="/like/list" class="link">
              <div :style="footerIndex == 5 ? 'color: #784ba0' : 'color: #bbb'">
                <div style="position: relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="30px"
                    viewBox="0 -960 960 960"
                    width="30px"
                    fill="currentColor"
                  >
                    <path
                      d="M840-640q32 0 56 24t24 56v80q0 7-2 15t-4 15L794-168q-9 20-30 34t-44 14H280v-520l240-238q15-15 35.5-17.5T595-888q19 10 28 28t4 37l-45 183h258Zm-480 34v406h360l120-280v-80H480l54-220-174 174ZM160-120q-33 0-56.5-23.5T80-200v-360q0-33 23.5-56.5T160-640h120v80H160v360h120v80H160Zm200-80v-406 406Z"
                    />
                  </svg>
                  <span
                    v-if="goodCount > 0"
                    style="
                      position: absolute;
                      border-radius: 4.5px;
                      height: 9px;
                      min-width: 0;
                      padding: 0;
                      width: 9px;
                      background: red;
                    "
                  ></span>
                </div>
                <div style="font-size: 10px">いいね</div>
              </div>
            </router-link>
          </div>
          <div class="text-center" style="padding: 13px 0">
            <router-link to="/message/list" class="link">
              <div :style="footerIndex == 6 ? 'color: #784ba0' : 'color: #bbb'">
                <div style="position: relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="30px"
                    viewBox="0 -960 960 960"
                    width="30px"
                    fill="currentColor"
                  >
                    <path
                      d="m240-240-92 92q-19 19-43.5 8.5T80-177v-623q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240Zm-34-80h594v-480H160v525l46-45Zm-46 0v-480 480Z"
                    />
                  </svg>
                  <span
                    v-if="messageCount > 0"
                    style="
                      position: absolute;
                      border-radius: 4.5px;
                      height: 9px;
                      min-width: 0;
                      padding: 0;
                      width: 9px;
                      background: red;
                    "
                  ></span>
                </div>
                <div style="font-size: 10px">メッセージ</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import home from "@/assets/icons/footer/home.svg";
import { setNoteTag, store } from "./../plugins/store";
import { db } from "./../plugins/firebase";
import { doc, onSnapshot } from "firebase/firestore";

export default {
  data() {
    return {
      footerIndex: "",
      home,
      messageCount: 0,
      goodCount: 0,
      unsubscribe: null,
    };
  },
  props: ["index"],
  created() {
    this.footerIndex = this.index;
  },
  mounted() {
    const userRef = doc(db, "users", store.uid);
    this.unsubscribe = onSnapshot(userRef, (docSnap) => {
      if (docSnap.exists()) {
        this.user = docSnap.data();
        const isGood =
          this.user.isGoodBadges == undefined ? true : this.user.isGoodBadges;
        this.goodCount = isGood ? 0 : 1;
        const isMessage =
          this.user.isChatBadges == undefined ? true : this.user.isChatBadges;
        this.messageCount = isMessage ? 0 : 1;
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    resetTab() {
      setNoteTag("#Home");
    },
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10000;
  background-color: #fff;
  border-top: solid 1px #ccc;
  height: 80px;
}
.item-box {
  /* width: 16.6666%; */
  width: 20%;
  float: left;
  padding-top: 8px;
}

.link:hover {
  text-decoration: none;
}
</style>
