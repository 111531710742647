<template>
  <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
  <div class="user-edit-profile" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div style="padding: 0 8px">
              <div style="padding-bottom: 15px" v-show="isEntry">
                <div
                  class="progress"
                  role="progressbar"
                  aria-label="Basic example"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    class="progress-bar"
                    :style="
                      'background-color: #784ba0 !important; width: ' +
                      count +
                      '%'
                    "
                  ></div>
                </div>
              </div>
              <div
                style="
                  padding-top: 10px;
                  padding-bottom: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  color: #827490;
                "
              >
                ジェンダー&nbsp;
                <svg
                  ref="tooltipIcons"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16px"
                  viewBox="0 -960 960 960"
                  width="16px"
                  fill="currentColor"
                  @click="myBottomSheet = !myBottomSheet"
                >
                  <path
                    d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
                  />
                </svg>
              </div>
              <div
                style="
                  padding: 10px 0px 20px 0;
                  font-size: 12px;
                  font-weight: 400;
                "
              >
                性自認、恋愛感情、どんな性的魅力を感じるか、など宜しければお答えください
              </div>
              <div class="item-box">
                <div style="padding-top: 5px">
                  <button
                    v-for="(item, i) in this.gender"
                    :key="i"
                    type="button"
                    style="
                      margin-right: 5px;
                      margin-bottom: 5px;
                      width: auto !important;
                      border-radius: 30px !important;
                    "
                    :class="
                      isSelectedGender(item)
                        ? 'btn btn-kinkyer m-plus-medium'
                        : 'btn btn-kinkyer-outline-by-color m-plus-medium'
                    "
                    @click="clickSelectedGender(item)"
                  >
                    {{ item }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 75px;
      padding: 5px 8px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="((isEdit = true), update())"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
  <v-bottom-sheet v-model="myBottomSheet">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8" style="padding: 0">
          <v-sheet
            class="text-center"
            height="450px"
            style="border-radius: 15px 15px 0 0"
          >
            <div style="padding: 0px; color: #ccc" class="text-center">
              <svg
                ref="tooltipIcons"
                xmlns="http://www.w3.org/2000/svg"
                height="40px"
                viewBox="0 -960 960 960"
                width="40px"
                fill="currentColor"
                @click="myBottomSheet = !myBottomSheet"
              >
                <path d="M200-440v-80h560v80H200Z" />
              </svg>
            </div>
            <div style="height: 400px; padding: 0 15px">
              <div>
                <div style="padding-bottom: 5px">ジェンダーについて</div>
                <div
                  style="overflow-y: scroll; padding: 5px; height: 380px"
                  class="text-start"
                >
                  <div style="font-size: 14px; font-weight: bold">異性愛</div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    自分とは異なる性別の相手に恋愛感情や性的関心を抱くセクシュアリティ。一般的に男性が女性、女性が男性を好む形。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">同性愛</div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    自分と同じ性別の相手に恋愛感情や性的関心を抱くセクシュアリティ。男性同士・女性同士の関係を含む。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">
                    バイセクシャル
                  </div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    男女どちらにも恋愛感情や性的関心を抱くセクシュアリティ。性別に関係なく人を愛することができる。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">
                    アセクシャル
                  </div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    他者に対して性的欲求や関心を抱かない、または極めて少ないセクシュアリティ。恋愛感情はある場合もある。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">
                    アロマンティック
                  </div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    他者に対して恋愛感情を抱かない、または極めて稀にしか抱かないセクシュアリティ。性的感情はある場合もある。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">
                    モノガミー
                  </div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    一対一の関係性を望み、恋愛や性的関係において特定の相手一人と深い絆を築く価値観。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">
                    ポリアモリー
                  </div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    複数の人と同時に恋愛関係を築くことを認める価値観。誠実で合意に基づく複数愛を前提とする。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">
                    クエスチョニング
                  </div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    自分の性別や性的指向について明確に定まっておらず、探究・模索している状態を指す。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">
                    Xジェンダー
                  </div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    男性でも女性でもない、またはその両方を含むような性別を自認するジェンダー。中性・無性など多様。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">FtM</div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    出生時に女性と割り当てられたが、男性としての性自認を持つ人。トランスジェンダー男性とも呼ばれる。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">FtX</div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    出生時に女性と割り当てられたが、男性でも女性でもない、あるいは中性・Xジェンダーを自認する人。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">MtF</div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    出生時に男性と割り当てられたが、女性としての性自認を持つ人。トランスジェンダー女性とも呼ばれる。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">MtX</div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    出生時に男性と割り当てられたが、男性でも女性でもない、あるいは中性・Xジェンダーを自認する人。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">女装子</div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    主に男性が女性の服装や容姿を楽しむスタイル。性自認が男性のまま、女性的な外見を表現することも多い。
                  </div>
                  <div style="font-size: 14px; font-weight: bold">男の娘</div>
                  <div style="font-size: 14px; padding: 3px 8px 10px 8px">
                    見た目が女性のように見えるが性別は男性であるキャラクターや人。女装との違いは“女性らしさ”の強調。
                  </div>
                </div>
              </div>
            </div>
          </v-sheet>
        </div>
      </div>
    </div>
  </v-bottom-sheet>
</template>

<script>
import AppHeader from "@/components/HeaderView.vue";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc, setDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      isEdit: false,
      isEntry: false,
      user: {},
      btnName: "",
      count: 0,
      myBottomSheet: false,
      selectedGender: [],
      gender: [
        "異性愛",
        "同性愛",
        "バイセクシャル",
        "アセクシャル",
        "アロマンティック",
        "モノガミー",
        "ポリアモリー",
        "クエスチョニング",
        "Xジェンダー",
        "FtM",
        "FtX",
        "MtF",
        "MtX",
        "女装子",
        "男の娘",
      ],
    };
  },
  async created() {
    this.count = 3.846 * 12;
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";
    this.user = store.user;
  },
  mounted() {
    this.selectedGender =
      this.user.selectedGender == undefined ? [] : this.user.selectedGender;
  },
  components: {
    AppHeader,
  },
  methods: {
    async update() {
      try {
        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        await updateDoc(docUpdateUsersRef, {
          selectedGender: this.selectedGender,
        });
        await setDoc(doc(db, "shareUsers", this.user.uid), {
          selectedGender: this.selectedGender,
        });

        this.user.selectedGender = this.selectedGender;

        setUser(this.user, this.user.uid);

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push(
            "/user/edit/personality/" + this.$route.params.type,
          );
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
    isSelectedGender(value) {
      return this.selectedGender.includes(value);
    },
    clickSelectedGender(value) {
      if (this.isSelectedGender(value)) {
        const index = this.selectedGender.indexOf(value);
        if (index !== -1) {
          this.selectedGender.splice(index, 1); // 配列から削除
        }
      } else {
        this.selectedGender.push(value);
      }
    },
  },
};
</script>

<style scoped>
.user-edit-profile {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
</style>
