<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="false" :is_back="true" title="" type="none" />
    <div class="sheet">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <div style="padding: 15px 15px">
                <div
                  style="
                    padding: 10px 20px;
                    font-size: 12px;
                    font-weight: 400;
                    background-color: rgb(244, 245, 247);
                    border-radius: 6px;
                  "
                >
                  投稿内容を公開するグループの設定を行います<br />
                  あなたの投稿を公開するユーザーをフォロー中、フォローされたユーザーの中から選択してください
                </div>
              </div>
              <div class="subtitle-box">
                <div class="subtitle">フォローリスト</div>
              </div>
              <div>
                <div v-for="(item, i) in folowItems" :key="i" class="item">
                  <div v-if="isCreated">
                    <div style="display: flex">
                      <div
                        style="
                          padding-left: 10px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="selectedUid"
                          :value="item.data.uid"
                        />
                      </div>
                      <div>
                        <ListView :item="item.data" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="subtitle-box">
                <div class="subtitle">フォロワーリスト</div>
              </div>
              <div>
                <div v-for="(item, i) in folowerItems" :key="i" class="item">
                  <div v-if="isCreated">
                    <div style="display: flex">
                      <div
                        style="
                          padding-left: 10px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="selectedUid"
                          :value="item.data.uid"
                        />
                      </div>
                      <div>
                        <ListView :item="item.data" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 65px;
        padding: 5px 20px;
      "
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8" style="height: 65px; background-color: #fff">
            <button
              type="button"
              style="width: 100%; height: 40px"
              class="btn btn-kinkyer m-plus-medium"
              @click="((isEdit = true), update())"
            >
              投稿グループを保存する
            </button>
          </div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import ListView from "@/components/user/ListView.vue";
import { db } from "../../plugins/firebase";
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { store, setUser } from "../../plugins/store";

export default {
  data() {
    return {
      user: {},
      folowItems: [],
      folowerItems: [],
      isCreated: false,
      selectedUid: [],
      isEdit: true,
    };
  },
  async created() {
    this.user = store.user;

    const docUserRef = doc(db, "users", this.user.uid);
    const docUserSnap = await getDoc(docUserRef);
    setUser(docUserSnap.data(), this.user.uid);

    this.selectedUid = this.user.noteGroupUID;
    try {
      const qFolow = query(
        collection(db, "users"),
        where("followeredUID", "array-contains", this.user.uid),
        orderBy("loginAt", "desc"),
      );

      const querySnapshotFolow = await getDocs(qFolow);

      this.folowItems = querySnapshotFolow.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      const qFolower = query(
        collection(db, "users"),
        where("followerUID", "array-contains", this.user.uid),
        orderBy("loginAt", "desc"),
      );

      const querySnapshotFolower = await getDocs(qFolower);

      this.folowerItems = querySnapshotFolower.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
    } catch (error) {
      console.error("データの取得に失敗しました:", error);
    } finally {
      this.isCreated = true;
    }
  },
  components: {
    AppHeader,
    ListView,
    VPullToRefresh,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async update() {
      try {
        this.isEdit = false;

        const docUpdateUsersRef = doc(db, "users", this.user.uid);
        await updateDoc(docUpdateUsersRef, {
          noteGroupUID: this.selectedUid,
        });
        this.user.noteGroupUID = this.selectedUid;

        setUser(this.user, this.user.uid);
      } catch (error) {
        this.isEdit = true;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      } finally {
        this.isEdit = true;

        this.$toast.show("保存完了しました", {
          position: "bottom",
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style scoped>
.sheet {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 60px;
  padding-bottom: 60px;
}
.subtitle-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 15px;
  background-color: #efefef;
}
.subtitle {
  width: calc(100% / 2);
  font-size: 12px;
}
</style>
