import { createRouter, createWebHistory } from "vue-router";

import TopView from "../views/TopView.vue";

// デバッグ
import Debug from "../views/DebugView.vue";
import DebugImage from "../views/DebugImageView.vue";

// Entry
import EntryTop from "../views/entry/TopView.vue";
import PasswordView from "../views/entry/PasswordView.vue";

// Auth
import AuthTop from "../views/auth/TopView.vue";
import AuthConf from "../views/auth/ConfView.vue";

// Home
import HomeTimeline from "../views/home/TimelineView.vue";
import HomeTimelineDetail from "../views/home/TimelineDetail.vue";
import HomeTimelineEdit from "../views/home/TimelineEdit.vue";
import HomeTimelineReply from "../views/home/TimelineReply.vue";
import HomeTimelineHistory from "../views/home/TimelineHistory.vue";
import HomeTimelineByUserShow from "../views/home/TimelineByUserShowView.vue";

// mypage
import MypageIndex from "../views/mypage/IndexView.vue";
import MypageFollow from "../views/mypage/followList.vue";
import MypageBookmark from "../views/mypage/bookmarkList.vue";
import MypageGroup from "../views/mypage/groupList.vue";
import MypageEventList from "../views/mypage/event/ListView.vue";
import MypageEventEdit from "../views/mypage/event/EditView.vue";
import MypageWeekSetting from "../views/mypage/week/SettingView.vue";

import Withdrawal from "../views/mypage/withdrawalView.vue";
import Subscription from "../views/mypage/SubscriptionView.vue";
import PlanCancel from "../views/mypage/PlanCancelView.vue";

import BlockUserList from "../views/mypage/blockUserList.vue";
import ReportUserList from "../views/mypage/reportUserList.vue";

import Tutorial1 from "../views/mypage/tutorial/Page1View.vue";
import Tutorial2 from "../views/mypage/tutorial/Page2View.vue";
import Tutorial3 from "../views/mypage/tutorial/Page3View.vue";
import Tutorial4 from "../views/mypage/tutorial/Page4View.vue";

// mypage/setting
import MypageSettingIdentification from "../views/mypage/setting/userIdentification.vue";
import MypageSettingCompletion from "../views/mypage/setting/entryCompletion.vue";
import MypageSettingPWA from "../views/mypage/setting/slidePWA.vue";

// matching
import MatchingIndex from "../views/matching/indexView.vue";

// event
import EventIndex from "../views/event/IndexView.vue";
import EventBookmark from "../views/event/BookmarkView.vue";
import EventDetail from "../views/event/DetailView.vue";

// week
import WeekIndex from "../views/week/indexView.vue";

// like
import LikeList from "../views/like/ListView.vue";

// message
import MessageList from "../views/message/ListView.vue";
import MessagePerson from "../views/message/PersonView.vue";
import MessageAppea from "../views/message/AppealView.vue";
import MessageGroup from "../views/message/GroupView.vue";
import MessageGroupSetting from "../views/message/GroupSettingView.vue";

// user
import UserDetail from "../views/user/DetailView.vue";
import UserPreview from "../views/user/PreView.vue";
// user/edit
import UserEditInfo from "../views/user/edit/UserInfo.vue";
import UserEditIcon from "../views/user/edit/UserIcon.vue";
import UserEditProfile from "../views/user/edit/UserProfile.vue";
import UserEditDetail from "../views/user/edit/UserDetail.vue";
import UserEditFetishism from "../views/user/edit/UserFetishism.vue";
import UserEditBDSM from "../views/user/edit/UserBDSM.vue";
import UserEditSeiheki from "../views/user/edit/UserSeiheki.vue";
import UserEditKaiwai from "../views/user/edit/UserKaiwai.vue";

import UserEditName from "../views/user/edit/UserNameView.vue";
import UserEditSex from "../views/user/edit/UserSexView.vue";
import UserEditBirthday from "../views/user/edit/UserBirthdayView.vue";
import UserEditAddress from "../views/user/edit/UserAddressView.vue";
import UserEditUrl from "../views/user/edit/UserUrlView.vue";
import UserEditBlood from "../views/user/edit/UserBloodView.vue";
import UserEditBodyType from "../views/user/edit/UserBodyTypeView.vue";
import UserEditBodyHeight from "../views/user/edit/UserBodyHeightView.vue";
import UserEditAlcohol from "../views/user/edit/UserAlcoholView.vue";
import UserEditSmoking from "../views/user/edit/UserSmokingView.vue";
import UserEditGender from "../views/user/edit/UserGenderView.vue";
import UserEditPersonality from "../views/user/edit/UserPersonalityView.vue";
import UserEditDirectional from "../views/user/edit/UserDirectionalView.vue";
import UserEditTag from "../views/user/edit/UserTagView.vue";
import UserEditSadisticAndMasochist from "../views/user/edit/UserSadisticAndMasochistView.vue";
import UserEditAppeal from "../views/user/edit/UserAppealView.vue";
import UserEditComment from "../views/user/edit/UserCommentView.vue";
import UserEditIntroduction from "../views/user/edit/UserIntroductionView.vue";
import UserEditMemoByLike from "../views/user/edit/UserMemoByLikeView.vue";
import UserEditMemoByNg from "../views/user/edit/UserMemoByNgView.vue";

// search
import SearchMatchingView from "../views/search/MatchingView.vue";

// share
import ShareUserProfile from "../views/share/UserProfileView.vue";

// information
import InformationList from "../views/information/ListView.vue";
import InformationDetail from "../views/information/DetailView.vue";

// error
import Error400 from "../views/error/Error400View.vue";

const routes = [
  {
    path: "/debug",
    name: "debug",
    component: Debug,
  },
  {
    path: "/debug/image",
    name: "debug-image",
    component: DebugImage,
  },
  {
    path: "/",
    name: "top",
    component: TopView,
    meta: {
      title: "ホームページ - Kinkyer",
      description: "ホームページの説明文です。",
      image: "https://kinkyer.jp/images/home-ogp.png",
    },
  },
  {
    path: "/entry/top",
    name: "entry",
    component: EntryTop,
  },
  {
    path: "/entry/password",
    name: "entry-password",
    component: PasswordView,
  },
  {
    path: "/auth",
    name: "auth",
    component: AuthTop,
  },
  {
    path: "/auth/conf",
    name: "auth-conf",
    component: AuthConf,
  },
  {
    path: "/home",
    name: "home-timeline",
    component: HomeTimeline,
  },
  {
    path: "/timeline/detail",
    name: "home-timeline-detail",
    component: HomeTimelineDetail,
  },
  {
    path: "/timeline/edit",
    name: "home-timeline-edit",
    component: HomeTimelineEdit,
  },
  {
    path: "/timeline/reply",
    name: "home-timeline-reply",
    component: HomeTimelineReply,
  },
  {
    path: "/timeline/history/:noteid",
    name: "home-timeline-history",
    component: HomeTimelineHistory,
  },
  {
    path: "/user/timeline",
    name: "user-timeline",
    component: HomeTimelineByUserShow,
  },
  {
    path: "/mypage",
    name: "mypage",
    component: MypageIndex,
  },
  {
    path: "/mypage/withdrawal",
    name: "mypage-withdrawal",
    component: Withdrawal,
  },
  {
    path: "/mypage/subscription",
    name: "mypage-subscription",
    component: Subscription,
  },
  {
    path: "/mypage/plan/cancel",
    name: "mypage-plan-cancel",
    component: PlanCancel,
  },
  {
    path: "/mypage/tutorial/1",
    name: "mypage-tutorial-1",
    component: Tutorial1,
  },
  {
    path: "/mypage/tutorial/2",
    name: "mypage-tutorial-2",
    component: Tutorial2,
  },
  {
    path: "/mypage/tutorial/3",
    name: "mypage-tutorial-3",
    component: Tutorial3,
  },
  {
    path: "/mypage/tutorial/4",
    name: "mypage-tutorial-4",
    component: Tutorial4,
  },
  {
    path: "/:page/list",
    name: "follow-list",
    component: MypageFollow,
  },
  {
    path: "/bookmark",
    name: "bookmarek-list",
    component: MypageBookmark,
  },
  {
    path: "/group/note/list",
    name: "group-list",
    component: MypageGroup,
  },
  {
    path: "/mypage/setting/identification/:type",
    name: "mypage-setting-identification",
    component: MypageSettingIdentification,
  },
  {
    path: "/mypage/setting/completion",
    name: "mypage-setting-completion",
    component: MypageSettingCompletion,
  },
  {
    path: "/mypage/setting/pwa",
    name: "mypage-setting-pwa",
    component: MypageSettingPWA,
  },
  {
    path: "/mypage/event/list",
    name: "mypage-event-list",
    component: MypageEventList,
  },
  {
    path: "/mypage/event/edit",
    name: "mypage-event-edit",
    component: MypageEventEdit,
  },
  {
    path: "/mypage/week/setting",
    name: "mypage-week-setting",
    component: MypageWeekSetting,
  },
  {
    path: "/block/list",
    name: "block-list",
    component: BlockUserList,
  },
  {
    path: "/report/list",
    name: "report-list",
    component: ReportUserList,
  },
  {
    path: "/matching",
    name: "matching",
    component: MatchingIndex,
  },
  {
    path: "/event",
    name: "event",
    component: EventIndex,
  },
  {
    path: "/event/bookmark",
    name: "event-bookmark",
    component: EventBookmark,
  },
  {
    path: "/event/detail",
    name: "event-detail",
    component: EventDetail,
  },
  {
    path: "/week",
    name: "week",
    component: WeekIndex,
  },
  {
    path: "/like/list",
    name: "like-list",
    component: LikeList,
  },
  {
    path: "/message/list",
    name: "message-list",
    component: MessageList,
  },
  {
    path: "/message/person/:matching_id",
    name: "message-person",
    component: MessagePerson,
  },
  {
    path: "/message/appea/:matching_id",
    name: "message-appea",
    component: MessageAppea,
  },
  {
    path: "/message/group/:matching_id",
    name: "message-group",
    component: MessageGroup,
  },
  {
    path: "/message/setting/group/:matching_id",
    name: "message-setting-group",
    component: MessageGroupSetting,
  },
  {
    path: "/user/detail",
    name: "user-detail",
    component: UserDetail,
  },
  {
    path: "/user/preview",
    name: "user-preview",
    component: UserPreview,
  },
  {
    path: "/user/edit/icon/:type",
    name: "user-edit-icon",
    component: UserEditIcon,
  },
  {
    path: "/user/edit/info/:type",
    name: "user-edit-info",
    component: UserEditInfo,
  },
  {
    path: "/user/edit/profile/:type",
    name: "user-edit-profile",
    component: UserEditProfile,
  },
  {
    path: "/user/edit/detail/:type",
    name: "user-edit-detail",
    component: UserEditDetail,
  },
  {
    path: "/user/edit/fetishism/:type",
    name: "user-edit-fetishism",
    component: UserEditFetishism,
  },
  {
    path: "/user/edit/bdsm/:type",
    name: "user-edit-bdsm",
    component: UserEditBDSM,
  },
  {
    path: "/user/edit/seiheki/:type",
    name: "user-edit-seiheki",
    component: UserEditSeiheki,
  },
  {
    path: "/user/edit/kaiwai/:type",
    name: "user-edit-kaiwai",
    component: UserEditKaiwai,
  },
  {
    path: "/user/edit/name/:type",
    name: "user-edit-name",
    component: UserEditName,
  },
  {
    path: "/user/edit/sex/:type",
    name: "user-edit-sex",
    component: UserEditSex,
  },
  {
    path: "/user/edit/birthday/:type",
    name: "user-edit-birthday",
    component: UserEditBirthday,
  },
  {
    path: "/user/edit/address/:type",
    name: "user-edit-address",
    component: UserEditAddress,
  },
  {
    path: "/user/edit/url/:type",
    name: "user-edit-url",
    component: UserEditUrl,
  },
  {
    path: "/user/edit/blood/:type",
    name: "user-edit-blood",
    component: UserEditBlood,
  },
  {
    path: "/user/edit/body_type/:type",
    name: "user-edit-body-type",
    component: UserEditBodyType,
  },
  {
    path: "/user/edit/body_height/:type",
    name: "user-edit-body-height",
    component: UserEditBodyHeight,
  },
  {
    path: "/user/edit/alcohol/:type",
    name: "user-edit-alcohol",
    component: UserEditAlcohol,
  },
  {
    path: "/user/edit/smoking/:type",
    name: "user-edit-smoking",
    component: UserEditSmoking,
  },
  {
    path: "/user/edit/gender/:type",
    name: "user-edit-gender",
    component: UserEditGender,
  },
  {
    path: "/user/edit/personality/:type",
    name: "user-edit-personality",
    component: UserEditPersonality,
  },
  {
    path: "/user/edit/directional/:type",
    name: "user-edit-directional",
    component: UserEditDirectional,
  },
  {
    path: "/user/edit/tag/:type",
    name: "user-edit-tag",
    component: UserEditTag,
  },
  {
    path: "/user/edit/sadistic_and_masochist/:type",
    name: "user-edit-sadistic-and-masochist",
    component: UserEditSadisticAndMasochist,
  },
  {
    path: "/user/edit/appeal/:type",
    name: "user-edit-appeal",
    component: UserEditAppeal,
  },
  {
    path: "/user/edit/comment/:type",
    name: "user-edit-comment",
    component: UserEditComment,
  },
  {
    path: "/user/edit/introduction/:type",
    name: "user-edit-introduction",
    component: UserEditIntroduction,
  },
  {
    path: "/user/edit/memo_by_like/:type",
    name: "user-edit-memo-by-like",
    component: UserEditMemoByLike,
  },
  {
    path: "/user/edit/memo_by_ng/:type",
    name: "user-edit-memo-by-ng",
    component: UserEditMemoByNg,
  },
  {
    path: "/search/matching",
    name: "search-matching",
    component: SearchMatchingView,
  },
  {
    path: "/share/profile/:uid",
    name: "share-user-profile",
    component: ShareUserProfile,
  },
  {
    path: "/information/list",
    name: "information-list",
    component: InformationList,
  },
  {
    path: "/information/detail/:iid",
    name: "information-detail",
    component: InformationDetail,
  },
  {
    path: "/error/400",
    name: "error-400",
    component: Error400,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: Error400,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
