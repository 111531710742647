<template>
  <div class="page">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div
            class="text-start"
            style="padding-top: 50px; margin: 0 auto; width: 300px"
          >
            <span class="m-plus-medium" style="font-size: 18px; color: #fff"
              >STEP1.<br />いいねを送ろう！</span
            >
          </div>
          <div class="text-center" style="margin: 0 auto; padding-top: 30px">
            <div class="message-window text-start">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators" style="bottom: -40px">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="3"
                    aria-label="Slide 4"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-box carousel-item active">
                    <div style="color: #827490; padding: 40px 10px 0 10px">
                      プロフィールを検索して、<br />気になった人にいいねを送りましょう
                    </div>
                    <div class="text-center tutorial-image">
                      <img
                        :src="require('@/assets/images/matching_info1.png')"
                        style="width: 250px; height: auto"
                      />
                    </div>
                  </div>
                  <div class="carousel-box carousel-item">
                    <div style="color: #827490; padding: 40px 10px 0 10px">
                      Kinkyerでは相手が許可した場合、<br />マッチング前でも数回メッセージを送れます
                    </div>
                    <div class="text-center tutorial-image">
                      <img
                        :src="require('@/assets/images/matching_info2.png')"
                        style="width: 250px; height: auto"
                      />
                    </div>
                  </div>
                  <div class="carousel-box carousel-item">
                    <div style="color: #827490; padding: 40px 10px 0 10px">
                      相手からいいねが返ってくると<br />マッチングが成立します
                    </div>
                    <div class="text-center tutorial-image">
                      <img
                        :src="require('@/assets/images/matching_info3.png')"
                        style="width: 250px; height: auto"
                      />
                    </div>
                  </div>
                  <div class="carousel-box carousel-item">
                    <div style="color: #827490; padding: 40px 10px 0 10px">
                      マッチングが成立し、お二人でトークができるようになります
                    </div>
                    <div class="text-center tutorial-image">
                      <img
                        :src="require('@/assets/images/matching_info4.png')"
                        style="width: 250px; height: auto"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div class="btn-window">
              <router-link to="/mypage/tutorial/2">
                <button
                  type="button"
                  class="btn btn-kinkyer-by-color m-plus-medium"
                >
                  STEP2へ
                </button>
              </router-link>
              <div style="padding: 8px 0 0 0">
                <router-link to="/home">
                  <a> スキップしてホームへ </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel } from "bootstrap";

export default {
  data() {
    return {};
  },
  mounted() {
    const carouselEl = document.querySelector("#carouselExampleIndicators");
    if (carouselEl) {
      new Carousel(carouselEl, {
        interval: 3000, // 3秒ごとに自動スライド
        ride: "carousel", // 自動起動
      });
    }
  },
  methods: {},
};
</script>

<style scoped>
a {
  font-size: 12px;
  color: #fff !important;
  text-decoration: none !important;
}
a:hover {
  font-size: 12px;
  color: #fff !important;
  text-decoration: none !important;
}
a:focus {
  font-size: 12px;
  color: #fff !important;
  text-decoration: none !important;
}
.page {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: -moz-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: -webkit-linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background: linear-gradient(-135deg, #d241a8, #784ba0, #506ab4);
  background-color: #784ba0;
}
.message-window {
  margin: 0 auto;
  width: 300px;
  border-radius: 12px;
  background-color: #fff;
  font-size: 15px;
}
.btn-window {
  margin: 0 auto;
  width: 300px;
}
.carousel-box {
  width: 300px;
  height: 350px;
  padding: 10px 12px;
}
.tutorial-image {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
}
</style>
