<template>
  <div style="padding: 12px 15px; display: flex" @click="setMessage">
    <div v-if="imageUrl">
      <img :src="imageUrl" @click="linkUser" />
    </div>
    <div v-else-if="isGroup">
      <img :src="require('@/assets/groupchat.png')" />
    </div>
    <div v-else>
      <img :src="require('@/assets/icon_user.webp')" @click="linkUser" />
    </div>
    <div style="width: 90%">
      <div style="display: flex">
        <div
          style="
            padding-left: 10px;
            padding-top: 0px;
            font-size: 14px;
            width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          "
          :class="isUnRead ? 'un-read-box' : ''"
        >
          {{ user.name }}
        </div>
        <div
          style="padding-right: 10px; font-size: 11px; width: 50%"
          class="text-end"
        >
          {{ setDateView(user.updatedAt) }}
        </div>
      </div>
      <div
        style="
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 0px;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        "
        :class="isUnRead ? 'un-read-box' : ''"
      >
        {{ MyMessage }}{{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../plugins/firebase";
import { doc, getDoc } from "firebase/firestore";
import { store, setUserDetail } from "../../plugins/store";

export default {
  data() {
    return {
      loginUser: {},
      user: {},
      imageUrl: null,
      userId: "",
      name: "",
      pageType: "",
      likeUID: "",
      likedUID: "",
      MyMessage: "",
      message: "",
      isUnRead: false,
      isGroup: false,
    };
  },
  props: ["item", "type", "like_uid", "liked_uid"],
  async mounted() {
    this.user = this.item;
    this.isGroup = this.user.isGroup == undefined ? false : this.user.isGroup;
    this.pageType = this.isGroup ? "group" : this.type;
    this.likeUID = this.like_uid;
    this.likedUID = this.liked_uid;
    this.loginUser = store.user;

    this.isUnRead =
      this.user.isUnread == undefined ? false : this.user.isUnread;

    const isMyMessage =
      this.user.isMyMessage == undefined ? false : this.user.isMyMessage;

    this.MyMessage = isMyMessage ? "あなた：" : "";
    this.message =
      this.user.lastMessage == undefined ? "" : this.user.lastMessage;

    this.user.isIconUrl =
      this.user.isIconUrl == undefined ? true : this.user.isIconUrl;

    if (this.user.isIconUrl) {
      this.getUserIcon();
    }
  },
  methods: {
    async getUserIcon() {
      const imageRef = storageRef(
        storage,
        `users/icon/${this.user.uid}/user_icon1.png`,
      );

      try {
        const url = await getDownloadURL(imageRef);
        this.imageUrl = url;
      } catch (error) {
        console.log("---------- components/timeline ---------");
        console.error("Error fetching image URL:", error);
      }
    },
    async linkUser() {
      const docUserRef = doc(db, "users", this.user.uid);
      const docUserSnap = await getDoc(docUserRef);
      setUserDetail(docUserSnap.data());
      this.$router.push({
        name: "user-detail",
      });
    },
    async setMessage() {
      if (this.pageType == "like") {
        this.$router.push("/message/appea/" + this.user.roomId);
      } else if (this.pageType == "matching") {
        this.$router.push("/message/person/" + this.user.matching_room);
      } else if (this.pageType == "group") {
        this.$router.push("/message/group/" + this.user.matching_room);
      }
    },
    setDateView(date) {
      try {
        let status = "今";

        let dt = new Date();
        let lg = new Date(date.toDate());

        var diff = dt.getTime() - lg.getTime();
        //分
        var minutes = Math.floor(diff / (60 * 1000));
        //時
        var hour = Math.floor(diff / (60 * 60 * 1000));
        //日
        var day = Math.floor(diff / (1000 * 60 * 60 * 24));

        if (day == 0 && hour == 0 && minutes > 0) {
          status = minutes + "分前";
        } else if (day == 0 && hour > 0) {
          status = hour + "時間前";
        } else if (day > 0 && day < 31) {
          status = day + "日前";
        } else if (day > 30) {
          const yyyy = lg.getFullYear();
          const mm = ("00" + (lg.getMonth() + 1)).slice(-2);
          const dd = ("00" + lg.getDate()).slice(-2);
          status = `${yyyy}年${mm}月${dd}日`;
        }

        return status;
      } catch {
        return "-";
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.list-sub-tag {
  font-size: 10px;
  white-space: nowrap;
}
.un-read-box {
  font-weight: bold;
}
</style>
