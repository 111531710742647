<template>
  <div class="header-back">
    <div class="header-item header-item-size" style="width: 30%">
      <div>
        <a @click="$router.go(-1)" style="cursor: pointer; color: #fff">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="30px"
            viewBox="0 -960 960 960"
            width="30px"
            fill="currentColor"
          >
            <path
              d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z"
            />
          </svg>
        </a>
      </div>
    </div>
    <div class="text-center header-item" style="padding-top: 5px; width: 40%">
      <span class="title cherry-bomb-one-regular">Kinkyer</span>
    </div>
    <div class="header-item header-item-size" style="width: 30%"></div>
  </div>
  <div
    style="
      width: 20px;
      height: 20px;
      z-index: 1000;
      position: fixed;
      top: 49px;
      left: -1px;
    "
  >
    <img
      :src="require('@/assets/header_left.webp')"
      style="width: 20px; height: 20px; border-radius: 0"
    />
  </div>
  <div
    style="
      width: 20px;
      height: 20px;
      z-index: 1000;
      position: fixed;
      top: 49px;
      right: -1px;
    "
  >
    <img
      :src="require('@/assets/header_right.webp')"
      style="width: 20px; height: 20px; border-radius: 0"
    />
  </div>

  <div class="entry-password">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="page-title">メールアドレスの認証＆登録</div>
            <p class="page-subtitle">
              お客様が本人であることを確認するため、メールアドレスの認証をお願いします。<br />
              入力されたメールアドレス宛に認証用のURLを送信しますので、そちらをクリック後にメールアドレスの登録へお進みください<br />
              <span style="color: red; font-size: 11px"
                >※パスワードは6文字以上の半角英数字または記号で入力してください</span
              >
            </p>
            <div class="form-group">
              <label>メールアドレス</label>
              <input
                type="email"
                class="form-control kinkyer-form-control"
                aria-describedby="emailHelp"
                placeholder="メールアドレス"
                v-model="email"
              />
            </div>
            <div class="form-group">
              <label>パスワード</label>
              <input
                type="password"
                class="form-control kinkyer-form-control"
                aria-describedby="emailHelp"
                placeholder="パスワード"
                v-model="password"
              />
            </div>
            <div class="form-group">
              <label>パスワード（確認用）</label>
              <input
                type="password"
                class="form-control kinkyer-form-control"
                aria-describedby="emailHelp"
                placeholder="パスワード（確認用）"
                v-model="passwordConf"
              />
            </div>
            <br />
            <br />
            <div class="text-center">
              <div style="padding-bottom: 10px">
                <button
                  v-if="isLoadAuth"
                  type="button"
                  class="btn btn-kinkyer-outline-by-color m-plus-medium"
                >
                  <VueSpinner
                    size="15"
                    color="#784ba0"
                    style="margin-right: 5px; top: -2px; position: relative"
                  />
                  {{ verificationBtn }}
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-kinkyer-outline-by-color m-plus-medium"
                  @click="verificationEmail"
                >
                  {{ verificationBtn }}
                </button>
              </div>
              <div style="padding-bottom: 10px" v-if="isAuthed">
                <button
                  v-if="isLoadEntry"
                  type="button"
                  class="btn btn-kinkyer m-plus-medium"
                >
                  <VueSpinner
                    size="15"
                    color="#fff"
                    style="margin-right: 5px; top: -2px; position: relative"
                  />メールアドレス登録
                </button>
                <button
                  v-else
                  type="button"
                  @click="setPasswordInit"
                  class="btn btn-kinkyer m-plus-medium"
                >
                  メールアドレス登録
                </button>
              </div>
              <div style="padding-bottom: 10px" v-else>
                <button
                  type="button"
                  style="background-color: #784ba0ad; border-color: #784ba0ad"
                  class="btn btn-kinkyer m-plus-medium"
                  disabled
                >
                  メールアドレス登録
                </button>
                <div style="font-size: 12px; color: #784ba0">
                  メールアドレスの認証をお願いします
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db, auth } from "../../plugins/firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
} from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { setUser } from "../../plugins/store";

export default {
  data() {
    return {
      user: {},
      email: "",
      password: "",
      passwordConf: "",
      isLoadEntry: false,
      isLoadAuth: false,
      isPageInit: false,
      isAuthed: false,
      verificationBtn: "認証メールを送信",
    };
  },
  methods: {
    async verificationEmail() {
      this.isLoadAuth = true;

      if (this.password == "") {
        this.$toast.show("パスワードを入力してください", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });

        await this.delay(2000);
        this.isLoadAuth = false;
        return;
      } else if (this.password != this.passwordConf) {
        this.$toast.show("パスワードが一致しません", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });

        await this.delay(2000);
        this.isLoadAuth = false;
        return;
      } else if (this.verificationBtn != "認証メールを送信") {
        try {
          const user = auth.currentUser;
          // 認証メールの送信
          await sendEmailVerification(user);
          this.$toast.show("認証メールが送信されました", {
            position: "bottom",
            duration: 2000,
          });
          await this.delay(2000);
          this.isLoadAuth = false;
        } catch (error) {
          this.isLoadAuth = false;
          // エラーの種類に応じてメッセージを設定
          switch (error.code) {
            case "auth/email-already-in-use":
              this.$toast.show("このメールアドレスは既に登録されています。", {
                type: "error",
                position: "bottom",
                duration: 2000,
              });
              break;
            case "auth/invalid-email":
              this.$toast.show("メールアドレスの形式が正しくありません。", {
                type: "error",
                position: "bottom",
                duration: 2000,
              });
              break;
            case "auth/weak-password":
              this.$toast.show("パスワードは6文字以上にしてください。", {
                type: "error",
                position: "bottom",
                duration: 2000,
              });
              break;
            case "auth/too-many-request":
              this.$toast.show("しばらく時間をおいてお試しください", {
                type: "error",
                position: "bottom",
                duration: 2000,
              });
              break;
            default:
              console.log(error);
              this.$toast.show("登録中にエラーが発生しました。", {
                type: "error",
                position: "bottom",
                duration: 2000,
              });
              break;
          }
          await this.delay(2000);
          this.isLoadAuth = false;
        } finally {
          this.isAuthed = true;
          this.verificationBtn = "認証メールを再送する";
        }
        return;
      } else {
        try {
          // Firebaseで新規ユーザーを作成
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            this.email,
            this.password,
          );
          const user = userCredential.user;
          this.user = user;

          // 認証メールの送信
          await sendEmailVerification(user);
          this.$toast.show("認証メールが送信されました", {
            position: "bottom",
            duration: 2000,
          });
          await this.delay(2000);
          this.isLoadAuth = false;
        } catch (error) {
          this.isLoadAuth = false;
          // エラーの種類に応じてメッセージを設定
          switch (error.code) {
            case "auth/email-already-in-use":
              this.$toast.show("このメールアドレスは既に登録されています。", {
                type: "error",
                position: "bottom",
                duration: 2000,
              });
              break;
            case "auth/invalid-email":
              this.$toast.show("メールアドレスの形式が正しくありません。", {
                type: "error",
                position: "bottom",
                duration: 2000,
              });
              break;
            case "auth/weak-password":
              this.$toast.show("パスワードは6文字以上にしてください。", {
                type: "error",
                position: "bottom",
                duration: 2000,
              });
              break;
            case "auth/too-many-request":
              this.$toast.show("しばらく時間をおいてお試しください", {
                type: "error",
                position: "bottom",
                duration: 2000,
              });
              break;
            default:
              this.$toast.show("登録中にエラーが発生しました。", {
                type: "error",
                position: "bottom",
                duration: 2000,
              });
              break;
          }
          await this.delay(2000);
          this.isLoadAuth = false;
        } finally {
          this.isAuthed = true;
          this.verificationBtn = "認証メールを再送する";
        }
      }
    },
    setPasswordInit() {
      this.isPageInit = true;
      this.regPassword();
    },
    regPassword() {
      if (!this.isPageInit) {
        console.log("init page");
        return;
      }
      this.isLoadEntry = true;

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          user.reload().then(async () => {
            if (user.emailVerified) {
              await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                token: "",
                isEntry: false,
                isRegistration: false,
                isIdentification: false,
                isSubscription: false,
                isViewSTDtest: false,
                isViewLicense: false,
                isViewExchange: false,
                isAuthMail: true,
                isAuthTwitter: false,
                isPreUser: false,
                createdAt: new Date(),
                loginAt: new Date(),
                recommendCount: 3,
                lastRecommendAt: new Date(),
                maxGoodCount: 10,
                lastGoodAt: new Date(),
                isReplyGood: true,
                lastReplyGoodAt: new Date(),
                isDM: true,
                groupChatEntryCount: 3,
                groupChatCreateCount: 10,
                lastGroupChatEntryAt: new Date(),
                lastGroupChatCreateAt: new Date(),
                isAgeView: true,
                isSensitiveImage: true,
              });

              await setDoc(doc(db, "authLink", user.uid), {
                uid: user.uid,
                type: "mail",
              });

              const docRef = doc(db, "authLink", user.uid);
              const docSnap = await getDoc(docRef);

              const docUserRef = doc(db, "users", docSnap.data().uid);
              const docUserSnap = await getDoc(docUserRef);

              setUser(docUserSnap.data(), docSnap.data().uid);
              this.user = docUserSnap.data();
              this.uid = docSnap.data().uid;

              this.$router.push("/user/edit/name/entry");
            } else {
              console.log("test2");
              this.$toast.show("メール認証が未確認です。1", {
                position: "bottom",
                duration: 2000,
              });
              await this.delay(2000);
              this.isLoadEntry = false;
            }
          });
        }
      });
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style scoped>
label {
  font-size: 12px;
}
.entry-password {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 60px;
}

.icon {
  color: #fff;
  font-size: 30px;
}
.header-item {
  float: left;
}
.header-item-size {
  padding-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
}
.title {
  color: #fff;
  font-size: 30px;
}

.header-back {
  position: fixed;
  top: 0;
  width: 100%;
  height: 55px;
  background: -moz-linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background: -webkit-linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background: linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background-color: #784ba0;
  z-index: 999;
}

.page-title {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #827490;
}
.page-subtitle {
  padding: 10px 0;
  font-size: 12px;
  color: #827490;
}
</style>
