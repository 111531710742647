<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="false" :is_back="true" title="通知一覧" type="none" />
    <div class="sheet">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <div v-if="items.length">
                <div v-for="(item, i) in items" :key="i" class="item">
                  <div
                    v-if="item.data.isOld == undefined"
                    style="padding: 15px 20px; border-bottom: solid 1px #ddd"
                  >
                    <div :style="item.data.isView ? '' : ''">
                      {{ item.data.message }}
                    </div>
                  </div>
                  <div v-else>
                    <!-- 新しい通知 -->
                    <div
                      style="
                        padding: 15px 20px;
                        border-bottom: solid 1px #ddd;
                        font-size: 14px;
                      "
                      @click="setPage(item.data.type, item.data)"
                    >
                      <div style="padding-bottom: 5px">
                        <div style="display: flex">
                          <div style="width: 50%; display: flex">
                            <span
                              v-if="item.data.type == 'note_reaction'"
                              class="material-symbols-rounded"
                              style="color: #888; font-size: 25px"
                            >
                              mood
                            </span>
                            <span
                              v-else-if="item.data.type == 'note_like'"
                              class="material-symbols-rounded"
                              style="color: #888"
                            >
                              favorite
                            </span>
                            <span
                              v-else-if="item.data.type == 'note_reply'"
                              class="material-symbols-rounded"
                              style="color: #888"
                            >
                              chat
                            </span>
                            <span
                              v-else-if="item.data.type == 'like'"
                              class="material-symbols-rounded"
                              style="color: #888"
                            >
                              thumb_up
                            </span>
                            <span
                              v-else-if="item.data.type == 'matching'"
                              class="material-symbols-rounded"
                              style="color: #888"
                            >
                              volunteer_activism
                            </span>
                            <span
                              v-else-if="item.data.type == 'dm'"
                              class="material-symbols-rounded"
                              style="color: #888"
                            >
                              chat_bubble
                            </span>
                            <span
                              v-else-if="item.data.type == 'dm_appeal'"
                              class="material-symbols-rounded"
                              style="color: #888"
                            >
                              chat_bubble
                            </span>
                            <span
                              v-else-if="item.data.type == 'group_chat'"
                              class="material-symbols-rounded"
                              style="color: #888"
                            >
                              forum
                            </span>
                            <img :src="item.data.infoUser.icon_url" />
                          </div>
                          <div
                            style="
                              padding-right: 10px;
                              padding-top: 3px;
                              font-size: 11px;
                              width: 50%;
                            "
                            class="text-end"
                          >
                            {{ setDateView(item.data.createdAt) }}
                          </div>
                        </div>
                      </div>
                      <div style="display: flex">
                        <div style="padding-left: 4px; padding-top: 1px">
                          <span style="font-weight: bold; padding-right: 2px"
                            >{{ item.data.infoUser.name }}さん</span
                          >{{ item.data.message }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="text-center" style="font-size: 12px">
                  <br /><br /><br />
                  通知はありません。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-pull-to-refresh>
  <div class="inforModal" v-if="isModal">
    <div class="inforModalBody">
      <div class="text-end">
        <span class="material-symbols-rounded" @click="isModal = false">
          close
        </span>
        <div style="padding: 15px 10px">{{ infoData.message }}</div>
        <br />
        <div>
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="linkPage()"
          >
            チェックする
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import { db } from "../../plugins/firebase";
import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { store, setUser, setNote } from "../../plugins/store";

export default {
  data() {
    return {
      user: {},
      pageType: "",
      items: [],
      pageTitle: "",
      whereItem: "",
      isCreated: false,
      isModal: false,
      modalMessage: "",
      infoData: null,
    };
  },
  async created() {
    this.user = store.user;

    const docUserRef = doc(db, "users", this.user.uid);
    const docUserSnap = await getDoc(docUserRef);
    setUser(docUserSnap.data(), this.user.uid);

    try {
      const q = query(
        collection(db, `users/${this.user.uid}/information`),
        orderBy("createdAt", "desc"),
      );

      const querySnapshot = await getDocs(q);

      this.items = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
    } catch (error) {
      console.error("データの取得に失敗しました:", error);
    } finally {
      this.isCreated = true;
    }
  },
  async mounted() {
    const docUpdateRef = doc(db, "users", this.user.uid);
    await updateDoc(docUpdateRef, {
      isHeaderBadges: false,
    });
  },
  components: {
    AppHeader,
    VPullToRefresh,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    linkPage() {
      this.isModal = false;
    },
    setPage(type, item) {
      if (type == "user") {
        //ユーザ詳細へ
      } else if (type == "like") {
        //いいね
        this.$router.push("/like/list");
      } else if (type == "matching") {
        //マッチング
        this.$router.push("/message/list");
      } else if (type == "dm") {
        //メッセージ
        this.$router.push("/message/person/" + item.value);
      } else if (type == "dm_appeal") {
        //マッチング前メッセージ
        this.$router.push("/message/appea/" + item.value);
      } else if (type == "group_chat") {
        //グループチャット
      } else if (type == "note_reply") {
        //投稿への返信
        setNote(item.value);
        this.$router.push({
          name: "home-timeline-detail",
          params: { noteInfo: item.value },
        });
      } else if (type == "note_reaction") {
        //投稿へのリアクション
        setNote(item.value);
        this.$router.push({
          name: "home-timeline-detail",
          params: { noteInfo: item.value },
        });
      } else if (type == "note_like") {
        //投稿へのいいね
        setNote(item.value);
        this.$router.push({
          name: "home-timeline-detail",
          params: { noteInfo: item.value },
        });
      }
    },
    setDateView(date) {
      try {
        let status = "今";

        let dt = new Date();
        let lg = new Date(date.toDate());

        var diff = dt.getTime() - lg.getTime();
        //分
        var minutes = Math.floor(diff / (60 * 1000));
        //時
        var hour = Math.floor(diff / (60 * 60 * 1000));
        //日
        var day = Math.floor(diff / (1000 * 60 * 60 * 24));

        if (day == 0 && hour == 0 && minutes > 0) {
          status = minutes + "分前";
        } else if (day == 0 && hour > 0) {
          status = hour + "時間前";
        } else if (day > 0 && day < 31) {
          status = day + "日前";
        } else if (day > 30) {
          const yyyy = lg.getFullYear();
          const mm = ("00" + (lg.getMonth() + 1)).slice(-2);
          const dd = ("00" + lg.getDate()).slice(-2);
          status = `${yyyy}年${mm}月${dd}日`;
        }

        return status;
      } catch {
        return this.note.createdAtView;
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50%;
}
.sheet {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 55px;
}
.subtitle-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 15px;
  background-color: #efefef;
}
.subtitle {
  width: calc(100% / 2);
  font-size: 12px;
}
.inforModal {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
  background-color: #0000006e;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inforModalBody {
  position: absolute;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  min-width: 300px;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}

.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
