<template>
  <v-pull-to-refresh @load="reloadPage">
    <AppHeader :is_icon="true" :is_back="false" :title="''" type="none" />
    <div class="message-list">
      <div class="page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8" style="padding: 0">
              <!-- <div class="tab-box">
                <div
                  @click="tabIndex = 1"
                  class="tab-item text-center"
                  :style="
                    tabIndex == 1
                      ? 'background-color: #784ba0; color:#fff; font-weight:600'
                      : 'background-color: #efefef; color:#888; font-weight:400'
                  "
                >
                  メッセージ
                </div>
                <div
                  @click="tabIndex = 2"
                  class="tab-item text-center"
                  :style="
                    tabIndex == 2
                      ? 'background-color: #784ba0; color:#fff; font-weight:600'
                      : 'background-color: #efefef; color:#888; font-weight:400'
                  "
                >
                  グループチャット
                </div>
              </div> -->
              <div v-if="tabIndex == 1">
                <div v-if="machingUsers.length">
                  <div v-for="(item, i) in machingUsers" :key="i" class="item">
                    <div>
                      <ListForMatchingView
                        :item="item.data"
                        type="matching"
                        :like_uid="user.uid"
                        :liked_uid="item.data.uid"
                      />
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="text-center" style="font-size: 12px">
                    <br /><br /><br />
                    まだマッチングしていません
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-left">
      <div
        style="
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: #784ba0;
          color: #fff;
          padding-top: 15px;
          padding-left: 15px;
        "
        @click="myBottomSheet = true"
      >
        <span class="material-symbols-rounded" style="font-size: 31px">
          add_comment
        </span>
      </div>
    </div>
    <div
      class="d-none d-md-block"
      style="position: fixed; top: 0px; width: 100%; height: 100%; z-index: -1"
    >
      <div class="container" style="height: 100%">
        <div class="row" style="height: 100%">
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-right: solid 1px #f3f3f3"
          ></div>
          <div class="col-8"></div>
          <div
            class="col-2"
            style="height: 100%; padding: 0; border-left: solid 1px #f3f3f3"
          ></div>
        </div>
      </div>
    </div>
    <v-bottom-sheet v-model="myBottomSheet" style="z-index: 400000">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8" style="padding: 0">
            <v-sheet
              class="text-center"
              height="250px"
              style="border-radius: 15px 15px 0 0"
            >
              <div style="padding: 0px" class="text-center">
                <span
                  class="material-symbols-rounded"
                  style="color: #ccc; font-size: 40px"
                  @click="myBottomSheet = !myBottomSheet"
                >
                  remove
                </span>
              </div>
              <div style="height: 200px; padding: 0 15px">
                <div>
                  <div style="padding-bottom: 5px">グループチャット作成</div>
                  <div
                    class="text-start"
                    style="font-size: 12px; padding-bottom: 10px"
                  >
                    チャットルームの名前を入力してください
                  </div>
                  <div>
                    <input
                      type="email"
                      class="form-control form-control-reset"
                      aria-describedby="emailHelp"
                      placeholder="チャット名"
                      v-model="chatName"
                    />
                  </div>
                  <div style="padding-top: 10px">
                    <button
                      type="button"
                      style="width: 100%; border-radius: 4px"
                      @click="addGrupChat"
                      class="btn btn-kinkyer m-plus-medium"
                    >
                      チャット作成
                    </button>
                  </div>
                </div>
              </div>
            </v-sheet>
          </div>
        </div>
      </div>
    </v-bottom-sheet>
    <AppFooter :index="6" />
  </v-pull-to-refresh>
</template>

<script>
import { VPullToRefresh } from "vuetify/labs/VPullToRefresh";
import AppHeader from "@/components/HeaderView.vue";
import AppFooter from "@/components/FooterViwe.vue";
import ListForMatchingView from "@/components/user/ListForMatchingView.vue";
import { store, setUser } from "../../plugins/store";
import { db } from "../../plugins/firebase";
import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  orderBy,
  updateDoc,
  addDoc,
  setDoc,
} from "firebase/firestore";

export default {
  data() {
    return {
      user: {},
      tabIndex: 1,
      machingUsers: [],
      groupChats: [],
      myBottomSheet: false,
      chatName: "",
    };
  },
  async created() {
    const docRef = doc(db, "authLink", store.user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const docUserRef = doc(db, "users", docSnap.data().uid);
      const docUserSnap = await getDoc(docUserRef);

      setUser(docUserSnap.data(), docSnap.data().uid);
    }
    this.user = store.user;

    const queryLike = query(
      collection(db, `users/${this.user.uid}/matchingUsers`),
      orderBy("updatedAt", "desc"),
    );

    const queryLikeSnapshot = await getDocs(queryLike);

    this.machingUsers = queryLikeSnapshot.docs
      .map((doc) => {
        const data = doc.data();
        const uid = doc.id;
        if (!this.user.blockedUID.includes(uid)) {
          return {
            id: uid,
            data: data,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);

    /* groupchatはmatchingに統合
    const queryGroupChat = query(
      collection(db, `users/${this.user.uid}/groupChats`),
      orderBy("updatedAt", "desc"),
    );

    const queryGroupChatSnapshot = await getDocs(queryGroupChat);

    this.groupChats = queryGroupChatSnapshot.docs
      .map((doc) => {
        const data = doc.data();
        const uid = doc.id;
        if (!this.user.blockedUID.includes(uid)) {
          return {
            id: uid,
            data: data,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item !== null); */
  },
  async mounted() {
    const docUpdateRef = doc(db, "users", store.user.uid);
    await updateDoc(docUpdateRef, {
      isChatBadges: true,
    });
  },
  components: {
    AppHeader,
    AppFooter,
    VPullToRefresh,
    ListForMatchingView,
  },
  methods: {
    reloadPage() {
      this.reloadDelay(1500);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    reloadDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    /**
     * グループチャット追加
     */
    async addGrupChat() {
      try {
        var room_id = "";

        // チャットルーム作成
        const docRef = await addDoc(collection(db, "chat_room"), {
          enttyUids: [this.user.uid],
          ownerUid: this.user.uid,
          name: this.chatName,
          createdAt: new Date(),
        });
        room_id = docRef.id;

        // users/{uid}/matchingUsersへ追加
        // roomIDで扱うnameはチャット名でアイコンは固定
        await setDoc(doc(db, `users/${this.user.uid}/matchingUsers`, room_id), {
          uid: room_id,
          name: this.chatName,
          matching_room: room_id,
          matchingAt: new Date(),
          updatedAt: new Date(),
          isIconUrl: false,
          lastMessage: "グループチャットを作成しました",
          isMyMessage: false,
          isGroup: true,
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.reloadPage();
      }
    },
  },
};
</script>

<style scoped>
.message-list {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}

.page {
  padding-top: 60px;
}

.tab-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 35px;
  padding: 5px 15px;
  margin-bottom: 10px;
}

.tab-item {
  width: calc(100% / 2);
  height: 40px;
  font-size: 13px;
  border: solid 3px #fff;
  border-radius: 6px;
  padding-top: 6px;
}

.footer-left {
  position: fixed;
  right: 15px;
  bottom: 90px;
  z-index: 99;
}
.btn-kinkyer {
  border: solid 1px #784ba0;
  background-color: #784ba0;
  color: #fff;
  width: 100%;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}
.btn-kinkyer:hover {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.btn-kinkyer:active {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
.btn-kinkyer:not(:disabled):not(.disab-outlineled):active,
.btn-kinkyer:not(:disabled):not(.disabled).active,
.show > .btn-l-outlineuna.dropdown-toggle {
  background-color: #633988;
  color: #fff;
  outline: none;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
}
</style>
