<template>
  <AppHeader :is_icon="false" :is_back="true" :title="''" type="none" />
  <div class="user-edit-profile" ref="scrollContainer" style="overflow-y: auto">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div style="padding: 0 8px">
              <div style="padding-bottom: 15px" v-show="isEntry">
                <div
                  class="progress"
                  role="progressbar"
                  aria-label="Basic example"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    class="progress-bar"
                    :style="
                      'background-color: #784ba0 !important; width: ' +
                      count +
                      '%'
                    "
                  ></div>
                </div>
              </div>
              <div
                style="
                  padding-top: 10px;
                  padding-bottom: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  color: #827490;
                "
              >
                居住地
              </div>
              <div
                style="
                  padding: 10px 0px 20px 0;
                  font-size: 12px;
                  font-weight: 400;
                "
              >
                現在住んでいる場所、または拠点にしている都道府県を選択してください（最大で５つ選択できます）<br />
                <span
                  class="badge bg-danger"
                  style="
                    margin-top: 5px;
                    font-size: 10px;
                    position: relative;
                    font-weight: 700;
                    bottom: 2px;
                  "
                  >必須項目</span
                >
              </div>
              <div class="item-box">
                <div>
                  <v-select
                    :options="optionsArea"
                    v-model="selectedArea"
                    :selectable="
                      function () {
                        return selectedArea.length < 5;
                      }
                    "
                    :close-on-select="false"
                    placeholder="居住地"
                    multiple
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 75px;
      padding: 5px 8px;
      background-color: #fff;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6" style="height: 65px">
          <button
            type="button"
            style="width: 100%; height: 40px"
            class="btn btn-kinkyer m-plus-medium"
            @click="((isEdit = true), update())"
          >
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-show="isEdit"
    style="
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      margin: 0 auto;
      padding-top: 200px;
      text-align: center;
      z-index: 999;
    "
  >
    <div style="position: absolute; z-index: 9999; width: 100%">
      <VueSpinnerBars size="50" color="#fff" />
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/HeaderView.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { db } from "../../../plugins/firebase";
import { doc, updateDoc, setDoc } from "firebase/firestore";
import { store, setUser } from "../../../plugins/store";

export default {
  data() {
    return {
      isEdit: false,
      isEntry: false,
      user: {},
      btnName: "",
      selectedArea: [],
      count: 0,
      optionsArea: [
        "北海道（札幌）",
        "北海道（道東）",
        "北海道（道南）",
        "北海道（道北）",
        "北海道（道央）",
        "青森県",
        "岩手県",
        "宮城県",
        "秋田県",
        "山形県",
        "福島県",
        "茨城県",
        "栃木県",
        "群馬県",
        "埼玉県",
        "千葉県",
        "東京都",
        "神奈川県",
        "新潟県",
        "富山県",
        "石川県",
        "福井県",
        "山梨県",
        "長野県",
        "岐阜県",
        "静岡県",
        "愛知県",
        "三重県",
        "滋賀県",
        "京都府",
        "大阪府",
        "兵庫県",
        "奈良県",
        "和歌山県",
        "鳥取県",
        "島根県",
        "岡山県",
        "広島県",
        "山口県",
        "徳島県",
        "香川県",
        "愛媛県",
        "高知県",
        "福岡県",
        "佐賀県",
        "長崎県",
        "熊本県",
        "大分県",
        "宮崎県",
        "鹿児島県",
        "沖縄県",
        "海外",
        "ネットのみ",
      ],
    };
  },
  async created() {
    this.count = 3.846 * 4;
    this.isEntry = this.$route.params.type != "mod";
    this.btnName = this.$route.params.type == "mod" ? "保存する" : "次へ";
    this.user = store.user;
  },
  mounted() {
    this.selectedArea = this.user.area == undefined ? "" : this.user.area;
  },
  components: {
    AppHeader,
    vSelect,
  },
  methods: {
    async update() {
      try {
        if (this.selectedArea.length == 0) {
          this.isEdit = false;
          this.$toast.show("居住地を入力してください", {
            type: "error",
            position: "bottom",
            duration: 2000,
          });
          return;
        }

        const docUpdateUsersRef = doc(db, "users", this.user.uid);

        await updateDoc(docUpdateUsersRef, {
          area: this.selectedArea,
        });
        await setDoc(doc(db, "shareUsers", this.user.uid), {
          area: this.selectedArea,
        });

        this.user.area = this.selectedArea;

        setUser(this.user, this.user.uid);

        if (this.$route.params.type == "mod") {
          this.$router.go(-1);
        } else {
          this.$router.push("/user/edit/icon/" + this.$route.params.type);
        }
      } catch (error) {
        this.isEdit = false;
        console.error("Error :", error);

        this.$toast.show("登録時にエラーが発生しました", {
          type: "error",
          position: "bottom",
          duration: 2000,
        });
        return;
      }
    },
  },
};
</script>

<style scoped>
.user-edit-profile {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
}
.page {
  padding-top: 80px;
  padding-bottom: 80px;
}
.item-box {
  padding-bottom: 20px;
}
.item-title {
  font-size: 14px;
  font-weight: 600;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.item-description {
  font-size: 12px;
  font-weight: 400;
}
</style>
