import { db } from "./firebase";
import { addDoc, collection } from "firebase/firestore";

/**
 * ログ登録
 * @param {ログタイプ} code
 * 1:いいね
 * 2:アピールいいね
 * 3:マッチング
 * 4:ログイン
 * @param {送信ユーザー} fromUser
 * sex,isSubscription
 * @param {受信ユーザー} toUser
 * sex,isSubscription
 * @param {送信ユーザーID} fromUserUID
 * @param {受信ユーザーID} toUserUID
 */
export async function setReport(code, fromUser, toUser) {
  var reportCode = "none";
  if (code == 1) {
    reportCode = "like";
  } else if (code == 2) {
    reportCode = "appealLike";
  } else if (code == 3) {
    reportCode = "matching";
  } else if (code == 4) {
    reportCode = "auth";
  }

  var itemFromUser = {};
  var fromUserUID = "";
  if (fromUser != undefined) {
    itemFromUser = {
      sex: fromUser.sex,
      isSubscription: fromUser.isSubscription,
    };
    fromUserUID = fromUser.uid;
  }

  var itemToUser = {};
  var toUserUID = "";
  if (toUser != undefined) {
    itemToUser = {
      sex: toUser.sex,
      isSubscription: toUser.isSubscription,
    };
    toUserUID = toUser.uid;
  }

  await addDoc(collection(db, `logs`), {
    code: reportCode,
    fromUser: itemFromUser,
    toUser: itemToUser,
    fromUserUID: fromUserUID,
    toUserUID: toUserUID,
    createdAt: new Date(),
  });
}
