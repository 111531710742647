<template>
  <div class="header-back">
    <div class="header-item header-item-size" style="width: 30%">
      <div>
        <a @click="$router.go(-1)" style="cursor: pointer">
          <span class="material-symbols-rounded icon"> arrow_back </span>
        </a>
      </div>
    </div>
    <div class="text-center header-item" style="padding-top: 5px; width: 40%">
      <div
        class="title m-plus-regular"
        style="font-size: 20px; padding-top: 10px"
      >
        メール認証
      </div>
    </div>
    <div class="header-item header-item-size" style="width: 30%"></div>
  </div>

  <div class="entry-password">
    <div class="page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <br />
            <p>メールアドレス認証を確認して、登録を続けてください</p>
            <div class="form-group">
              <label>メールアドレス</label>
              <input
                disabled
                type="email"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="メールアドレス"
                v-model="email"
              />
            </div>
            <br />
            <div class="text-center">
              <div style="padding-bottom: 10px">
                <button
                  v-if="isLoadAuth"
                  type="button"
                  class="btn btn-kinkyer-outline-by-color m-plus-medium"
                >
                  <VueSpinner
                    size="15"
                    color="#784ba0"
                    style="margin-right: 5px; top: -2px; position: relative"
                  />
                  メールアドレスを認証
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-kinkyer-outline-by-color m-plus-medium"
                  @click="verificationEmail"
                >
                  メールアドレスを認証
                </button>
              </div>
              <div style="padding-bottom: 10px" v-if="isAuthed">
                <button
                  v-if="isLoadEntry"
                  type="button"
                  class="btn btn-kinkyer m-plus-medium"
                >
                  <VueSpinner
                    size="15"
                    color="#fff"
                    style="margin-right: 5px; top: -2px; position: relative"
                  />メールアドレス登録
                </button>
                <button
                  v-else
                  type="button"
                  @click="setPasswordInit"
                  class="btn btn-kinkyer m-plus-medium"
                >
                  メールアドレス登録
                </button>
              </div>
              <div style="padding-bottom: 10px" v-else>
                <button
                  type="button"
                  style="background-color: #784ba0ad; border-color: #784ba0ad"
                  class="btn btn-kinkyer m-plus-medium"
                >
                  メールアドレス登録
                </button>
                <div style="font-size: 12px; color: #784ba0">
                  メールアドレスの認証をお願いします
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db, auth } from "../../plugins/firebase";
import { sendEmailVerification, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { setUser, removeAll } from "../../plugins/store";

export default {
  data() {
    return {
      email: "",
      user: null,
      isLoadEntry: false,
      isLoadAuth: false,
      isPageInit: false,
      isAuthed: false,
    };
  },
  created() {
    onAuthStateChanged(auth, async (user) => {
      if (user == null) {
        // storeリセットしてトップページへ
        removeAll();
        this.$router.push("/");
      } else {
        this.email = user.email;
        this.user = user;
      }
    });
  },
  methods: {
    async verificationEmail() {
      this.isLoadAuth = true;

      try {
        // 認証メールの送信
        await sendEmailVerification(this.user);
        this.$toast.show("認証メールが送信されました", {
          position: "bottom",
          duration: 2000,
        });
        await this.delay(2000);
        this.isLoadAuth = false;
      } finally {
        this.isAuthed = true;
      }
    },
    setPasswordInit() {
      this.isPageInit = true;
      this.regPassword();
    },
    regPassword() {
      if (!this.isPageInit) {
        console.log("init page");
        return;
      }
      this.isLoadEntry = true;

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          user.reload().then(async () => {
            if (user.emailVerified) {
              await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                token: "",
                isEntry: false,
                isRegistration: false,
                isIdentification: false,
                isSubscription: false,
                isViewSTDtest: false,
                isViewLicense: false,
                isViewExchange: false,
                isAuthMail: true,
                isAuthTwitter: false,
                isPreUser: false,
                createdAt: new Date(),
                loginAt: new Date(),
                recommendCount: 3,
                lastRecommendAt: new Date(),
                maxGoodCount: 10,
                lastGoodAt: new Date(),
                isReplyGood: true,
                lastReplyGoodAt: new Date(),
                isDM: true,
                groupChatEntryCount: 3,
                groupChatCreateCount: 10,
                lastGroupChatEntryAt: new Date(),
                lastGroupChatCreateAt: new Date(),
                isAgeView: true,
                isSensitiveImage: true,
              });

              await setDoc(doc(db, "authLink", user.uid), {
                uid: user.uid,
                type: "mail",
              });

              const docRef = doc(db, "authLink", user.uid);
              const docSnap = await getDoc(docRef);

              const docUserRef = doc(db, "users", docSnap.data().uid);
              const docUserSnap = await getDoc(docUserRef);

              setUser(docUserSnap.data(), docSnap.data().uid);
              this.user = docUserSnap.data();
              this.uid = docSnap.data().uid;

              this.$router.push("/user/edit/name/entry");
            } else {
              this.$toast.show("メール認証が未確認です。", {
                position: "bottom",
                duration: 2000,
              });
              await this.delay(2000);
              this.isLoadEntry = false;
            }
          });
        }
      });
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style scoped>
p {
  font-size: 12px;
  background-color: rgb(244, 245, 247);
  padding: 10px 15px;
}
label {
  font-size: 12px;
}
.entry-password {
  animation: fadeIn 1.2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  height: 100%;
  background-color: #fff;
}
.page {
  padding-top: 60px;
}

.icon {
  color: #fff;
  font-size: 30px;
}
.header-item {
  float: left;
}
.header-item-size {
  padding-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
}
.title {
  color: #fff;
  font-size: 30px;
}

.header-back {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background: -moz-linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background: -webkit-linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background: linear-gradient(-135deg, #8a49a1, #784ba0, #6d54a6);
  background-color: #784ba0;
  z-index: 999;
}
</style>
